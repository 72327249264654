<template>
  <div @mousedown.self="handleDiscardActiveObject" class="editor">
    <div
      @mousedown="
        handleDiscardActiveObject();
        closePanel();
      "
      class="editor__nav"
    >
      <div class="editor__nav-left">
        <div @click="handelBack()" class="editor__clean">
          <svg
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6V15H4.64286V11C4.64286 9.89543 5.47433 9 6.5 9C7.52567 9 8.35714 9.89543 8.35714 11V15H13V6L6.5 0L0 6Z"
              fill="white"
            />
          </svg>
        </div>
        <div v-if="canvas != null" class="editor__history">
          <div
            @click="handleHistory('undo')"
            :class="['editor__history-item', undo_stack.length < 2 && 'no-active']"
          >
            <svg
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.3056 5.43272L0.652795 6.10582L0 5.43272L0.652795 4.7596L1.3056 5.43272ZM17 13.0481C17 13.5738 16.5867 14 16.0768 14C15.5669 14 15.1536 13.5738 15.1536 13.0481H17ZM5.2688 10.8654L0.652795 6.10582L1.9584 4.7596L6.5744 9.51921L5.2688 10.8654ZM0.652795 4.7596L5.2688 0L6.5744 1.34623L1.9584 6.10582L0.652795 4.7596ZM1.3056 4.48079H10.5376V6.38464H1.3056V4.48079ZM17 11.1442V13.0481H15.1536V11.1442H17ZM10.5376 4.48079C14.1067 4.48079 17 7.46411 17 11.1442H15.1536C15.1536 8.5156 13.0869 6.38464 10.5376 6.38464V4.48079Z"
                fill="#F0EDED"
              />
            </svg>
          </div>
          <div
            @click="handleHistory('redo')"
            :class="['editor__history-item', redo_stack.length == 0 && 'no-active']"
          >
            <svg
              width="17"
              height="13"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6944 5.04466L16.3472 5.66969L17 5.04466L16.3472 4.41963L15.6944 5.04466ZM0 12.1161C0 12.6043 0.413317 13 0.923199 13C1.43308 13 1.8464 12.6043 1.8464 12.1161H0ZM11.7312 10.0893L16.3472 5.66969L15.0416 4.41963L10.4256 8.83927L11.7312 10.0893ZM16.3472 4.41963L11.7312 0L10.4256 1.25007L15.0416 5.66969L16.3472 4.41963ZM15.6944 4.16074H6.4624V5.92859H15.6944V4.16074ZM0 10.3482V12.1161H1.8464V10.3482H0ZM6.4624 4.16074C2.89331 4.16074 0 6.93096 0 10.3482H1.8464C1.8464 7.90735 3.91307 5.92859 6.4624 5.92859V4.16074Z"
                fill="#F0EDED"
              />
            </svg>
          </div>
        </div>
        <div v-if="canvas != null" @click="cleanCanvas()" class="editor__clean">Clean</div>
        <div v-if="canvas != null" @click="saveCanvas()" class="editor__clean">Save</div>
      </div>

      <div class="editor__nav-right">
        <div class="editor__resize" @click="handleOpenResizeModal">
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6698 12V11.07H14.8668V9.15042H16V12H12.6698ZM14.8668 0.929964H12.6698V0H16V2.84958H14.8668V0.929964ZM1.13267 2.84958H0V0H3.33023V0.929964H1.13267V2.84958ZM2.19702 1.91961H13.8024V10.0799H2.19702V1.91961ZM3.32968 9.15042H12.6692V2.84958H3.32968V9.15042ZM4.42874 3.83971H11.6054V8.16077H4.42874V3.83971ZM1.13267 11.07H3.32968V12H0V9.15042H1.13322L1.13267 11.07Z"
              fill="#F0EDED"
            />
          </svg>
          <span class="editor__resize-text">Resize</span>
        </div>

        <div v-if="canvas != null" class="editor__save">
          <div @click="handleOpenDownloadModal()" class="editor__save-image">Download</div>
        </div>
      </div>
    </div>

    <div
      class="editor__container"
      @mousedown.self="
        handleDiscardActiveObject();
        closePanel();
      "
    >
      <!-- side panel -->
      <!-- -- -->
      <div @mousedown="handleDiscardActiveObject" class="editor__side">
        <div class="editor__add-container">
          <div
            @click="openPanel('template', true)"
            v-if="canvas != null"
            title="Download element"
            :class="['editor__add-item', is_active_panel == 'template' && 'active']"
          >
            <div class="editor__add-item-icon">
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.4167 1.58333V4.75H1.58333V1.58333H17.4167ZM17.4167 0H1.58333C1.16341 0 0.76068 0.166815 0.463748 0.463748C0.166815 0.76068 0 1.16341 0 1.58333V4.75C0 5.16993 0.166815 5.57265 0.463748 5.86959C0.76068 6.16652 1.16341 6.33333 1.58333 6.33333H17.4167C17.8366 6.33333 18.2393 6.16652 18.5363 5.86959C18.8332 5.57265 19 5.16993 19 4.75V1.58333C19 1.16341 18.8332 0.76068 18.5363 0.463748C18.2393 0.166815 17.8366 0 17.4167 0Z"
                  fill="#565656"
                />
                <path
                  d="M4.75 9.5V17.4167H1.58333V9.5H4.75ZM4.75 7.91667H1.58333C1.16341 7.91667 0.76068 8.08348 0.463748 8.38041C0.166815 8.67735 0 9.08007 0 9.5V17.4167C0 17.8366 0.166815 18.2393 0.463748 18.5363C0.76068 18.8332 1.16341 19 1.58333 19H4.75C5.16993 19 5.57265 18.8332 5.86959 18.5363C6.16652 18.2393 6.33333 17.8366 6.33333 17.4167V9.5C6.33333 9.08007 6.16652 8.67735 5.86959 8.38041C5.57265 8.08348 5.16993 7.91667 4.75 7.91667Z"
                  fill="#565656"
                />
                <path
                  d="M17.4167 9.5V17.4167H9.5V9.5H17.4167ZM17.4167 7.91667H9.5C9.08007 7.91667 8.67735 8.08348 8.38041 8.38041C8.08348 8.67735 7.91667 9.08007 7.91667 9.5V17.4167C7.91667 17.8366 8.08348 18.2393 8.38041 18.5363C8.67735 18.8332 9.08007 19 9.5 19H17.4167C17.8366 19 18.2393 18.8332 18.5363 18.5363C18.8332 18.2393 19 17.8366 19 17.4167V9.5C19 9.08007 18.8332 8.67735 18.5363 8.38041C18.2393 8.08348 17.8366 7.91667 17.4167 7.91667Z"
                  fill="#565656"
                />
              </svg>
            </div>
            <span class="editor__add-item-text"> Templates </span>
          </div>
          <div
            @click="openPanel('upload', true)"
            v-if="canvas != null"
            title="Download element"
            :class="['editor__add-item', is_active_panel == 'upload' && 'active']"
          >
            <div class="editor__add-item-icon">
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.37151 0.579831C8.7211 0.230307 9.2789 0.230307 9.62849 0.579831L13.3185 4.26915C13.6743 4.62487 13.6813 5.20881 13.3342 5.57342C12.9871 5.93804 12.4173 5.94524 12.0615 5.58953L9.9 3.42842V13.7837C9.9 14.2931 9.49706 14.706 9 14.706C8.50294 14.706 8.1 14.2931 8.1 13.7837V3.42842L5.93849 5.58953C5.58271 5.94524 5.0129 5.93804 4.6658 5.57342C4.31869 5.20881 4.32572 4.62487 4.68151 4.26915L8.37151 0.579831ZM0.9 9.17206C1.39706 9.17206 1.8 9.585 1.8 10.0944V16.5507C1.8 16.7953 1.89482 17.0299 2.0636 17.2029C2.23239 17.3759 2.4613 17.473 2.7 17.473H15.3C15.5387 17.473 15.7676 17.3759 15.9364 17.2029C16.1052 17.0299 16.2 16.7953 16.2 16.5507V10.0944C16.2 9.585 16.6029 9.17206 17.1 9.17206C17.5971 9.17206 18 9.585 18 10.0944V16.5507C18 17.2845 17.7155 17.9883 17.2092 18.5073C16.7028 19.0262 16.0161 19.3177 15.3 19.3177H2.7C1.98392 19.3177 1.29716 19.0262 0.790812 18.5073C0.284463 17.9883 0 17.2845 0 16.5507V10.0944C0 9.585 0.402944 9.17206 0.9 9.17206Z"
                  fill="#565656"
                />
              </svg>
            </div>
            <span class="editor__add-item-text"> Uploads </span>
          </div>
          <div
            @click="openPanel('text', true)"
            title="Add text"
            :class="['editor__add-item', is_active_panel == 'text' && 'active']"
            v-if="canvas != null"
          >
            <div class="editor__add-item-icon">
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0.947368C0 0.424156 0.421384 0 0.941176 0H15.0588C15.5786 0 16 0.424156 16 0.947368V3.78947C16 4.31269 15.5786 4.73684 15.0588 4.73684H14.1176C13.5978 4.73684 13.1765 4.31269 13.1765 3.78947V2.84211H9.41177V15.1579H11.2941C11.8139 15.1579 12.2353 15.582 12.2353 16.1053V17.0526C12.2353 17.5759 11.8139 18 11.2941 18H4.70588C4.18609 18 3.76471 17.5759 3.76471 17.0526V16.1053C3.76471 15.582 4.18609 15.1579 4.70588 15.1579H6.58824V2.84211H2.82353V3.78947C2.82353 4.31269 2.40215 4.73684 1.88235 4.73684H0.941176C0.421384 4.73684 0 4.31269 0 3.78947V0.947368Z"
                  fill="#565656"
                />
              </svg>
            </div>
            <span class="editor__add-item-text"> Texts </span>
          </div>
          <div
            @click="openPanel('background', true)"
            class="editor__add-item"
            v-if="canvas != null"
            :class="['editor__add-item', is_active_panel == 'background' && 'active']"
          >
            <div class="editor__add-item-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                fill="none"
              >
                <rect width="48" height="48" fill="white" fill-opacity="0.01" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37 37C39.2091 37 41 35.2091 41 33C41 31.5272 39.6667 29.5272 37 27C34.3333 29.5272 33 31.5272 33 33C33 35.2091 34.7909 37 37 37Z"
                  fill="#565656"
                />
                <path
                  class="stroke"
                  d="M20.8535 5.50439L24.389 9.03993"
                  stroke="#565656"
                  stroke-width="4"
                  stroke-linecap="round"
                />
                <path
                  class="stroke"
                  d="M23.6818 8.33281L8.12549 23.8892L19.4392 35.2029L34.9955 19.6465L23.6818 8.33281Z"
                  stroke="#565656"
                  stroke-width="4"
                  stroke-linejoin="round"
                />
                <path
                  class="stroke"
                  d="M12 20.0732L28.961 25.6496"
                  stroke="#565656"
                  stroke-width="4"
                  stroke-linecap="round"
                />
                <path
                  class="stroke"
                  d="M4 43H44"
                  stroke="#565656"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <span class="editor__add-item-text"> Background </span>
          </div>
        </div>
      </div>
      <!-- -- -->
      <!-- end side panel -->

      <!-- active panel -->
      <!-- -- -->
      <div
        v-if="canvas != null"
        @mousedown.self="handleDiscardActiveObject"
        class="editor__chosen-bar-wrap"
      >
        <div
          v-if="!mobileActivePanel"
          @click="openMobileActivePanel"
          class="editor__chosen-bar-burger"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
              fill="#0F0F0F"
            />
          </svg>
        </div>
        <div
          v-if="mobileActivePanel"
          @click="closeMobileActivePanel"
          class="editor__chosen-bar-burger close"
        >
          <svg
            data-v-ba736d70=""
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              data-v-ba736d70=""
              d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="black"
            ></path>
          </svg>
        </div>
        <div :class="['editor__chosen-bar', !mobileActivePanel && 'hide']">
          <div
            class="editor__chosen-bar-container"
            v-if="chosenElement == null && is_active_panel == null"
          >
            <div class="editor__chosen-bar-header">Regularize</div>
            <div class="editor__chosen-bar-add-items">
              <div class="editor__chose-bar-add-item">
                <div
                  @click="openPanel('upload')"
                  v-if="canvas != null"
                  title="Download element"
                  class="editor__chosen-bar-add-item-container"
                >
                  <div class="editor__chosen-bar-add-item-button">
                    <svg
                      class="icon"
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.2318 0.289737C10.6591 -0.0965789 11.3409 -0.0965789 11.7682 0.289737L16.2782 4.36741C16.713 4.76057 16.7216 5.40598 16.2974 5.80897C15.8731 6.21196 15.1767 6.21993 14.7418 5.82677L12.1 3.43817V14.8835C12.1 15.4465 11.6075 15.9029 11 15.9029C10.3925 15.9029 9.9 15.4465 9.9 14.8835V3.43817L7.25816 5.82677C6.82331 6.21993 6.12688 6.21196 5.70264 5.80897C5.2784 5.40598 5.287 4.76057 5.72184 4.36741L10.2318 0.289737ZM1.1 9.78641C1.70751 9.78641 2.2 10.2428 2.2 10.8058V17.9417C2.2 18.2121 2.31589 18.4714 2.52218 18.6626C2.72847 18.8538 3.00826 18.9612 3.3 18.9612H18.7C18.9917 18.9612 19.2715 18.8538 19.4778 18.6626C19.6841 18.4714 19.8 18.2121 19.8 17.9417V10.8058C19.8 10.2428 20.2925 9.78641 20.9 9.78641C21.5075 9.78641 22 10.2428 22 10.8058V17.9417C22 18.7528 21.6523 19.5307 21.0335 20.1043C20.4146 20.6778 19.5752 21 18.7 21H3.3C2.42479 21 1.58542 20.6778 0.966547 20.1043C0.347677 19.5307 0 18.7528 0 17.9417V10.8058C0 10.2428 0.492487 9.78641 1.1 9.78641Z"
                        fill="#000000"
                      />
                    </svg>
                    Uploads
                  </div>
                </div>
              </div>

              <div class="editor__chose-bar-add-item">
                <div
                  @click="openPanel('text')"
                  title="Add text"
                  class="editor__chosen-bar-add-item-container"
                >
                  <div class="editor__chosen-bar-add-item-button">
                    <svg
                      class="icon"
                      width="17"
                      height="20"
                      viewBox="0 0 17 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 1.05263C0 0.471284 0.44772 0 1 0H16C16.5523 0 17 0.471284 17 1.05263V4.21053C17 4.79187 16.5523 5.26316 16 5.26316H15C14.4477 5.26316 14 4.79187 14 4.21053V3.15789H10V16.8421H12C12.5523 16.8421 13 17.3134 13 17.8947V18.9474C13 19.5287 12.5523 20 12 20H5C4.44772 20 4 19.5287 4 18.9474V17.8947C4 17.3134 4.44772 16.8421 5 16.8421H7V3.15789H3V4.21053C3 4.79187 2.55228 5.26316 2 5.26316H1C0.44772 5.26316 0 4.79187 0 4.21053V1.05263Z"
                        fill="#000000"
                      />
                    </svg>
                    Texts
                  </div>
                </div>
              </div>
              <div class="editor__chose-bar-add-item"></div>
            </div>
            You have not selected any layers, select a layer to edit or add text or a image
          </div>

          <div
            class="editor__chosen-bar-container"
            v-if="chosenElement == null && is_active_panel == 'template'"
          >
            <div class="editor__chosen-bar-header">
              Templates
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 16.4L16.4 2"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 2L16.4 16.4"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div>From Public</div>
          </div>

          <div
            class="editor__chosen-bar-container"
            v-if="chosenElement == null && is_active_panel == 'text'"
          >
            <div class="editor__chosen-bar-header">
              Texts
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 16.4L16.4 2"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 2L16.4 16.4"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div class="editor__chosen-bar-button" @click="handelAddText('Your Text..')">
              Add Simple Text
            </div>
          </div>

          <div
            class="editor__chosen-bar-container"
            v-if="chosenElement == null && is_active_panel == 'upload'"
          >
            <div class="editor__chosen-bar-header">
              Uploads
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 16.4L16.4 2"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 2L16.4 16.4"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <label title="Download image" for="img">
              <div class="editor__chosen-bar-button">Upload Image</div>
              <input
                ref="img"
                id="img"
                @change="handleAddImage()"
                type="file"
                accept="image/png, image/svg+xml, image/jpeg, image/jpg, image/webp"
                class="editor__chosen-bar-button-input"
              />
            </label>

            <label title="Download image" for="vector">
              <div class="editor__chosen-bar-button">Upload Vector</div>
              <input
                ref="vector"
                id="vector"
                @change="handleAddVector()"
                type="file"
                accept="image/svg+xml"
                class="editor__chosen-bar-button-input"
              />
            </label>
          </div>

          <div
            class="editor__chosen-bar-container"
            v-if="chosenElement == null && is_active_panel == 'background'"
          >
            <div class="editor__chosen-bar-header">
              Background
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 16.4L16.4 2"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 2L16.4 16.4"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <template v-if="canvas != null">
              <div class="editor__filter-tabs border">
                <div
                  @click="changeFillingTypeBackground('color')"
                  :class="['editor__filter-tab', fillingTabBackground == 'color' && 'active']"
                >
                  Color
                </div>
                <div
                  @click="changeFillingTypeBackground('gradient')"
                  :class="['editor__filter-tab', fillingTabBackground == 'gradient' && 'active']"
                >
                  Gradient
                </div>
              </div>
              <!-- -- -->

              <template v-if="fillingTabBackground == 'color'">
                <div class="editor__chosen-bar-item">
                  <sketch-picker
                    @input="
                      (e) => {
                        changeColor(e, 'background', 'fillColor');
                      }
                    "
                    :value="background.fillColor"
                  ></sketch-picker>
                </div>
              </template>

              <template v-if="fillingTabBackground == 'gradient'">
                <div class="editor__filter-tabs-radio">
                  <div
                    @click="
                      () => {
                        this.background.gradientType = 'linear';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      background.gradientType == 'linear' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Linear
                  </div>
                  <div
                    @click="
                      () => {
                        this.background.gradientType = 'radial';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      background.gradientType == 'radial' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Radial
                  </div>
                </div>

                <div
                  class="editor__gradient-preset"
                  :style="{
                    background: generateGradient(
                      background.gradientColors,
                      background.gradientAngle,
                      background.gradientType,
                    ),
                  }"
                ></div>

                <div class="editor__gradient-colors">
                  <div
                    v-for="(color, index) in background.gradientColors"
                    :key="index"
                    class="editor__gradient-color"
                    :style="{ order: background.gradientColors[index].offset * 100 }"
                  >
                    <div class="editor__gradient-color-tools">
                      <div class="editor__gradient-color-name">Color {{ index + 1 }}</div>
                      <div class="editor__gradient-color-tools-right">
                        <div class="editor__gradient-tools-color">
                          <div
                            class="editor__gradient-tools-color-container"
                            @click="openColorPanel(`bg_${index}`)"
                            :style="{ background: background.gradientColors[index].color }"
                          ></div>
                          <div
                            :class="[
                              'editor__gradient-tools-color-picker',
                              colorPanel == `bg_${index}` && 'active',
                            ]"
                          >
                            <div
                              @click="closeColorPanel"
                              class="editor__gradient-tools-color-picker-close"
                            >
                              <svg
                                data-v-ba736d70=""
                                width="15"
                                height="15"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  data-v-ba736d70=""
                                  d="M2 16.4L16.4 2"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  data-v-ba736d70=""
                                  d="M2 2L16.4 16.4"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                            <sketch-picker
                              @input="
                                (e) => {
                                  changeColor(e, 'background', 'gradientColors', index, 'color');
                                }
                              "
                              :value="background.gradientColors[index].color"
                            ></sketch-picker>
                          </div>
                        </div>

                        <div
                          @click="deleteGradientColorItemBackground(index)"
                          class="editor__gradient-color-delete"
                          v-if="background.gradientColors.length > 1"
                        >
                          <svg
                            width="13"
                            height="17"
                            viewBox="0 0 13 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.5 7.96875C9.5 7.6755 9.276 7.4375 9 7.4375C8.724 7.4375 8.5 7.6755 8.5 7.96875V14.3438C8.5 14.6375 8.724 14.875 9 14.875C9.276 14.875 9.5 14.6375 9.5 14.3438V7.96875ZM7 7.96875C7 7.6755 6.776 7.4375 6.5 7.4375C6.224 7.4375 6 7.6755 6 7.96875V14.3438C6 14.6375 6.224 14.875 6.5 14.875C6.776 14.875 7 14.6375 7 14.3438V7.96875ZM4.5 7.96875C4.5 7.6755 4.276 7.4375 4 7.4375C3.724 7.4375 3.5 7.6755 3.5 7.96875V14.3438C3.5 14.6375 3.724 14.875 4 14.875C4.276 14.875 4.5 14.6375 4.5 14.3438V7.96875ZM12 14.875C12 16.0485 11.1045 17 10 17H3C1.8955 17 0.999999 16.0485 0.999999 14.875V6.375H12V14.875ZM5 2.125H8V1.59375C8 1.29997 7.776 1.0625 7.5 1.0625H5.5C5.224 1.0625 5 1.29997 5 1.59375V2.125ZM0.999999 2.125H4V1.0625C4 0.476 4.448 0 5 0H8C8.552 0 9 0.476 9 1.0625V2.125H12C12.552 2.125 13 2.601 13 3.1875V4.25C13 4.8365 12.5525 5.31197 12.0005 5.3125H0.998992C0.447493 5.31197 -9.53674e-07 4.8365 -9.53674e-07 4.25V3.1875C-9.53674e-07 2.601 0.447999 2.125 0.999999 2.125Z"
                              fill="#565656"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <vue-slider
                        :min="0"
                        :max="1"
                        :interval="0.01"
                        v-model="background.gradientColors[index].offset"
                      ></vue-slider>
                    </div>
                  </div>
                  <div @click="addGradientColorItemBackgrouond" class="editor__gradient-color-add">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 1.9C3.34314 1.9 2 3.17599 2 4.75V14.25C2 15.824 3.34315 17.1 5 17.1H15C16.6568 17.1 18 15.824 18 14.25V4.75C18 3.17599 16.6568 1.9 15 1.9H5ZM0 4.75C0 2.12665 2.23858 0 5 0H15C17.7614 0 20 2.12664 20 4.75V14.25C20 16.8733 17.7614 19 15 19H5C2.23857 19 0 16.8733 0 14.25V4.75ZM10 5.7C10.5523 5.7 11 6.12533 11 6.65V8.55H13C13.5523 8.55 14 8.97533 14 9.5C14 10.0247 13.5523 10.45 13 10.45H11V12.35C11 12.8747 10.5523 13.3 10 13.3C9.44771 13.3 9 12.8747 9 12.35V10.45H7C6.44772 10.45 6 10.0247 6 9.5C6 8.97533 6.44772 8.55 7 8.55H9V6.65C9 6.12533 9.44771 5.7 10 5.7Z"
                        fill="#565656"
                      />
                    </svg>
                    Add Colour
                  </div>
                </div>
                <template v-if="this.background.gradientType == 'linear'">
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Rotate
                      <div class="editor__filter-label-side">
                        {{ background.gradientAngle }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="90"
                      :interval="1"
                      v-model="background.gradientAngle"
                    ></vue-slider>
                  </div>
                </template>
              </template>
            </template>
          </div>

          <!-- image filter -->
          <!-- -- -->
          <div
            v-if="
              (chosenElementType == 'image' || chosenElementType == 'vector') &&
              is_active_panel == null
            "
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Image
              <div @mousedown="handleDiscardActiveObject" class="editor__chosen-bar-header-close">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 16.4L16.4 2"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 2L16.4 16.4"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <!-- basic filter element -->
            <!-- -- -->
            <div class="editor__filter-basic">
              <div
                class="editor__filter-basic-item"
                title="Bring to front element"
                @click="handleTopItem()"
              >
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.99998 13L0 9.941L5.99998 6.882L12 9.941L5.99998 13ZM7.00027 5.86233H5.00056V2.93118L3.00081 5.09769V3.18601L6.00039 0L8.99998 3.18601V5.09769L7.00027 2.93118V5.86233Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Send to back element"
                @click="handleBottomItem()"
              >
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.49998 0L0 3.29431L6.49998 6.58861L13 3.29431L6.49998 0ZM7.58362 7.68672H5.41727V10.8433L3.25088 8.51018V10.5689L6.50043 14L9.74997 10.5689V8.51018L7.58362 10.8433V7.68672Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Duplicate element"
                @click="handleCopyItem()"
              >
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2015 8.77896H10.5426V3.77123C10.5426 3.07954 9.95285 2.5193 9.22475 2.5193H3.95346V1.89334C3.95346 1.5478 4.24866 1.26737 4.61238 1.26737H11.2015C11.5652 1.26737 11.8604 1.5478 11.8604 1.89334V8.15299C11.8604 8.49852 11.5652 8.77896 11.2015 8.77896ZM9.22475 8.77896H4.61238C4.24866 8.77896 3.95346 8.49852 3.95346 8.15299V3.77123H8.56584C8.92956 3.77123 9.22475 4.05167 9.22475 4.3972V8.77896ZM8.56584 11.2828H1.97673C1.61301 11.2828 1.31782 11.0024 1.31782 10.6569V4.3972C1.31782 4.05167 1.61301 3.77123 1.97673 3.77123H2.63564V8.77896C2.63564 9.47065 3.22537 10.0309 3.95346 10.0309H9.22475V10.6569C9.22475 11.0024 8.92956 11.2828 8.56584 11.2828ZM11.8604 0.0154419H3.95346C3.22537 0.0154419 2.63564 0.575681 2.63564 1.26737V2.5193H1.31782C0.589725 2.5193 0 3.07954 0 3.77123V11.2828C0 11.9745 0.589725 12.5347 1.31782 12.5347H9.22475C9.95285 12.5347 10.5426 11.9745 10.5426 11.2828V10.0309H11.8604C12.5885 10.0309 13.1782 9.47065 13.1782 8.77896V1.26737C13.1782 0.575681 12.5885 0.0154419 11.8604 0.0154419Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Delete element"
                @click="handleDeleteItem()"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.56414 1.26737C5.18001 1.26737 4.86862 1.54763 4.86862 1.89334V2.5193H7.65069V1.89334C7.65069 1.54762 7.33931 1.26737 6.95517 1.26737H5.56414ZM9.04172 2.5193V1.89334C9.04172 0.85621 8.10757 0.0154419 6.95517 0.0154419H5.56414C4.41177 0.0154419 3.47758 0.856204 3.47758 1.89334V2.5193H0.695517C0.311394 2.5193 0 2.79956 0 3.14527C0 3.49098 0.311394 3.77123 0.695517 3.77123H1.39103V10.0309C1.39103 11.4138 2.63662 12.5347 4.1731 12.5347H8.3462C9.88273 12.5347 11.1283 11.4138 11.1283 10.0309V3.77123H11.8238C12.2079 3.77123 12.5193 3.49098 12.5193 3.14527C12.5193 2.79956 12.2079 2.5193 11.8238 2.5193H9.04172ZM9.73724 3.77123H2.78207V10.0309C2.78207 10.7223 3.40485 11.2828 4.1731 11.2828H8.3462C9.11448 11.2828 9.73724 10.7223 9.73724 10.0309V3.77123ZM4.86862 5.02316C5.25274 5.02316 5.56414 5.30342 5.56414 5.64913V9.40492C5.56414 9.75063 5.25274 10.0309 4.86862 10.0309C4.4845 10.0309 4.1731 9.75063 4.1731 9.40492V5.64913C4.1731 5.30342 4.4845 5.02316 4.86862 5.02316ZM7.65069 5.02316C8.03481 5.02316 8.3462 5.30342 8.3462 5.64913V9.40492C8.3462 9.75063 8.03481 10.0309 7.65069 10.0309C7.26656 10.0309 6.95517 9.75063 6.95517 9.40492V5.64913C6.95517 5.30342 7.26656 5.02316 7.65069 5.02316Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Mirror horizontally"
                @click="changeFlip('flipX')"
              >
                <svg
                  width="13"
                  height="11"
                  viewBox="0 0 13 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.0970785 10.6739C0.215515 10.8766 0.424724 11 0.650001 11H5.2C5.55899 11 5.85 10.6922 5.85 10.3125V0.687564C5.85 0.368718 5.64271 0.0917124 5.34937 0.0184598C5.05595 -0.0547997 4.75344 0.094923 4.61862 0.380103L0.0686216 10.0051C-0.0321218 10.2182 -0.0213579 10.4712 0.0970785 10.6739ZM1.70173 9.62501L4.55 3.59983V9.62501H1.70173ZM7.15 10.3125C7.15 10.6922 7.441 11 7.8 11H12.35C12.5753 11 12.7845 10.8766 12.903 10.6739C13.0214 10.4712 13.0321 10.2182 12.9314 10.0051L8.38136 0.380103C8.24655 0.094923 7.94404 -0.0547997 7.65063 0.0184598C7.35728 0.0917124 7.15 0.368718 7.15 0.687564V10.3125Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Mirror vertically"
                @click="changeFlip('flipY')"
              >
                <svg
                  width="11"
                  height="13"
                  viewBox="0 0 11 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.32608 0.0970781C0.123406 0.215515 1.88878e-06 0.424723 1.87894e-06 0.65L1.68005e-06 5.2C1.66436e-06 5.55899 0.307792 5.85 0.687496 5.85L10.3124 5.85C10.6313 5.85 10.9083 5.64271 10.9815 5.34937C11.0548 5.05595 10.9051 4.75344 10.6199 4.61862L0.994944 0.0686212C0.78182 -0.0321223 0.528753 -0.0213584 0.32608 0.0970781ZM1.37499 1.70173L7.40017 4.55L1.37499 4.55L1.37499 1.70173ZM0.687496 7.15C0.307792 7.15 1.58209e-06 7.441 1.5664e-06 7.8L1.36751e-06 12.35C1.35767e-06 12.5753 0.123406 12.7845 0.32608 12.903C0.528753 13.0214 0.78182 13.0321 0.994943 12.9314L10.6199 8.38136C10.9051 8.24655 11.0548 7.94404 10.9815 7.65063C10.9083 7.35728 10.6313 7.15 10.3124 7.15L0.687496 7.15Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div class="editor__filter-basic-item" title="Lock element" @click="handleLockItem()">
                <svg
                  v-if="chosenData.lock"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                    stroke="#565656"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  v-if="!chosenData.lock"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.584 6C15.8124 4.2341 14.0503 3 12 3C9.23858 3 7 5.23858 7 8V10.0288M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C16.8802 10 17.7202 10 18.362 10.327C18.9265 10.6146 19.3854 11.0735 19.673 11.638C20 12.2798 20 13.1198 20 14.8V16.2C20 17.8802 20 18.7202 19.673 19.362C19.3854 19.9265 18.9265 20.3854 18.362 20.673C17.7202 21 16.8802 21 15.2 21H8.8C7.11984 21 6.27976 21 5.63803 20.673C5.07354 20.3854 4.6146 19.9265 4.32698 19.362C4 18.7202 4 17.8802 4 16.2V14.8C4 13.1198 4 12.2798 4.32698 11.638C4.6146 11.0735 5.07354 10.6146 5.63803 10.327C5.99429 10.1455 6.41168 10.0647 7 10.0288Z"
                    stroke="#565656"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div class="editor__chosen-bar-item">
              <div class="editor__filter-label">
                Object Opacity
                <div class="editor__filter-label-side">
                  {{ chosenData.opacity }}
                </div>
              </div>
              <vue-slider
                :min="0"
                :max="1"
                :interval="0.05"
                v-model="chosenData.opacity"
              ></vue-slider>
            </div>

            <template v-if="chosenElementType == 'vector'">
              <div class="editor__chosen-bar-item">
                <div class="editor__filter-label">Color Vector</div>
                <sketch-picker
                  @input="
                    (e) => {
                      changeColor(e, 'chosenData', 'fill');
                    }
                  "
                  :value="chosenData.fill"
                ></sketch-picker>
              </div>
            </template>
            <div class="editor__panel-sizes-title">Blending mode</div>
            <div class="editor__select">
              <div class="editor__select-button">
                {{ chosenData.globalCompositeOperation || "none" }}
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 15 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.435 1.70711L8.07105 8.07107C7.68053 8.46159 7.04736 8.46159 6.65684 8.07107L0.292876 1.70711C-0.0976484 1.31658 -0.0976485 0.68342 0.292876 0.292895C0.683401 -0.0976291 1.31657 -0.0976292 1.70709 0.292895L7.27816 5.86396L7.44973 5.86396L13.0208 0.292894C13.4113 -0.0976309 14.0445 -0.097631 14.435 0.292893C14.8255 0.683418 14.8255 1.31658 14.435 1.70711Z"
                    fill="#d9d9d9"
                  />
                </svg>
              </div>
              <div class="editor__select-list">
                <div
                  v-for="item in globalCompositeOperationArray"
                  :key="item.id"
                  class="editor__select-list-item"
                  @click="addGlobalCompositeOperation(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- -- -->
          <!-- end image filter -->

          <!-- text font filter -->

          <div
            v-if="chosenElementType == 'text' && is_active_panel == 'font'"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Fonts
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div class="editor__list-fonts">
              <div
                @click="changeFont(font)"
                v-for="font in fontsList"
                :key="font.id"
                :class="[
                  'editor__list-fonts-item',
                  font.name.trim() == chosenData.fontFamily.trim() && 'active',
                ]"
              >
                <img :src="font.image" :alt="font.name" />
              </div>
            </div>
          </div>

          <!-- 3D text color filter -->
          <div
            v-if="chosenElementType == 'text' && is_active_panel == 'colour_3d'"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Colour 3D
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <template>
              <!-- filling 3D type filter -->
              <!-- -- -->
              <div class="editor__filter-tabs">
                <div
                  @click="changeFillingType3D('color')"
                  :class="['editor__filter-tab', fillingTab3D == 'color' && 'active']"
                >
                  Colour
                </div>
                <div
                  @click="changeFillingType3D('gradient')"
                  :class="['editor__filter-tab', fillingTab3D == 'gradient' && 'active']"
                >
                  Gradient
                </div>
              </div>
              <!-- -- -->

              <template v-if="fillingTab3D == 'color'">
                <div class="editor__chosen-bar-item">
                  <sketch-picker
                    @input="
                      (e) => {
                        changeColor(e, 'chosenData', 'fillColor3D');
                      }
                    "
                    :value="chosenData.fillColor3D"
                  ></sketch-picker>
                </div>
              </template>

              <template v-if="fillingTab3D == 'gradient'">
                <div class="editor__filter-tabs-radio">
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientType3D = 'linear';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientType3D == 'linear' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Linear
                  </div>
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientType3D = 'radial';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientType3D == 'radial' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Radial
                  </div>
                </div>

                <div
                  class="editor__gradient-preset"
                  :style="{
                    background: generateGradient(
                      chosenData.gradientColors3D,
                      chosenData.gradientAngle3D,
                      chosenData.gradientType3D,
                    ),
                  }"
                ></div>

                <div class="editor__gradient-colors">
                  <div
                    v-for="(color, index) in chosenData.gradientColors3D"
                    :key="index"
                    class="editor__gradient-color"
                    :style="{ order: chosenData.gradientColors3D[index].offset * 100 }"
                  >
                    <div class="editor__gradient-color-tools">
                      <div class="editor__gradient-color-name">Colour {{ index + 1 }}</div>
                      <div class="editor__gradient-color-tools-right">
                        <div class="editor__gradient-tools-color">
                          <div
                            class="editor__gradient-tools-color-container"
                            @click="openColorPanel(`3d_color_${index}`)"
                            :style="{ background: chosenData.gradientColors3D[index].color }"
                          ></div>
                          <div
                            :class="[
                              'editor__gradient-tools-color-picker',
                              colorPanel == `3d_color_${index}` && 'active',
                            ]"
                          >
                            <div
                              @click="closeColorPanel"
                              class="editor__gradient-tools-color-picker-close"
                            >
                              <svg
                                data-v-ba736d70=""
                                width="15"
                                height="15"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  data-v-ba736d70=""
                                  d="M2 16.4L16.4 2"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  data-v-ba736d70=""
                                  d="M2 2L16.4 16.4"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                            <sketch-picker
                              @input="
                                (e) => {
                                  changeColor(e, 'chosenData', 'gradientColors3D', index, 'color');
                                }
                              "
                              :value="chosenData.gradientColors3D[index].color"
                            ></sketch-picker>
                          </div>
                        </div>

                        <div
                          @click="deleteGradientColorItem3D(index)"
                          class="editor__gradient-color-delete"
                          v-if="chosenData.gradientColors3D.length > 1"
                        >
                          <svg
                            width="13"
                            height="17"
                            viewBox="0 0 13 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.5 7.96875C9.5 7.6755 9.276 7.4375 9 7.4375C8.724 7.4375 8.5 7.6755 8.5 7.96875V14.3438C8.5 14.6375 8.724 14.875 9 14.875C9.276 14.875 9.5 14.6375 9.5 14.3438V7.96875ZM7 7.96875C7 7.6755 6.776 7.4375 6.5 7.4375C6.224 7.4375 6 7.6755 6 7.96875V14.3438C6 14.6375 6.224 14.875 6.5 14.875C6.776 14.875 7 14.6375 7 14.3438V7.96875ZM4.5 7.96875C4.5 7.6755 4.276 7.4375 4 7.4375C3.724 7.4375 3.5 7.6755 3.5 7.96875V14.3438C3.5 14.6375 3.724 14.875 4 14.875C4.276 14.875 4.5 14.6375 4.5 14.3438V7.96875ZM12 14.875C12 16.0485 11.1045 17 10 17H3C1.8955 17 0.999999 16.0485 0.999999 14.875V6.375H12V14.875ZM5 2.125H8V1.59375C8 1.29997 7.776 1.0625 7.5 1.0625H5.5C5.224 1.0625 5 1.29997 5 1.59375V2.125ZM0.999999 2.125H4V1.0625C4 0.476 4.448 0 5 0H8C8.552 0 9 0.476 9 1.0625V2.125H12C12.552 2.125 13 2.601 13 3.1875V4.25C13 4.8365 12.5525 5.31197 12.0005 5.3125H0.998992C0.447493 5.31197 -9.53674e-07 4.8365 -9.53674e-07 4.25V3.1875C-9.53674e-07 2.601 0.447999 2.125 0.999999 2.125Z"
                              fill="#565656"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <vue-slider
                        :min="0"
                        :max="1"
                        :interval="0.01"
                        v-model="chosenData.gradientColors3D[index].offset"
                      ></vue-slider>
                    </div>
                  </div>
                  <div @click="addGradientColorItem3D" class="editor__gradient-color-add">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 1.9C3.34314 1.9 2 3.17599 2 4.75V14.25C2 15.824 3.34315 17.1 5 17.1H15C16.6568 17.1 18 15.824 18 14.25V4.75C18 3.17599 16.6568 1.9 15 1.9H5ZM0 4.75C0 2.12665 2.23858 0 5 0H15C17.7614 0 20 2.12664 20 4.75V14.25C20 16.8733 17.7614 19 15 19H5C2.23857 19 0 16.8733 0 14.25V4.75ZM10 5.7C10.5523 5.7 11 6.12533 11 6.65V8.55H13C13.5523 8.55 14 8.97533 14 9.5C14 10.0247 13.5523 10.45 13 10.45H11V12.35C11 12.8747 10.5523 13.3 10 13.3C9.44771 13.3 9 12.8747 9 12.35V10.45H7C6.44772 10.45 6 10.0247 6 9.5C6 8.97533 6.44772 8.55 7 8.55H9V6.65C9 6.12533 9.44771 5.7 10 5.7Z"
                        fill="#565656"
                      />
                    </svg>
                    Add Colour
                  </div>
                </div>

                <template v-if="this.chosenData.gradientType3D == 'linear'">
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Gradient Rotate
                      <div class="editor__filter-label-side">
                        {{ chosenData.gradientAngle3D }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="90"
                      :interval="1"
                      v-model="chosenData.gradientAngle3D"
                    ></vue-slider>
                  </div>
                </template>
              </template>

              <template v-if="fillingTab3D == 'texture'">
                <div v-if="chosenData.textureFill3D" class="editor__loaded-texture">
                  <img :src="chosenData.textureFill3D.source.src" alt="" />
                </div>
                <div class="editor__chosen-bar-item">
                  <label class="editor__texture-button" title="Add image" for="img_texture_3d"
                    >Load Texture</label
                  >
                  <input
                    class="editor__texture-input"
                    ref="img_texture_3d"
                    id="img_texture_3d"
                    @change="loadTexture3D()"
                    type="file"
                    accept="image/png, image/svg, image/jpeg"
                  />
                </div>
              </template>
            </template>
          </div>

          <!-- text color filter -->
          <div
            v-if="chosenElementType == 'text' && is_active_panel == 'colour'"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Colour
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <template>
              <!-- filling type filter -->
              <!-- -- -->
              <div class="editor__filter-tabs three border">
                <div
                  @click="changeFillingType('color')"
                  :class="['editor__filter-tab', fillingTab == 'color' && 'active']"
                >
                  Colour
                </div>
                <div
                  @click="changeFillingType('gradient')"
                  :class="['editor__filter-tab', fillingTab == 'gradient' && 'active']"
                >
                  Gradient
                </div>
                <div
                  @click="changeFillingType('texture')"
                  :class="['editor__filter-tab', fillingTab == 'texture' && 'active']"
                >
                  Texture
                </div>
              </div>
              <!-- -- -->

              <template v-if="fillingTab == 'color'">
                <div class="editor__chosen-bar-item">
                  <sketch-picker
                    @input="
                      (e) => {
                        changeColor(e, 'chosenData', 'fill');
                      }
                    "
                    :value="chosenData.fill"
                  ></sketch-picker>
                </div>
              </template>

              <template v-if="fillingTab == 'gradient'">
                <div class="editor__filter-tabs-radio">
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientType = 'linear';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientType == 'linear' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Linear
                  </div>
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientType = 'radial';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientType == 'radial' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Radial
                  </div>
                </div>

                <div
                  class="editor__gradient-preset"
                  :style="{
                    background: generateGradient(
                      chosenData.gradientColors,
                      chosenData.gradientAngle,
                      chosenData.gradientType,
                    ),
                  }"
                ></div>

                <div class="editor__gradient-colors">
                  <div
                    v-for="(color, index) in chosenData.gradientColors"
                    :key="index"
                    class="editor__gradient-color"
                    :style="{ order: chosenData.gradientColors[index].offset * 100 }"
                  >
                    <div class="editor__gradient-color-tools">
                      <div class="editor__gradient-color-name">Colour {{ index + 1 }}</div>
                      <div class="editor__gradient-color-tools-right">
                        <div class="editor__gradient-tools-color">
                          <div
                            class="editor__gradient-tools-color-container"
                            @click="openColorPanel(`color_${index}`)"
                            :style="{ background: chosenData.gradientColors[index].color }"
                          ></div>
                          <div
                            :class="[
                              'editor__gradient-tools-color-picker',
                              colorPanel == `color_${index}` && 'active',
                            ]"
                          >
                            <div
                              @click="closeColorPanel"
                              class="editor__gradient-tools-color-picker-close"
                            >
                              <svg
                                data-v-ba736d70=""
                                width="15"
                                height="15"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  data-v-ba736d70=""
                                  d="M2 16.4L16.4 2"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  data-v-ba736d70=""
                                  d="M2 2L16.4 16.4"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                            <sketch-picker
                              @input="
                                (e) => {
                                  changeColor(e, 'chosenData', 'gradientColors', index, 'color');
                                }
                              "
                              :value="chosenData.gradientColors[index].color"
                            ></sketch-picker>
                          </div>
                        </div>

                        <div
                          @click="deleteGradientColorItem(index)"
                          class="editor__gradient-color-delete"
                          v-if="chosenData.gradientColors.length > 1"
                        >
                          <svg
                            width="13"
                            height="17"
                            viewBox="0 0 13 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.5 7.96875C9.5 7.6755 9.276 7.4375 9 7.4375C8.724 7.4375 8.5 7.6755 8.5 7.96875V14.3438C8.5 14.6375 8.724 14.875 9 14.875C9.276 14.875 9.5 14.6375 9.5 14.3438V7.96875ZM7 7.96875C7 7.6755 6.776 7.4375 6.5 7.4375C6.224 7.4375 6 7.6755 6 7.96875V14.3438C6 14.6375 6.224 14.875 6.5 14.875C6.776 14.875 7 14.6375 7 14.3438V7.96875ZM4.5 7.96875C4.5 7.6755 4.276 7.4375 4 7.4375C3.724 7.4375 3.5 7.6755 3.5 7.96875V14.3438C3.5 14.6375 3.724 14.875 4 14.875C4.276 14.875 4.5 14.6375 4.5 14.3438V7.96875ZM12 14.875C12 16.0485 11.1045 17 10 17H3C1.8955 17 0.999999 16.0485 0.999999 14.875V6.375H12V14.875ZM5 2.125H8V1.59375C8 1.29997 7.776 1.0625 7.5 1.0625H5.5C5.224 1.0625 5 1.29997 5 1.59375V2.125ZM0.999999 2.125H4V1.0625C4 0.476 4.448 0 5 0H8C8.552 0 9 0.476 9 1.0625V2.125H12C12.552 2.125 13 2.601 13 3.1875V4.25C13 4.8365 12.5525 5.31197 12.0005 5.3125H0.998992C0.447493 5.31197 -9.53674e-07 4.8365 -9.53674e-07 4.25V3.1875C-9.53674e-07 2.601 0.447999 2.125 0.999999 2.125Z"
                              fill="#565656"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <vue-slider
                        :min="0"
                        :max="1"
                        :interval="0.01"
                        v-model="chosenData.gradientColors[index].offset"
                      ></vue-slider>
                    </div>
                  </div>
                  <div @click="addGradientColorItem" class="editor__gradient-color-add">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 1.9C3.34314 1.9 2 3.17599 2 4.75V14.25C2 15.824 3.34315 17.1 5 17.1H15C16.6568 17.1 18 15.824 18 14.25V4.75C18 3.17599 16.6568 1.9 15 1.9H5ZM0 4.75C0 2.12665 2.23858 0 5 0H15C17.7614 0 20 2.12664 20 4.75V14.25C20 16.8733 17.7614 19 15 19H5C2.23857 19 0 16.8733 0 14.25V4.75ZM10 5.7C10.5523 5.7 11 6.12533 11 6.65V8.55H13C13.5523 8.55 14 8.97533 14 9.5C14 10.0247 13.5523 10.45 13 10.45H11V12.35C11 12.8747 10.5523 13.3 10 13.3C9.44771 13.3 9 12.8747 9 12.35V10.45H7C6.44772 10.45 6 10.0247 6 9.5C6 8.97533 6.44772 8.55 7 8.55H9V6.65C9 6.12533 9.44771 5.7 10 5.7Z"
                        fill="#565656"
                      />
                    </svg>
                    Add Colour
                  </div>
                </div>

                <template v-if="this.chosenData.gradientType == 'linear'">
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Gradient Rotate
                      <div class="editor__filter-label-side">
                        {{ chosenData.gradientAngle }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="90"
                      :interval="1"
                      v-model="chosenData.gradientAngle"
                    ></vue-slider>
                  </div>
                </template>
              </template>

              <template v-if="fillingTab == 'texture'">
                <div class="editor__texture-button-container">
                  <label class="editor__texture-button" title="Add image" for="img_texture"
                    >Load Texture</label
                  >
                  <input
                    class="editor__texture-input"
                    ref="img_texture"
                    id="img_texture"
                    @change="loadTexture()"
                    type="file"
                    accept="image/png, image/svg, image/jpeg"
                  />
                  <div
                    v-if="chosenData.textureFill"
                    @click="deleteTexture()"
                    class="editor__texture-button"
                  >
                    Delete
                  </div>
                </div>
                <div v-if="chosenData.textureFill" class="editor__loaded-texture">
                  <img :src="chosenData.textureFill.source.src" alt="" />

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Scale
                      <div class="editor__filter-label-side">
                        {{ chosenData.textureOffset }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0.1"
                      :max="1"
                      :interval="0.02"
                      v-model="chosenData.textureOffset"
                    ></vue-slider>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <!-- outline color filter -->
          <div
            v-if="chosenElementType == 'text' && is_active_panel == 'colour_outlines'"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Colour Outlines
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <template>
              <!-- filling type filter -->
              <!-- -- -->
              <div class="editor__filter-tabs border">
                <div
                  @click="changeFillingTypeOutlines('color')"
                  :class="['editor__filter-tab', fillingTabOutlines == 'color' && 'active']"
                >
                  Colour
                </div>
                <div
                  @click="changeFillingTypeOutlines('gradient')"
                  :class="['editor__filter-tab', fillingTabOutlines == 'gradient' && 'active']"
                >
                  Gradient
                </div>
              </div>
              <!-- -- -->

              <template v-if="fillingTabOutlines == 'color'">
                <div class="editor__chosen-bar-item">
                  <sketch-picker
                    @input="
                      (e) => {
                        changeColor(e, 'chosenData', 'stroke');
                      }
                    "
                    :value="chosenData.stroke"
                  ></sketch-picker>
                </div>
              </template>

              <template v-if="fillingTabOutlines == 'gradient'">
                <div class="editor__filter-tabs-radio">
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientTypeOutlines = 'linear';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientTypeOutlines == 'linear' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Linear
                  </div>
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientTypeOutlines = 'radial';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientTypeOutlines == 'radial' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>
                    Radial
                  </div>
                </div>

                <div
                  class="editor__gradient-preset"
                  :style="{
                    background: generateGradient(
                      chosenData.gradientColorsOutlines,
                      chosenData.gradientAngleOutlines,
                      chosenData.gradientTypeOutlines,
                    ),
                  }"
                ></div>

                <div class="editor__gradient-colors">
                  <div
                    v-for="(color, index) in chosenData.gradientColorsOutlines"
                    :key="index"
                    class="editor__gradient-color"
                    :style="{ order: chosenData.gradientColorsOutlines[index].offset * 100 }"
                  >
                    <div class="editor__gradient-color-tools">
                      <div class="editor__gradient-color-name">Colour {{ index + 1 }}</div>
                      <div class="editor__gradient-color-tools-right">
                        <div class="editor__gradient-tools-color">
                          <div
                            class="editor__gradient-tools-color-container"
                            @click="openColorPanel(`outline_${index}`)"
                            :style="{ background: chosenData.gradientColorsOutlines[index].color }"
                          ></div>
                          <div
                            :class="[
                              'editor__gradient-tools-color-picker',
                              colorPanel == `outline_${index}` && 'active',
                            ]"
                          >
                            <div
                              @click="closeColorPanel"
                              class="editor__gradient-tools-color-picker-close"
                            >
                              <svg
                                data-v-ba736d70=""
                                width="15"
                                height="15"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  data-v-ba736d70=""
                                  d="M2 16.4L16.4 2"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  data-v-ba736d70=""
                                  d="M2 2L16.4 16.4"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                            <sketch-picker
                              @input="
                                (e) => {
                                  changeColor(
                                    e,
                                    'chosenData',
                                    'gradientColorsOutlines',
                                    index,
                                    'color',
                                  );
                                }
                              "
                              :value="chosenData.gradientColorsOutlines[index].color"
                            ></sketch-picker>
                          </div>
                        </div>

                        <div
                          @click="deleteGradientColorItemOutlines(index)"
                          class="editor__gradient-color-delete"
                          v-if="chosenData.gradientColorsOutlines.length > 1"
                        >
                          <svg
                            width="13"
                            height="17"
                            viewBox="0 0 13 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.5 7.96875C9.5 7.6755 9.276 7.4375 9 7.4375C8.724 7.4375 8.5 7.6755 8.5 7.96875V14.3438C8.5 14.6375 8.724 14.875 9 14.875C9.276 14.875 9.5 14.6375 9.5 14.3438V7.96875ZM7 7.96875C7 7.6755 6.776 7.4375 6.5 7.4375C6.224 7.4375 6 7.6755 6 7.96875V14.3438C6 14.6375 6.224 14.875 6.5 14.875C6.776 14.875 7 14.6375 7 14.3438V7.96875ZM4.5 7.96875C4.5 7.6755 4.276 7.4375 4 7.4375C3.724 7.4375 3.5 7.6755 3.5 7.96875V14.3438C3.5 14.6375 3.724 14.875 4 14.875C4.276 14.875 4.5 14.6375 4.5 14.3438V7.96875ZM12 14.875C12 16.0485 11.1045 17 10 17H3C1.8955 17 0.999999 16.0485 0.999999 14.875V6.375H12V14.875ZM5 2.125H8V1.59375C8 1.29997 7.776 1.0625 7.5 1.0625H5.5C5.224 1.0625 5 1.29997 5 1.59375V2.125ZM0.999999 2.125H4V1.0625C4 0.476 4.448 0 5 0H8C8.552 0 9 0.476 9 1.0625V2.125H12C12.552 2.125 13 2.601 13 3.1875V4.25C13 4.8365 12.5525 5.31197 12.0005 5.3125H0.998992C0.447493 5.31197 -9.53674e-07 4.8365 -9.53674e-07 4.25V3.1875C-9.53674e-07 2.601 0.447999 2.125 0.999999 2.125Z"
                              fill="#565656"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <vue-slider
                        :min="0"
                        :max="1"
                        :interval="0.01"
                        v-model="chosenData.gradientColorsOutlines[index].offset"
                      ></vue-slider>
                    </div>
                  </div>
                  <div @click="addGradientColorItemOutlines" class="editor__gradient-color-add">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 1.9C3.34314 1.9 2 3.17599 2 4.75V14.25C2 15.824 3.34315 17.1 5 17.1H15C16.6568 17.1 18 15.824 18 14.25V4.75C18 3.17599 16.6568 1.9 15 1.9H5ZM0 4.75C0 2.12665 2.23858 0 5 0H15C17.7614 0 20 2.12664 20 4.75V14.25C20 16.8733 17.7614 19 15 19H5C2.23857 19 0 16.8733 0 14.25V4.75ZM10 5.7C10.5523 5.7 11 6.12533 11 6.65V8.55H13C13.5523 8.55 14 8.97533 14 9.5C14 10.0247 13.5523 10.45 13 10.45H11V12.35C11 12.8747 10.5523 13.3 10 13.3C9.44771 13.3 9 12.8747 9 12.35V10.45H7C6.44772 10.45 6 10.0247 6 9.5C6 8.97533 6.44772 8.55 7 8.55H9V6.65C9 6.12533 9.44771 5.7 10 5.7Z"
                        fill="#565656"
                      />
                    </svg>
                    Add Colour
                  </div>
                </div>

                <template v-if="this.chosenData.gradientTypeOutlines == 'linear'">
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Gradient Rotate
                      <div class="editor__filter-label-side">
                        {{ chosenData.gradientAngleOutlines }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="90"
                      :interval="1"
                      v-model="chosenData.gradientAngleOutlines"
                    ></vue-slider>
                  </div>
                </template>
              </template>
            </template>
          </div>

          <!-- outline color 3D filter -->
          <div
            v-if="chosenElementType == 'text' && is_active_panel == 'colour_outlines_3d'"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Colour Outlines 3D
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <template>
              <!-- filling type filter -->
              <!-- -- -->
              <div class="editor__filter-tabs border">
                <div
                  @click="changeFillingTypeOutlines3D('color')"
                  :class="['editor__filter-tab', fillingTabOutlines3D == 'color' && 'active']"
                >
                  Colour
                </div>
                <div
                  @click="changeFillingTypeOutlines3D('gradient')"
                  :class="['editor__filter-tab', fillingTabOutlines3D == 'gradient' && 'active']"
                >
                  Gradient
                </div>
              </div>
              <!-- -- -->

              <template v-if="fillingTabOutlines3D == 'color'">
                <div class="editor__chosen-bar-item">
                  <sketch-picker
                    @input="
                      (e) => {
                        changeColor(e, 'chosenData', 'stroke3D');
                      }
                    "
                    :value="chosenData.stroke3D"
                  ></sketch-picker>
                </div>
              </template>

              <template v-if="fillingTabOutlines3D == 'gradient'">
                <div class="editor__filter-tabs-radio">
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientTypeOutlines3D = 'linear';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientTypeOutlines3D == 'linear' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>

                    Linear
                  </div>
                  <div
                    @click="
                      () => {
                        this.chosenData.gradientTypeOutlines3D = 'radial';
                      }
                    "
                    :class="[
                      'editor__filter-tab-radio',
                      chosenData.gradientTypeOutlines3D == 'radial' && 'active',
                    ]"
                  >
                    <div class="editor__filter-tab-radio-icon"></div>

                    Radial
                  </div>
                </div>

                <div
                  class="editor__gradient-preset"
                  :style="{
                    background: generateGradient(
                      chosenData.gradientColorsOutlines3D,
                      chosenData.gradientAngleOutlines3D,
                      chosenData.gradientTypeOutlines3D,
                    ),
                  }"
                ></div>

                <div class="editor__gradient-colors">
                  <div
                    v-for="(color, index) in chosenData.gradientColorsOutlines3D"
                    :key="index"
                    class="editor__gradient-color"
                    :style="{ order: chosenData.gradientColorsOutlines3D[index].offset * 100 }"
                  >
                    <div class="editor__gradient-color-tools">
                      <div class="editor__gradient-color-name">Colour {{ index + 1 }}</div>
                      <div class="editor__gradient-color-tools-right">
                        <div class="editor__gradient-tools-color">
                          <div
                            class="editor__gradient-tools-color-container"
                            @click="openColorPanel(`outline_3d_${index}`)"
                            :style="{
                              background: chosenData.gradientColorsOutlines3D[index].color,
                            }"
                          ></div>
                          <div
                            :class="[
                              'editor__gradient-tools-color-picker',
                              colorPanel == `outline_3d_${index}` && 'active',
                            ]"
                          >
                            <div
                              @click="closeColorPanel"
                              class="editor__gradient-tools-color-picker-close"
                            >
                              <svg
                                data-v-ba736d70=""
                                width="15"
                                height="15"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  data-v-ba736d70=""
                                  d="M2 16.4L16.4 2"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  data-v-ba736d70=""
                                  d="M2 2L16.4 16.4"
                                  stroke="#000000"
                                  stroke-width="3.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </div>
                            <sketch-picker
                              @input="
                                (e) => {
                                  changeColor(
                                    e,
                                    'chosenData',
                                    'gradientColorsOutlines3D',
                                    index,
                                    'color',
                                  );
                                }
                              "
                              :value="chosenData.gradientColorsOutlines3D[index].color"
                            ></sketch-picker>
                          </div>
                        </div>

                        <div
                          @click="deleteGradientColorItemOutlines3D(index)"
                          class="editor__gradient-color-delete"
                          v-if="chosenData.gradientColorsOutlines3D.length > 1"
                        >
                          <svg
                            width="13"
                            height="17"
                            viewBox="0 0 13 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.5 7.96875C9.5 7.6755 9.276 7.4375 9 7.4375C8.724 7.4375 8.5 7.6755 8.5 7.96875V14.3438C8.5 14.6375 8.724 14.875 9 14.875C9.276 14.875 9.5 14.6375 9.5 14.3438V7.96875ZM7 7.96875C7 7.6755 6.776 7.4375 6.5 7.4375C6.224 7.4375 6 7.6755 6 7.96875V14.3438C6 14.6375 6.224 14.875 6.5 14.875C6.776 14.875 7 14.6375 7 14.3438V7.96875ZM4.5 7.96875C4.5 7.6755 4.276 7.4375 4 7.4375C3.724 7.4375 3.5 7.6755 3.5 7.96875V14.3438C3.5 14.6375 3.724 14.875 4 14.875C4.276 14.875 4.5 14.6375 4.5 14.3438V7.96875ZM12 14.875C12 16.0485 11.1045 17 10 17H3C1.8955 17 0.999999 16.0485 0.999999 14.875V6.375H12V14.875ZM5 2.125H8V1.59375C8 1.29997 7.776 1.0625 7.5 1.0625H5.5C5.224 1.0625 5 1.29997 5 1.59375V2.125ZM0.999999 2.125H4V1.0625C4 0.476 4.448 0 5 0H8C8.552 0 9 0.476 9 1.0625V2.125H12C12.552 2.125 13 2.601 13 3.1875V4.25C13 4.8365 12.5525 5.31197 12.0005 5.3125H0.998992C0.447493 5.31197 -9.53674e-07 4.8365 -9.53674e-07 4.25V3.1875C-9.53674e-07 2.601 0.447999 2.125 0.999999 2.125Z"
                              fill="#565656"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <vue-slider
                        :min="0"
                        :max="1"
                        :interval="0.01"
                        v-model="chosenData.gradientColorsOutlines3D[index].offset"
                      ></vue-slider>
                    </div>
                  </div>
                  <div @click="addGradientColorItemOutlines3D" class="editor__gradient-color-add">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 1.9C3.34314 1.9 2 3.17599 2 4.75V14.25C2 15.824 3.34315 17.1 5 17.1H15C16.6568 17.1 18 15.824 18 14.25V4.75C18 3.17599 16.6568 1.9 15 1.9H5ZM0 4.75C0 2.12665 2.23858 0 5 0H15C17.7614 0 20 2.12664 20 4.75V14.25C20 16.8733 17.7614 19 15 19H5C2.23857 19 0 16.8733 0 14.25V4.75ZM10 5.7C10.5523 5.7 11 6.12533 11 6.65V8.55H13C13.5523 8.55 14 8.97533 14 9.5C14 10.0247 13.5523 10.45 13 10.45H11V12.35C11 12.8747 10.5523 13.3 10 13.3C9.44771 13.3 9 12.8747 9 12.35V10.45H7C6.44772 10.45 6 10.0247 6 9.5C6 8.97533 6.44772 8.55 7 8.55H9V6.65C9 6.12533 9.44771 5.7 10 5.7Z"
                        fill="#565656"
                      />
                    </svg>
                    Add Colour
                  </div>
                </div>

                <template v-if="this.chosenData.gradientTypeOutlines3D == 'linear'">
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Gradient Rotate
                      <div class="editor__filter-label-side">
                        {{ chosenData.gradientAngleOutlines3D }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="90"
                      :interval="1"
                      v-model="chosenData.gradientAngleOutlines3D"
                    ></vue-slider>
                  </div>
                </template>
              </template>
            </template>
          </div>

          <!-- shadow color filter -->
          <div
            v-if="chosenElementType == 'text' && is_active_panel == 'colour_shadow'"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Colour Shadow
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <template>
              <!-- filling type filter -->
              <!-- -- -->
              <div class="editor__filter-tabs border">
                <div :class="['editor__filter-tab', 'active']">Colour</div>
              </div>
              <!-- -- -->

              <template>
                <div class="editor__chosen-bar-item">
                  <sketch-picker
                    @input="
                      (e) => {
                        changeColor(e, 'chosenData', 'shadowColor');
                      }
                    "
                    :value="chosenData.shadowColor"
                  ></sketch-picker>
                </div>
              </template>
            </template>
          </div>

          <!-- shadow color 3D filter -->
          <div
            v-if="chosenElementType == 'text' && is_active_panel == 'colour_shadow_3d'"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Colour Shadow 3D
              <div @mousedown="closePanel" class="editor__chosen-bar-header-close">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>

            <template>
              <!-- filling type filter -->
              <!-- -- -->
              <div class="editor__filter-tabs border">
                <div :class="['editor__filter-tab', 'active']">Colour</div>
              </div>
              <!-- -- -->

              <template>
                <div class="editor__chosen-bar-item">
                  <sketch-picker
                    @input="
                      (e) => {
                        changeColor(e, 'chosenData', 'shadow3D', 'color');
                      }
                    "
                    :value="chosenData.shadowColor"
                  ></sketch-picker>
                </div>
              </template>
            </template>
          </div>

          <!-- text filter -->
          <!-- -- -->
          <div
            v-if="chosenElementType == 'text' && is_active_panel == null"
            class="editor__chosen-bar-container"
          >
            <div class="editor__chosen-bar-header">
              Text
              <div @mousedown="handleDiscardActiveObject" class="editor__chosen-bar-header-close">
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 16.4L16.4 2"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2 2L16.4 16.4"
                    stroke="#000000"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <!-- basic filter element -->
            <!-- -- -->
            <div class="editor__filter-basic">
              <div
                class="editor__filter-basic-item"
                title="Bring to front element"
                @click="handleTopItem()"
              >
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.99998 13L0 9.941L5.99998 6.882L12 9.941L5.99998 13ZM7.00027 5.86233H5.00056V2.93118L3.00081 5.09769V3.18601L6.00039 0L8.99998 3.18601V5.09769L7.00027 2.93118V5.86233Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Send to back element"
                @click="handleBottomItem()"
              >
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.49998 0L0 3.29431L6.49998 6.58861L13 3.29431L6.49998 0ZM7.58362 7.68672H5.41727V10.8433L3.25088 8.51018V10.5689L6.50043 14L9.74997 10.5689V8.51018L7.58362 10.8433V7.68672Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Duplicate element"
                @click="handleCopyItem()"
              >
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2015 8.77896H10.5426V3.77123C10.5426 3.07954 9.95285 2.5193 9.22475 2.5193H3.95346V1.89334C3.95346 1.5478 4.24866 1.26737 4.61238 1.26737H11.2015C11.5652 1.26737 11.8604 1.5478 11.8604 1.89334V8.15299C11.8604 8.49852 11.5652 8.77896 11.2015 8.77896ZM9.22475 8.77896H4.61238C4.24866 8.77896 3.95346 8.49852 3.95346 8.15299V3.77123H8.56584C8.92956 3.77123 9.22475 4.05167 9.22475 4.3972V8.77896ZM8.56584 11.2828H1.97673C1.61301 11.2828 1.31782 11.0024 1.31782 10.6569V4.3972C1.31782 4.05167 1.61301 3.77123 1.97673 3.77123H2.63564V8.77896C2.63564 9.47065 3.22537 10.0309 3.95346 10.0309H9.22475V10.6569C9.22475 11.0024 8.92956 11.2828 8.56584 11.2828ZM11.8604 0.0154419H3.95346C3.22537 0.0154419 2.63564 0.575681 2.63564 1.26737V2.5193H1.31782C0.589725 2.5193 0 3.07954 0 3.77123V11.2828C0 11.9745 0.589725 12.5347 1.31782 12.5347H9.22475C9.95285 12.5347 10.5426 11.9745 10.5426 11.2828V10.0309H11.8604C12.5885 10.0309 13.1782 9.47065 13.1782 8.77896V1.26737C13.1782 0.575681 12.5885 0.0154419 11.8604 0.0154419Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Delete element"
                @click="handleDeleteItem()"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.56414 1.26737C5.18001 1.26737 4.86862 1.54763 4.86862 1.89334V2.5193H7.65069V1.89334C7.65069 1.54762 7.33931 1.26737 6.95517 1.26737H5.56414ZM9.04172 2.5193V1.89334C9.04172 0.85621 8.10757 0.0154419 6.95517 0.0154419H5.56414C4.41177 0.0154419 3.47758 0.856204 3.47758 1.89334V2.5193H0.695517C0.311394 2.5193 0 2.79956 0 3.14527C0 3.49098 0.311394 3.77123 0.695517 3.77123H1.39103V10.0309C1.39103 11.4138 2.63662 12.5347 4.1731 12.5347H8.3462C9.88273 12.5347 11.1283 11.4138 11.1283 10.0309V3.77123H11.8238C12.2079 3.77123 12.5193 3.49098 12.5193 3.14527C12.5193 2.79956 12.2079 2.5193 11.8238 2.5193H9.04172ZM9.73724 3.77123H2.78207V10.0309C2.78207 10.7223 3.40485 11.2828 4.1731 11.2828H8.3462C9.11448 11.2828 9.73724 10.7223 9.73724 10.0309V3.77123ZM4.86862 5.02316C5.25274 5.02316 5.56414 5.30342 5.56414 5.64913V9.40492C5.56414 9.75063 5.25274 10.0309 4.86862 10.0309C4.4845 10.0309 4.1731 9.75063 4.1731 9.40492V5.64913C4.1731 5.30342 4.4845 5.02316 4.86862 5.02316ZM7.65069 5.02316C8.03481 5.02316 8.3462 5.30342 8.3462 5.64913V9.40492C8.3462 9.75063 8.03481 10.0309 7.65069 10.0309C7.26656 10.0309 6.95517 9.75063 6.95517 9.40492V5.64913C6.95517 5.30342 7.26656 5.02316 7.65069 5.02316Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Mirror horizontally"
                @click="changeFlip('flipX')"
              >
                <svg
                  width="13"
                  height="11"
                  viewBox="0 0 13 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.0970785 10.6739C0.215515 10.8766 0.424724 11 0.650001 11H5.2C5.55899 11 5.85 10.6922 5.85 10.3125V0.687564C5.85 0.368718 5.64271 0.0917124 5.34937 0.0184598C5.05595 -0.0547997 4.75344 0.094923 4.61862 0.380103L0.0686216 10.0051C-0.0321218 10.2182 -0.0213579 10.4712 0.0970785 10.6739ZM1.70173 9.62501L4.55 3.59983V9.62501H1.70173ZM7.15 10.3125C7.15 10.6922 7.441 11 7.8 11H12.35C12.5753 11 12.7845 10.8766 12.903 10.6739C13.0214 10.4712 13.0321 10.2182 12.9314 10.0051L8.38136 0.380103C8.24655 0.094923 7.94404 -0.0547997 7.65063 0.0184598C7.35728 0.0917124 7.15 0.368718 7.15 0.687564V10.3125Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div
                class="editor__filter-basic-item"
                title="Mirror vertically"
                @click="changeFlip('flipY')"
              >
                <svg
                  width="11"
                  height="13"
                  viewBox="0 0 11 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.32608 0.0970781C0.123406 0.215515 1.88878e-06 0.424723 1.87894e-06 0.65L1.68005e-06 5.2C1.66436e-06 5.55899 0.307792 5.85 0.687496 5.85L10.3124 5.85C10.6313 5.85 10.9083 5.64271 10.9815 5.34937C11.0548 5.05595 10.9051 4.75344 10.6199 4.61862L0.994944 0.0686212C0.78182 -0.0321223 0.528753 -0.0213584 0.32608 0.0970781ZM1.37499 1.70173L7.40017 4.55L1.37499 4.55L1.37499 1.70173ZM0.687496 7.15C0.307792 7.15 1.58209e-06 7.441 1.5664e-06 7.8L1.36751e-06 12.35C1.35767e-06 12.5753 0.123406 12.7845 0.32608 12.903C0.528753 13.0214 0.78182 13.0321 0.994943 12.9314L10.6199 8.38136C10.9051 8.24655 11.0548 7.94404 10.9815 7.65063C10.9083 7.35728 10.6313 7.15 10.3124 7.15L0.687496 7.15Z"
                    fill="#565656"
                  />
                </svg>
              </div>

              <div class="editor__filter-basic-item" title="Lock element" @click="handleLockItem()">
                <svg
                  v-if="chosenData.lock"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                    stroke="#565656"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  v-if="!chosenData.lock"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.584 6C15.8124 4.2341 14.0503 3 12 3C9.23858 3 7 5.23858 7 8V10.0288M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C16.8802 10 17.7202 10 18.362 10.327C18.9265 10.6146 19.3854 11.0735 19.673 11.638C20 12.2798 20 13.1198 20 14.8V16.2C20 17.8802 20 18.7202 19.673 19.362C19.3854 19.9265 18.9265 20.3854 18.362 20.673C17.7202 21 16.8802 21 15.2 21H8.8C7.11984 21 6.27976 21 5.63803 20.673C5.07354 20.3854 4.6146 19.9265 4.32698 19.362C4 18.7202 4 17.8802 4 16.2V14.8C4 13.1198 4 12.2798 4.32698 11.638C4.6146 11.0735 5.07354 10.6146 5.63803 10.327C5.99429 10.1455 6.41168 10.0647 7 10.0288Z"
                    stroke="#565656"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <!-- -- -->

            <!-- text input -->
            <!-- -- -->
            <textarea
              class="editor__filter-text-input"
              v-model="chosenData.text"
              name="element_text"
              id="element_text"
              cols="30"
              rows="2"
              :style="{ fontFamily: chosenData.fontFamily }"
            ></textarea>
            <!-- -- -->

            <!-- basic filter element -->
            <!-- -- -->
            <template>
              <div class="editor__chosen-bar-item">
                <div class="editor__filter-label">
                  Opacity
                  <div class="editor__filter-label-side">
                    {{ Math.round(chosenData.opacity * 100) }}%
                  </div>
                </div>
                <vue-slider
                  :min="0"
                  :max="1"
                  :interval="0.05"
                  v-model="chosenData.opacity"
                ></vue-slider>
              </div>

              <div class="editor__chosen-bar-item">
                <div class="editor__filter-label">Font</div>
                <div
                  class="editor__filter-font"
                  :style="{ fontFamily: chosenData.fontFamily }"
                  @click="openPanel('font')"
                >
                  {{ chosenData.fontFamily }}
                  <svg
                    width="15"
                    height="9"
                    viewBox="0 0 15 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.4351 1.70711L8.07117 8.07107C7.68065 8.46159 7.04749 8.46159 6.65696 8.07107L0.292999 1.70711C-0.0975264 1.31658 -0.0975265 0.68342 0.292998 0.292895C0.683523 -0.0976291 1.31669 -0.0976292 1.70721 0.292895L7.27828 5.86396L7.44985 5.86396L13.0209 0.292894C13.4114 -0.0976309 14.0446 -0.097631 14.4351 0.292893C14.8257 0.683418 14.8257 1.31658 14.4351 1.70711Z"
                      fill="#919191"
                    />
                  </svg>
                </div>
              </div>

              <div class="editor__chosen-bar-item">
                <div class="editor__filter-label">
                  Size
                  <div class="editor__filter-label-side">
                    {{ Math.round(chosenData.fontSize * chosenData.scale * 0.75) }} pt
                  </div>
                </div>
                <vue-slider
                  :min="10"
                  :max="500"
                  :interval="2"
                  v-model="chosenData.fontSize"
                ></vue-slider>
              </div>

              <div class="editor__chosen-bar-item">
                <div class="editor__filter-label">Style</div>
                <div class="editor__filter-icon-buttons four">
                  <div
                    @click="
                      () => {
                        if (this.chosenData.fontWeight == 'normal') {
                          this.chosenData.fontWeight = 'bold';
                        } else {
                          this.chosenData.fontWeight = 'normal';
                        }
                      }
                    "
                    :class="[
                      'editor__filter-icon-button',
                      chosenData.fontWeight == 'bold' && 'active',
                    ]"
                  >
                    <svg
                      width="12"
                      height="15"
                      viewBox="0 0 12 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 15V0H6.00586C7.10938 0 8.02979 0.163574 8.76709 0.490723C9.5044 0.817871 10.0586 1.27197 10.4297 1.85303C10.8008 2.4292 10.9863 3.09326 10.9863 3.84522C10.9863 4.43115 10.8691 4.94629 10.6348 5.39063C10.4004 5.83008 10.0781 6.19141 9.66797 6.47461C9.2627 6.75293 8.79883 6.95068 8.27637 7.06787V7.21436C8.84766 7.23877 9.38233 7.3999 9.88037 7.69775C10.3833 7.99561 10.791 8.41309 11.1035 8.9502C11.416 9.48242 11.5723 10.1172 11.5723 10.8545C11.5723 11.6504 11.3745 12.3608 10.979 12.9858C10.5884 13.606 10.0098 14.0967 9.24316 14.458C8.47656 14.8193 7.53174 15 6.40869 15H0ZM3.17139 12.4072H5.75684C6.64063 12.4072 7.28516 12.2388 7.69043 11.9019C8.0957 11.5601 8.29834 11.106 8.29834 10.5396C8.29834 10.1245 8.19824 9.7583 7.99805 9.44092C7.79785 9.12354 7.51221 8.87451 7.14111 8.69385C6.7749 8.51318 6.33789 8.42285 5.83008 8.42285H3.17139V12.4072ZM3.17139 6.27686H5.52246C5.95703 6.27686 6.34277 6.20117 6.67969 6.0498C7.02148 5.89356 7.29004 5.67383 7.48535 5.39063C7.68555 5.10742 7.78565 4.76807 7.78565 4.37256C7.78565 3.83057 7.59277 3.39355 7.20703 3.06152C6.82617 2.72949 6.28418 2.56348 5.58106 2.56348H3.17139V6.27686Z"
                        fill="#565656"
                      />
                    </svg>
                  </div>

                  <div
                    @click="
                      () => {
                        if (this.chosenData.fontStyle == 'normal') {
                          this.chosenData.fontStyle = 'italic';
                        } else {
                          this.chosenData.fontStyle = 'normal';
                        }
                      }
                    "
                    :class="[
                      'editor__filter-icon-button',
                      chosenData.fontStyle == 'italic' && 'active',
                    ]"
                  >
                    <svg
                      width="11"
                      height="15"
                      viewBox="0 0 11 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.71596 0.0439233C3.75988 0.0146411 3.88433 0 4.08931 0C5.45095 0.0585653 6.96632 0.0732064 8.63543 0.0439233L10.3045 0H10.5461L10.612 0.0658863C10.6706 0.124451 10.6999 0.183015 10.6999 0.241581C10.6999 0.475842 10.6266 0.761347 10.4802 1.0981C10.451 1.18594 10.2387 1.22987 9.84334 1.22987C9.77013 1.22987 9.68228 1.22987 9.57979 1.22987C8.71596 1.24451 8.2328 1.30307 8.13031 1.40556L8.04246 1.47145L6.54905 7.53294C5.5388 11.5447 5.03368 13.5725 5.03368 13.6164C5.01903 13.6603 5.08492 13.6896 5.23133 13.7042C5.42167 13.7335 5.7877 13.7555 6.32943 13.7701C6.8858 13.7701 7.19326 13.8214 7.25183 13.9239C7.26647 13.9678 7.24451 14.1362 7.18594 14.429C7.11274 14.7072 7.04685 14.8755 6.98829 14.9341L6.9224 15H6.57101C4.88726 14.9268 2.97657 14.9195 0.838946 14.978C0.341142 14.9927 0.0629575 14.9488 0.00439239 14.8463C-0.0102489 14.8023 0.011713 14.634 0.0702782 14.3411C0.158126 14.0483 0.216691 13.8799 0.245974 13.836L0.333821 13.7701H1.10249C1.87848 13.7555 2.33968 13.7116 2.48609 13.6384C2.53001 13.6237 2.58126 13.5871 2.63982 13.5285L5.67057 1.3836C5.67057 1.36896 5.65593 1.347 5.62665 1.31772C5.50952 1.27379 5.09956 1.24451 4.39678 1.22987C3.82577 1.22987 3.5183 1.18594 3.47438 1.0981C3.4451 1.05417 3.45974 0.885798 3.5183 0.592972C3.59151 0.285505 3.65739 0.102489 3.71596 0.0439233Z"
                        fill="#565656"
                      />
                    </svg>
                  </div>

                  <div
                    @click="
                      () => {
                        this.chosenData.underline = !this.chosenData.underline;
                      }
                    "
                    :class="['editor__filter-icon-button', chosenData.underline && 'active']"
                  >
                    <svg
                      width="12"
                      height="15"
                      viewBox="0 0 12 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.30114 0H10.3636V7.26429C10.3636 8.01071 10.178 8.67857 9.80682 9.26786C9.43561 9.85357 8.91856 10.3161 8.25568 10.6554C7.59659 10.9911 6.82955 11.1589 5.95455 11.1589C5.08333 11.1589 4.31629 10.9893 3.65341 10.65C2.99053 10.3107 2.47348 9.84821 2.10227 9.2625C1.73106 8.67679 1.54545 8.01071 1.54545 7.26429V0H2.60795V7.19464C2.60795 7.77679 2.74621 8.29643 3.02273 8.75357C3.29924 9.20714 3.6875 9.56429 4.1875 9.825C4.69129 10.0857 5.2803 10.2161 5.95455 10.2161C6.62879 10.2161 7.2178 10.0857 7.72159 9.825C8.22538 9.56429 8.61364 9.20714 8.88636 8.75357C9.16288 8.29643 9.30114 7.77679 9.30114 7.19464V0Z"
                        fill="#565656"
                      />
                      <path d="M0 13.9714H11.9091V15H0V13.9714Z" fill="#565656" />
                    </svg>
                  </div>

                  <div
                    @click="
                      () => {
                        this.chosenData.linethrough = !this.chosenData.linethrough;
                      }
                    "
                    :class="['editor__filter-icon-button', chosenData.linethrough && 'active']"
                  >
                    <svg
                      width="13"
                      height="15"
                      viewBox="0 0 13 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.54028 4.0237C9.46445 3.35071 9.15166 2.82938 8.6019 2.45972C8.05213 2.08531 7.36019 1.8981 6.52607 1.8981C5.92891 1.8981 5.41232 1.99289 4.9763 2.18246C4.54028 2.3673 4.20142 2.62322 3.95972 2.95024C3.72275 3.27251 3.60427 3.63981 3.60427 4.05213C3.60427 4.3981 3.68483 4.69668 3.84597 4.94787C4.01185 5.19905 4.22749 5.40995 4.49289 5.58057C4.76303 5.74645 5.05213 5.88626 5.36019 6C5.66825 6.109 5.96446 6.19905 6.24882 6.27014L7.67062 6.63981C8.13507 6.75355 8.61137 6.90758 9.09953 7.1019C9.58768 7.29621 10.0403 7.55213 10.4573 7.86967C10.8744 8.1872 11.2109 8.58057 11.4668 9.04976C11.7275 9.51896 11.8578 10.0806 11.8578 10.7346C11.8578 11.5592 11.6445 12.2915 11.218 12.9313C10.7962 13.5711 10.1825 14.0758 9.37678 14.4455C8.57583 14.8152 7.60664 15 6.46919 15C5.37915 15 4.43602 14.827 3.63981 14.481C2.8436 14.1351 2.22038 13.6446 1.77014 13.0095C1.31991 12.3697 1.07109 11.6114 1.0237 10.7346H3.22749C3.27014 11.2607 3.44076 11.6991 3.73934 12.0498C4.04265 12.3957 4.42891 12.654 4.8981 12.8246C5.37204 12.9905 5.891 13.0735 6.45498 13.0735C7.07583 13.0735 7.62796 12.9763 8.11137 12.782C8.59953 12.5829 8.98341 12.3081 9.26303 11.9573C9.54265 11.6019 9.68246 11.1872 9.68246 10.7133C9.68246 10.282 9.55924 9.92891 9.3128 9.65403C9.07109 9.37915 8.74171 9.15166 8.32465 8.97156C7.91232 8.79147 7.4455 8.6327 6.92417 8.49526L5.20379 8.02607C4.03791 7.70853 3.11374 7.24171 2.43128 6.62559C1.75355 6.00948 1.41469 5.19431 1.41469 4.18009C1.41469 3.34123 1.64218 2.609 2.09716 1.98341C2.55213 1.35782 3.16825 0.872038 3.9455 0.526067C4.72275 0.175356 5.59953 0 6.57583 0C7.56161 0 8.43128 0.172986 9.18483 0.518958C9.94313 0.864929 10.5403 1.34123 10.9763 1.94787C11.4123 2.54976 11.6398 3.24171 11.6588 4.0237H9.54028Z"
                        fill="#565656"
                      />
                      <path d="M0 8.20379H12.8815V9.56872H0V8.20379Z" fill="#565656" />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="editor__chosen-bar-item">
                <div class="editor__filter-label">Align</div>
                <div class="editor__filter-icon-buttons four">
                  <div
                    @click="
                      () => {
                        if (this.chosenData.textAlign == 'left') {
                          this.chosenData.textAlign = 'left';
                        } else {
                          this.chosenData.textAlign = 'left';
                        }
                      }
                    "
                    :class="[
                      'editor__filter-icon-button',
                      chosenData.textAlign == 'left' && 'active',
                    ]"
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 1.07143C0 0.479695 0.335786 0 0.75 0H14.25C14.6642 0 15 0.479695 15 1.07143C15 1.66316 14.6642 2.14286 14.25 2.14286H0.75C0.335786 2.14286 0 1.66316 0 1.07143ZM0 5.35714C0 4.76541 0.335786 4.28571 0.75 4.28571H10.5C10.9142 4.28571 11.25 4.76541 11.25 5.35714C11.25 5.94888 10.9142 6.42857 10.5 6.42857H0.75C0.335786 6.42857 0 5.94888 0 5.35714ZM0 9.64286C0 9.05112 0.335786 8.57143 0.75 8.57143H14.25C14.6642 8.57143 15 9.05112 15 9.64286C15 10.2346 14.6642 10.7143 14.25 10.7143H0.75C0.335786 10.7143 0 10.2346 0 9.64286ZM0 13.9286C0 13.3368 0.335786 12.8571 0.75 12.8571H10.5C10.9142 12.8571 11.25 13.3368 11.25 13.9286C11.25 14.5203 10.9142 15 10.5 15H0.75C0.335786 15 0 14.5203 0 13.9286Z"
                        fill="#565656"
                      />
                    </svg>
                  </div>

                  <div
                    @click="
                      () => {
                        if (this.chosenData.textAlign == 'center') {
                          this.chosenData.textAlign = 'left';
                        } else {
                          this.chosenData.textAlign = 'center';
                        }
                      }
                    "
                    :class="[
                      'editor__filter-icon-button',
                      chosenData.textAlign == 'center' && 'active',
                    ]"
                  >
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 1.07143C0 0.479695 0.402944 0 0.9 0H17.1C17.5971 0 18 0.479695 18 1.07143C18 1.66316 17.5971 2.14286 17.1 2.14286H0.9C0.402944 2.14286 0 1.66316 0 1.07143ZM3.6 5.35714C3.6 4.76541 4.00294 4.28571 4.5 4.28571H13.5C13.9971 4.28571 14.4 4.76541 14.4 5.35714C14.4 5.94888 13.9971 6.42857 13.5 6.42857H4.5C4.00294 6.42857 3.6 5.94888 3.6 5.35714ZM0 9.64286C0 9.05112 0.402944 8.57143 0.9 8.57143H17.1C17.5971 8.57143 18 9.05112 18 9.64286C18 10.2346 17.5971 10.7143 17.1 10.7143H0.9C0.402944 10.7143 0 10.2346 0 9.64286ZM3.6 13.9286C3.6 13.3368 4.00294 12.8571 4.5 12.8571H13.5C13.9971 12.8571 14.4 13.3368 14.4 13.9286C14.4 14.5203 13.9971 15 13.5 15H4.5C4.00294 15 3.6 14.5203 3.6 13.9286Z"
                        fill="#565656"
                      />
                    </svg>
                  </div>

                  <div
                    @click="
                      () => {
                        if (this.chosenData.textAlign == 'right') {
                          this.chosenData.textAlign = 'left';
                        } else {
                          this.chosenData.textAlign = 'right';
                        }
                      }
                    "
                    :class="[
                      'editor__filter-icon-button',
                      chosenData.textAlign == 'right' && 'active',
                    ]"
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 1.07143C15 0.479695 14.6642 0 14.25 0H0.749999C0.335786 0 -9.53674e-07 0.479695 -9.53674e-07 1.07143C-9.53674e-07 1.66316 0.335786 2.14286 0.749999 2.14286H14.25C14.6642 2.14286 15 1.66316 15 1.07143ZM15 5.35714C15 4.76541 14.6642 4.28571 14.25 4.28571H4.5C4.08579 4.28571 3.75 4.76541 3.75 5.35714C3.75 5.94888 4.08579 6.42857 4.5 6.42857H14.25C14.6642 6.42857 15 5.94888 15 5.35714ZM15 9.64286C15 9.05112 14.6642 8.57143 14.25 8.57143H0.749999C0.335786 8.57143 -9.53674e-07 9.05112 -9.53674e-07 9.64286C-9.53674e-07 10.2346 0.335786 10.7143 0.749999 10.7143H14.25C14.6642 10.7143 15 10.2346 15 9.64286ZM15 13.9286C15 13.3368 14.6642 12.8571 14.25 12.8571H4.5C4.08579 12.8571 3.75 13.3368 3.75 13.9286C3.75 14.5203 4.08579 15 4.5 15H14.25C14.6642 15 15 14.5203 15 13.9286Z"
                        fill="#565656"
                      />
                    </svg>
                  </div>

                  <div
                    @click="
                      () => {
                        if (this.chosenData.textAlign == 'justify') {
                          this.chosenData.textAlign = 'left';
                        } else {
                          this.chosenData.textAlign = 'justify';
                        }
                      }
                    "
                    :class="[
                      'editor__filter-icon-button',
                      chosenData.textAlign == 'justify' && 'active',
                    ]"
                  >
                    <svg
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 1.07143C0 0.479695 0.470101 0 1.05 0H19.95C20.5299 0 21 0.479695 21 1.07143C21 1.66316 20.5299 2.14286 19.95 2.14286H1.05C0.470101 2.14286 0 1.66316 0 1.07143ZM0 5.35714C0 4.76541 0.470101 4.28571 1.05 4.28571H19.95C20.5299 4.28571 21 4.76541 21 5.35714C21 5.94888 20.5299 6.42857 19.95 6.42857H1.05C0.470101 6.42857 0 5.94888 0 5.35714ZM0 9.64286C0 9.05112 0.470101 8.57143 1.05 8.57143H19.95C20.5299 8.57143 21 9.05112 21 9.64286C21 10.2346 20.5299 10.7143 19.95 10.7143H1.05C0.470101 10.7143 0 10.2346 0 9.64286ZM0 13.9286C0 13.3368 0.470101 12.8571 1.05 12.8571H19.95C20.5299 12.8571 21 13.3368 21 13.9286C21 14.5203 20.5299 15 19.95 15H1.05C0.470101 15 0 14.5203 0 13.9286Z"
                        fill="#565656"
                      />
                    </svg>
                  </div>
                </div>

                <div class="editor__chosen-bar-item">
                  <div class="editor__filter-label">Colour</div>

                  <div
                    v-if="chosenData.fillType == 'color'"
                    @click="openPanel('colour')"
                    class="editor__filter-color-button"
                    :style="{ background: chosenData.fill }"
                  ></div>
                  <div
                    v-if="chosenData.fillType == 'gradient'"
                    @click="openPanel('colour')"
                    class="editor__filter-color-button"
                    :style="{
                      background: generateGradient(
                        chosenData.gradientColors,
                        chosenData.gradientAngle,
                        chosenData.gradientType,
                      ),
                    }"
                  ></div>
                  <template v-if="chosenData.fillType == 'texture'">
                    <div
                      v-if="chosenData.textureFill"
                      @click="openPanel('colour')"
                      class="editor__filter-color-button"
                    >
                      <img :src="chosenData.textureFill.source.src" alt="" />
                    </div>
                    <div
                      v-if="!chosenData.textureFill"
                      @click="openPanel('colour')"
                      class="editor__filter-color-button"
                      :style="{ background: chosenData.fillColor || '#000' }"
                    ></div>
                  </template>
                </div>

                <div class="editor__chosen-bar-item">
                  <div class="editor__filter-label">
                    Line Height
                    <div class="editor__filter-label-side">
                      {{ chosenData.lineHeight }}
                    </div>
                  </div>
                  <vue-slider
                    :min="0.5"
                    :max="5"
                    :interval="0.1"
                    v-model="chosenData.lineHeight"
                  ></vue-slider>
                </div>

                <div class="editor__chosen-bar-item">
                  <div class="editor__filter-label">
                    Letter Spacing
                    <div class="editor__filter-label-side">
                      {{ chosenData.charSpacing / 10 }}
                    </div>
                  </div>
                  <vue-slider
                    :min="-200"
                    :max="1000"
                    :interval="1"
                    v-model="chosenData.charSpacing"
                  ></vue-slider>
                </div>
              </div>
            </template>
            <!-- -- -->

            <!-- Outlines filter element -->
            <!-- -- -->

            <div
              @click="switcherOutlines"
              :class="['editor__switcher', chosenData.strokeWidth && 'active']"
            >
              <div class="editor__switcher-left">
                <svg
                  width="15"
                  height="9"
                  viewBox="0 0 15 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.4351 1.70711L8.07117 8.07107C7.68065 8.46159 7.04749 8.46159 6.65696 8.07107L0.292999 1.70711C-0.0975264 1.31658 -0.0975265 0.68342 0.292998 0.292895C0.683523 -0.0976291 1.31669 -0.0976292 1.70721 0.292895L7.27828 5.86396L7.44985 5.86396L13.0209 0.292894C13.4114 -0.0976309 14.0446 -0.097631 14.4351 0.292893C14.8257 0.683418 14.8257 1.31658 14.4351 1.70711Z"
                    fill="#919191"
                  />
                </svg>
                Outlines
              </div>
              <div :class="['editor__switcher-right']"></div>
            </div>

            <transition
              name="fade-expand"
              @before-enter="beforeEnter"
              @enter="enter"
              @leave="leave"
            >
              <template v-if="chosenData.strokeWidth > 0">
                <div class="editor__chosen-bar-item hidden-block">
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Width Line
                      <div class="editor__filter-label-side">
                        {{ chosenData.strokeWidth }}
                      </div>
                    </div>
                    <template v-if="!chosenData.is3D">
                      <vue-slider
                        :min="1"
                        :max="200"
                        :interval="1"
                        v-model="chosenData.strokeWidth"
                      ></vue-slider>
                    </template>
                    <template v-if="chosenData.is3D">
                      <vue-slider
                        :min="1"
                        :max="5"
                        :interval="0.2"
                        v-model="chosenData.strokeWidth"
                      ></vue-slider>
                    </template>
                  </div>

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">Outlines Colour</div>

                    <div
                      v-if="chosenData.fillTypeOutlines == 'color'"
                      @click="openPanel('colour_outlines')"
                      class="editor__filter-color-button"
                      :style="{ background: chosenData.stroke }"
                    ></div>
                    <div
                      v-if="chosenData.fillTypeOutlines == 'gradient'"
                      @click="openPanel('colour_outlines')"
                      class="editor__filter-color-button"
                      :style="{
                        background: generateGradient(
                          chosenData.gradientColorsOutlines,
                          chosenData.gradientAngleOutlines,
                          chosenData.gradientTypeOutlines,
                        ),
                      }"
                    ></div>
                  </div>
                </div>
              </template>
            </transition>

            <!-- -- -->

            <!-- Shadow filter element -->
            <!-- -- -->

            <div
              @click="switcherShadow"
              :class="['editor__switcher', chosenData.shadow && 'active']"
            >
              <div class="editor__switcher-left">
                <svg
                  width="15"
                  height="9"
                  viewBox="0 0 15 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.4351 1.70711L8.07117 8.07107C7.68065 8.46159 7.04749 8.46159 6.65696 8.07107L0.292999 1.70711C-0.0975264 1.31658 -0.0975265 0.68342 0.292998 0.292895C0.683523 -0.0976291 1.31669 -0.0976292 1.70721 0.292895L7.27828 5.86396L7.44985 5.86396L13.0209 0.292894C13.4114 -0.0976309 14.0446 -0.097631 14.4351 0.292893C14.8257 0.683418 14.8257 1.31658 14.4351 1.70711Z"
                    fill="#919191"
                  />
                </svg>
                Shadow
              </div>
              <div :class="['editor__switcher-right']"></div>
            </div>

            <transition
              name="fade-expand"
              @before-enter="beforeEnter"
              @enter="enter"
              @leave="leave"
            >
              <template v-if="chosenData.shadow">
                <div class="editor__chosen-bar-item">
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">Shadow Colour</div>
                    <div
                      @click="openPanel('colour_shadow')"
                      class="editor__filter-color-button"
                      :style="{ background: chosenData.shadowColor }"
                    ></div>
                  </div>

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Shadow Blur
                      <div class="editor__filter-label-side">
                        {{ chosenData.shadowBlur * 5 }}
                      </div>
                    </div>
                    <vue-slider
                      :min="1"
                      :max="20"
                      :interval="0.2"
                      v-model="chosenData.shadowBlur"
                    ></vue-slider>
                  </div>

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Shadow Distance X
                      <div class="editor__filter-label-side">
                        {{ chosenData.shadowOffsetX * 5 }}
                      </div>
                    </div>
                    <vue-slider
                      :min="-20"
                      :max="20"
                      :interval="0.2"
                      v-model="chosenData.shadowOffsetX"
                    ></vue-slider>
                  </div>

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      Shadow Distance Y
                      <div class="editor__filter-label-side">
                        {{ chosenData.shadowOffsetY * 5 }}
                      </div>
                    </div>
                    <vue-slider
                      :min="-20"
                      :max="20"
                      :interval="0.2"
                      v-model="chosenData.shadowOffsetY"
                    ></vue-slider>
                  </div>
                </div>
              </template>
            </transition>

            <!-- -- -->

            <!-- 3D filter element -->
            <!-- -- -->
            <div
              @click="chosenData.strokeWidth <= 5 && switcher3D()"
              :class="[
                'editor__switcher',
                chosenData.strokeWidth > 5 && 'disabled',
                chosenData.is3D && 'active',
              ]"
            >
              <div class="editor__switcher-left">
                <svg
                  width="15"
                  height="9"
                  viewBox="0 0 15 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.4351 1.70711L8.07117 8.07107C7.68065 8.46159 7.04749 8.46159 6.65696 8.07107L0.292999 1.70711C-0.0975264 1.31658 -0.0975265 0.68342 0.292998 0.292895C0.683523 -0.0976291 1.31669 -0.0976292 1.70721 0.292895L7.27828 5.86396L7.44985 5.86396L13.0209 0.292894C13.4114 -0.0976309 14.0446 -0.097631 14.4351 0.292893C14.8257 0.683418 14.8257 1.31658 14.4351 1.70711Z"
                    fill="#919191"
                  />
                </svg>
                3D
              </div>
              <div :class="['editor__switcher-right']"></div>
            </div>

            <transition
              name="fade-expand"
              @before-enter="beforeEnter"
              @enter="enter"
              @leave="leave"
            >
              <template v-if="chosenData.is3D">
                <div>
                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      3D Opacity
                      <div class="editor__filter-label-side">
                        {{ Math.round(chosenData.opacity3D * 100) }}%
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="1"
                      :interval="0.05"
                      v-model="chosenData.opacity3D"
                    ></vue-slider>
                  </div>

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">3D Colour</div>

                    <div
                      v-if="chosenData.fillType3D == 'color'"
                      @click="openPanel('colour_3d')"
                      class="editor__filter-color-button"
                      :style="{ background: chosenData.fillColor3D }"
                    ></div>
                    <div
                      v-if="chosenData.fillType3D == 'gradient'"
                      @click="openPanel('colour_3d')"
                      class="editor__filter-color-button"
                      :style="{
                        background: generateGradient(
                          chosenData.gradientColors3D,
                          chosenData.gradientAngle3D,
                          chosenData.gradientType3D,
                        ),
                      }"
                    ></div>
                  </div>

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      3D Rotate
                      <div class="editor__filter-label-side">
                        {{ chosenData.angle3D }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="360"
                      :interval="1"
                      v-model="chosenData.angle3D"
                    ></vue-slider>
                  </div>

                  <div class="editor__chosen-bar-item">
                    <div class="editor__filter-label">
                      3D Length
                      <div class="editor__filter-label-side">
                        {{ chosenData.length3D }}
                      </div>
                    </div>
                    <vue-slider
                      :min="0"
                      :max="30"
                      :interval="1"
                      v-model="chosenData.length3D"
                    ></vue-slider>
                  </div>

                  <!-- Outlines 3D filter element -->
                  <!-- -- -->

                  <div
                    @click.self="chosenData.strokeWidth3D == 0 ? switcherOutlines3D() : ''"
                    :class="[chosenData.strokeWidth3D == 0 && 'blur-container']"
                  >
                    <div
                      @click="switcherOutlines3D"
                      :class="['editor__switcher white', chosenData.strokeWidth3D > 0 && 'active']"
                    >
                      <div class="editor__switcher-left">3D Outlines</div>
                      <div :class="['editor__switcher-right']"></div>
                    </div>

                    <template>
                      <div class="editor__chosen-bar-item">
                        <div class="editor__chosen-bar-item">
                          <div class="editor__filter-label">
                            Width Line
                            <div class="editor__filter-label-side">
                              {{ chosenData.strokeWidth3D }}
                            </div>
                          </div>
                          <vue-slider
                            :min="1"
                            :max="200"
                            :interval="1"
                            v-model="chosenData.strokeWidth3D"
                          ></vue-slider>
                        </div>

                        <div class="editor__chosen-bar-item">
                          <div class="editor__filter-label">3D Outlines Colour</div>

                          <div
                            v-if="chosenData.fillTypeOutlines3D == 'color'"
                            @click="openPanel('colour_outlines_3d')"
                            class="editor__filter-color-button"
                            :style="{ background: chosenData.stroke3D }"
                          ></div>
                          <div
                            v-if="chosenData.fillTypeOutlines3D == 'gradient'"
                            @click="openPanel('colour_outlines_3d')"
                            class="editor__filter-color-button"
                            :style="{
                              background: generateGradient(
                                chosenData.gradientColorsOutlines3D,
                                chosenData.gradientAngleOutlines3D,
                                chosenData.gradientTypeOutlines3D,
                              ),
                            }"
                          ></div>
                        </div>
                      </div>
                    </template>
                  </div>

                  <!-- Shadow filter element -->
                  <!-- -- -->

                  <div
                    @click.self="chosenData.shadow3D == null ? switcherShadow3D() : ''"
                    :class="[chosenData.shadow3D == null && 'blur-container']"
                  >
                    <div
                      @click="switcherShadow3D"
                      :class="['editor__switcher white', chosenData.shadow3D && 'active']"
                    >
                      <div class="editor__switcher-left">3D Shadows</div>
                      <div :class="['editor__switcher-right']"></div>
                    </div>

                    <template>
                      <div class="editor__chosen-bar-item">
                        <div class="editor__chosen-bar-item">
                          <div class="editor__filter-label">3D Shadow Colour</div>
                          <div
                            @click="openPanel('colour_shadow_3d')"
                            class="editor__filter-color-button"
                            :style="{
                              background: chosenData.shadow3D
                                ? chosenData.shadow3D.color
                                : '#000000',
                            }"
                          ></div>
                        </div>

                        <div class="editor__chosen-bar-item">
                          <div class="editor__filter-label">
                            3D Shadow Blur
                            <div class="editor__filter-label-side">
                              {{ chosenData.shadow3D ? chosenData.shadow3D.blur * 5 : 1 }}
                            </div>
                          </div>
                          <vue-slider
                            v-if="chosenData.shadow3D"
                            :min="1"
                            :max="20"
                            :interval="0.2"
                            v-model="chosenData.shadow3D.blur"
                          ></vue-slider>
                          <vue-slider
                            v-else
                            :min="1"
                            :max="20"
                            :interval="0.2"
                            v-model="chosenData.shadow3D"
                          ></vue-slider>
                        </div>

                        <div class="editor__chosen-bar-item">
                          <div class="editor__filter-label">
                            3D Shadow Distance X
                            <div class="editor__filter-label-side">
                              {{ chosenData.shadow3D ? chosenData.shadow3D.offsetX * 5 : 1 }}
                            </div>
                          </div>
                          <vue-slider
                            v-if="chosenData.shadow3D"
                            :min="-20"
                            :max="20"
                            :interval="0.2"
                            v-model="chosenData.shadow3D.offsetX"
                          ></vue-slider>
                          <vue-slider
                            v-else
                            :min="-20"
                            :max="20"
                            :interval="0.2"
                            v-model="chosenData.shadow3D"
                          ></vue-slider>
                        </div>

                        <div class="editor__chosen-bar-item">
                          <div class="editor__filter-label">
                            Shadow Distance Y
                            <div class="editor__filter-label-side">
                              {{ chosenData.shadow3D ? chosenData.shadow3D.offsetY * 5 : 1 }}
                            </div>
                          </div>
                          <vue-slider
                            v-if="chosenData.shadow3D"
                            :min="-20"
                            :max="20"
                            :interval="0.2"
                            v-model="chosenData.shadow3D.offsetY"
                          ></vue-slider>
                          <vue-slider
                            v-else
                            :min="-20"
                            :max="20"
                            :interval="0.2"
                            v-model="chosenData.shadow3D"
                          ></vue-slider>
                        </div>
                      </div>
                    </template>
                  </div>

                  <!-- -- -->
                  <!-- -- -->
                </div>
              </template>
            </transition>
            <!-- -- -->
          </div>
        </div>
      </div>
      <div
        @mousedown.self="handleDiscardActiveObject()"
        @click="closePanel()"
        class="editor__canvas-wrap"
      >
        <div
          @mousedown.self="handleDiscardActiveObject()"
          ref="canvasContainer"
          class="editor__canvas-container"
        >
          <div
            @mouseover="
              () => {
                this.toCanvas = true;
                this.handleRemoveGuide();
              }
            "
            @mouseleave="
              () => {
                this.toCanvas = false;
                this.handleRemoveGuide();
              }
            "
            v-if="this.canvas != null"
            :style="{
              width: `${canvasWidth * zoom}px`,
              height: `${canvasHeight * zoom}px`,
              minWidth: `${canvasWidth * zoom}px`,
              minHeight: `${canvasHeight * zoom}px`,
            }"
            class="editor__canvas"
            id="canvas-container"
          >
            <div
              id="canvas"
              :style="{
                width: `${canvasWidth * zoom}px`,
                height: `${canvasHeight * zoom}px`,
                minWidth: `${canvasWidth * zoom}px`,
                minHeight: `${canvasHeight * zoom}px`,
              }"
            >
              <canvas ref="can" :width="canvasWidth" :height="canvasHeight"></canvas>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!mobileListPanel && elementList.length"
        @click="openMobileListPanel"
        class="editor__list-elements-burger"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M20 7L4 7" stroke="#000" stroke-width="1.5" stroke-linecap="round" />
          <path d="M15 12L4 12" stroke="#000" stroke-width="1.5" stroke-linecap="round" />
          <path d="M9 17H4" stroke="#000" stroke-width="1.5" stroke-linecap="round" />
        </svg>
      </div>

      <div
        v-if="mobileListPanel && elementList.length"
        @click="closeMobileListPanel"
        class="editor__list-elements-burger close"
      >
        <svg
          data-v-ba736d70=""
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
        >
          <path
            data-v-ba736d70=""
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.67456 0.329502C10.1084 0.768849 10.1084 1.48115 9.67456 1.9205L3.79357 7.87501H18.8889C19.5026 7.87501 20 8.37868 20 9.00001C20 9.62135 19.5026 10.125 18.8889 10.125H3.79357L9.67456 16.0795C10.1084 16.5189 10.1084 17.2312 9.67456 17.6705C9.24067 18.1098 8.53711 18.1098 8.10322 17.6705L0.325433 9.7955C0.117067 9.58456 0 9.29836 0 9.00001C0 8.70166 0.117067 8.41546 0.325433 8.20452L8.10322 0.329502C8.53711 -0.109834 9.24067 -0.109834 9.67456 0.329502Z"
            fill="black"
          ></path>
        </svg>
      </div>

      <div :class="['editor__list-elements', !mobileListPanel && 'hide']">
        <div
          v-for="element in elementList"
          :key="element.id"
          :class="['editor__list-element', element.id == chosenElementID && 'active']"
          @click="handleChooseElement(element.id)"
        >
          <div v-if="element.type == 'image'" class="editor__list-element-item">
            <img :src="element.image" alt="" />
          </div>
          <div v-if="element.type == 'text'" class="editor__list-element-item">
            <div class="editor__list-element-item-text">{{ element.text }}</div>
          </div>
          <div v-if="element.type == 'vector'" class="editor__list-element-item">Vector Group</div>
          <div v-if="element.lock" class="editor__list-element-lock">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 10.0288C7.47142 10 8.05259 10 8.8 10H15.2C15.9474 10 16.5286 10 17 10.0288M7 10.0288C6.41168 10.0647 5.99429 10.1455 5.63803 10.327C5.07354 10.6146 4.6146 11.0735 4.32698 11.638C4 12.2798 4 13.1198 4 14.8V16.2C4 17.8802 4 18.7202 4.32698 19.362C4.6146 19.9265 5.07354 20.3854 5.63803 20.673C6.27976 21 7.11984 21 8.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V14.8C20 13.1198 20 12.2798 19.673 11.638C19.3854 11.0735 18.9265 10.6146 18.362 10.327C18.0057 10.1455 17.5883 10.0647 17 10.0288M7 10.0288V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V10.0288"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- zoom -->

    <div v-if="canvas != null" class="editor__zoom-container">
      <div v-if="zoomScale != 1" class="editor__zoom-input">{{ Math.round(zoomScale * 100) }}%</div>
      <div @click="centerCanvas" class="editor__zoom-element">
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 1024 1024">
          <path
            fill="#000000"
            d="m160 96.064 192 .192a32 32 0 0 1 0 64l-192-.192V352a32 32 0 0 1-64 0V96h64v.064zm0 831.872V928H96V672a32 32 0 1 1 64 0v191.936l192-.192a32 32 0 1 1 0 64l-192 .192zM864 96.064V96h64v256a32 32 0 1 1-64 0V160.064l-192 .192a32 32 0 1 1 0-64l192-.192zm0 831.872-192-.192a32 32 0 0 1 0-64l192 .192V672a32 32 0 1 1 64 0v256h-64v-.064z"
          />
        </svg>
      </div>
      <div @click="handleZoomScale(true)" class="editor__zoom-element">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          width="20px"
          height="20px"
          viewBox="0 0 32 32"
          version="1.1"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketch:type="MSPage"
          >
            <g
              id="Icon-Set"
              sketch:type="MSLayerGroup"
              transform="translate(-308.000000, -1139.000000)"
              fill="#000000"
            >
              <path
                d="M321.46,1163.45 C315.17,1163.45 310.07,1158.44 310.07,1152.25 C310.07,1146.06 315.17,1141.04 321.46,1141.04 C327.75,1141.04 332.85,1146.06 332.85,1152.25 C332.85,1158.44 327.75,1163.45 321.46,1163.45 L321.46,1163.45 Z M339.688,1169.25 L331.429,1161.12 C333.592,1158.77 334.92,1155.67 334.92,1152.25 C334.92,1144.93 328.894,1139 321.46,1139 C314.026,1139 308,1144.93 308,1152.25 C308,1159.56 314.026,1165.49 321.46,1165.49 C324.672,1165.49 327.618,1164.38 329.932,1162.53 L338.225,1170.69 C338.629,1171.09 339.284,1171.09 339.688,1170.69 C340.093,1170.3 340.093,1169.65 339.688,1169.25 L339.688,1169.25 Z M326.519,1151.41 L322.522,1151.41 L322.522,1147.41 C322.522,1146.85 322.075,1146.41 321.523,1146.41 C320.972,1146.41 320.524,1146.85 320.524,1147.41 L320.524,1151.41 L316.529,1151.41 C315.978,1151.41 315.53,1151.59 315.53,1152.14 C315.53,1152.7 315.978,1153.41 316.529,1153.41 L320.524,1153.41 L320.524,1157.41 C320.524,1157.97 320.972,1158.41 321.523,1158.41 C322.075,1158.41 322.522,1157.97 322.522,1157.41 L322.522,1153.41 L326.519,1153.41 C327.07,1153.41 327.518,1152.96 327.518,1152.41 C327.518,1151.86 327.07,1151.41 326.519,1151.41 L326.519,1151.41 Z"
                id="zoom-in"
                sketch:type="MSShapeGroup"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div @click="handleZoomScale(false)" class="editor__zoom-element">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          width="20px"
          height="20px"
          viewBox="0 0 32 32"
          version="1.1"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            sketch:type="MSPage"
          >
            <g
              id="Icon-Set"
              sketch:type="MSLayerGroup"
              transform="translate(-360.000000, -1139.000000)"
              fill="#000000"
            >
              <path
                d="M373.46,1163.45 C367.17,1163.45 362.071,1158.44 362.071,1152.25 C362.071,1146.06 367.17,1141.04 373.46,1141.04 C379.75,1141.04 384.85,1146.06 384.85,1152.25 C384.85,1158.44 379.75,1163.45 373.46,1163.45 L373.46,1163.45 Z M391.688,1169.25 L383.429,1161.12 C385.592,1158.77 386.92,1155.67 386.92,1152.25 C386.92,1144.93 380.894,1139 373.46,1139 C366.026,1139 360,1144.93 360,1152.25 C360,1159.56 366.026,1165.49 373.46,1165.49 C376.672,1165.49 379.618,1164.38 381.932,1162.53 L390.225,1170.69 C390.629,1171.09 391.284,1171.09 391.688,1170.69 C392.093,1170.3 392.093,1169.65 391.688,1169.25 L391.688,1169.25 Z M378.689,1151.41 L368.643,1151.41 C368.102,1151.41 367.663,1151.84 367.663,1152.37 C367.663,1152.9 368.102,1153.33 368.643,1153.33 L378.689,1153.33 C379.23,1153.33 379.669,1152.9 379.669,1152.37 C379.669,1151.84 379.23,1151.41 378.689,1151.41 L378.689,1151.41 Z"
                id="zoom-out"
                sketch:type="MSShapeGroup"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <!-- modals -->

    <div @click.self="handleCloseResizeModal" v-if="resizeModal" class="e-modal">
      <div class="e-modal__container">
        <div @click="handleCloseResizeModal" class="e-modal__close">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.168977 0.180242C0.394279 -0.0600807 0.759567 -0.0600807 0.984869 0.180242L7.5 7.12971L14.0151 0.180242C14.2404 -0.0600807 14.6057 -0.0600807 14.831 0.180242C15.0563 0.420565 15.0563 0.810205 14.831 1.05053L8.31589 8L14.831 14.9495C15.0563 15.1898 15.0563 15.5794 14.831 15.8198C14.6057 16.0601 14.2404 16.0601 14.0151 15.8198L7.5 8.87029L0.984869 15.8198C0.759567 16.0601 0.394279 16.0601 0.168977 15.8198C-0.0563257 15.5794 -0.0563256 15.1898 0.168977 14.9495L6.68411 8L0.168977 1.05053C-0.0563256 0.810205 -0.0563257 0.420565 0.168977 0.180242Z"
              fill="black"
            />
          </svg>
        </div>
        <div class="e-modal__resize-container">
          <template v-if="canvas != null">
            <div class="e-modal__resize-title">Popular Size</div>
            <div class="e-modal__chosen-size-items">
              <div
                v-for="item in sizes"
                :key="item.id"
                @click="resizeCanvas(item)"
                :class="['e-modal__chosen-size-item', item.name == chosenSize.name && 'active']"
              >
                {{ item.name }} - {{ item.sizeName }} - {{ item.width }} x {{ item.height }} pixels
              </div>
            </div>
          </template>
          <template v-if="canvas == null">
            <div class="editor__panel-sizes">
              <div class="editor__select">
                <div class="editor__select-button">
                  Choose size
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 15 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.435 1.70711L8.07105 8.07107C7.68053 8.46159 7.04736 8.46159 6.65684 8.07107L0.292876 1.70711C-0.0976484 1.31658 -0.0976485 0.68342 0.292876 0.292895C0.683401 -0.0976291 1.31657 -0.0976292 1.70709 0.292895L7.27816 5.86396L7.44973 5.86396L13.0208 0.292894C13.4113 -0.0976309 14.0445 -0.097631 14.435 0.292893C14.8255 0.683418 14.8255 1.31658 14.435 1.70711Z"
                      fill="#d9d9d9"
                    />
                  </svg>
                </div>
                <div class="editor__select-list">
                  <div
                    v-for="item in sizes"
                    :key="item.id"
                    class="editor__select-list-item"
                    @click="changeCanvasAspect(item)"
                  >
                    {{ item.name }} - {{ item.sizeName }} - {{ item.width }} x
                    {{ item.height }} pixels
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div @click.self="handleCloseDownloadModal" v-if="downloadModal" class="e-modal">
      <div class="e-modal__container">
        <div @click="handleCloseResizeModal" class="e-modal__close">
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.168977 0.180242C0.394279 -0.0600807 0.759567 -0.0600807 0.984869 0.180242L7.5 7.12971L14.0151 0.180242C14.2404 -0.0600807 14.6057 -0.0600807 14.831 0.180242C15.0563 0.420565 15.0563 0.810205 14.831 1.05053L8.31589 8L14.831 14.9495C15.0563 15.1898 15.0563 15.5794 14.831 15.8198C14.6057 16.0601 14.2404 16.0601 14.0151 15.8198L7.5 8.87029L0.984869 15.8198C0.759567 16.0601 0.394279 16.0601 0.168977 15.8198C-0.0563257 15.5794 -0.0563256 15.1898 0.168977 14.9495L6.68411 8L0.168977 1.05053C-0.0563256 0.810205 -0.0563257 0.420565 0.168977 0.180242Z"
              fill="black"
            />
          </svg>
        </div>
        <div class="e-modal__resize-container">
          <template v-if="canvas != null">
            <div class="e-modal__resize-title">Download</div>
            <div class="e-modal__chosen-size-items">
              <div
                @click="chosenTypeDownload('standard')"
                :class="['e-modal__chosen-size-item', typeDownload == 'standard' && 'active']"
              >
                Standart image - for online use and draft prints
              </div>
            </div>
            <div class="e-modal__download-button-container">
              <div @click="handleDownload()" class="e-modal__download-button">Download</div>
              <a ref="linkDownload" href="#" download=""></a>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loading">
      <div class="loader">
        <div class="square"></div>
        <div class="square"></div>
        <div class="square last"></div>
        <div class="square clear"></div>
        <div class="square"></div>
        <div class="square last"></div>
        <div class="square clear"></div>
        <div class="square"></div>
        <div class="square last"></div>
      </div>
    </div>
  </div>
</template>

<script>
import referencesApi from "~/api/references";
import templatesApi from "~/api/templates";

import fabric from "~/plugins/fabric";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { debounce } from "lodash";

import { Sketch } from "vue-color";

export default {
  name: "EditorTemplate",
  data() {
    return {
      is_active_panel: null,
      listChosenElementId: null,
      mobileActivePanel: false,
      mobileListPanel: false,
      toCanvas: false,
      loading: false,
      zoom: 0.5,
      colorPanel: null,
      editor_save: null,
      layout_template: null,
      zoomIndex: 0.5,
      zoomScale: 1,
      canvas: null,
      pause_saving: false,
      pause_saving_history: false,
      undo_stack: [],
      redo_stack: [],
      filterTab: "basic",
      fillingTab: "color",
      fillingTab3D: "color",
      fillingTabBackground: "color",
      fillingTabOutlines: "color",
      fillingTabOutlines3D: "color",
      chosenElement: null,
      chosenElementType: null,
      modalFont: false,
      elementList: [],
      chosenElementID: null,
      isUpdating: false,
      freezeNG: false,
      freezeNGO: false,
      freezeNG3D: false,
      saveIntervalId: null,
      inputAddText: "",
      canvasWidth: "540",
      canvasHeight: "675",
      chosenSize: null,
      resizeModal: false,
      downloadModal: false,
      typeDownload: "standard",

      background: {
        fillType: "color",
        fillColor: "#ffffff",
        gradientType: "linear",
        gradientAngle: 0,
        gradientColors: [],
      },

      chosenData: {
        lock: false,
        scale: 1,
        globalCompositeOperation: null,
        flipX: false,
        flipY: false,

        opacity: 1,
        fill: "",
        fontFamily: "",
        strokeWidth: 0,
        stroke: "#fff",
        charSpacing: 1,

        strokeWidth3D: 0,
        stroke3D: "#fff",

        is3D: false,
        angle3D: 0,
        length3D: 0,
        opacity3D: 1,
        fillType3D: "color",
        gradientAngle3D: 0,
        gradientType3D: "linear",
        fillColor3D: "#000",
        gradientColors3D: [],
        textureFill3D: null,
        shadow3D: null,
        text: "",
        fontSize: 20,
        fontWeight: "normal",
        fontStyle: "normal",
        underline: false,
        linethrough: false,
        textAlign: "left",
        angle: 0,
        lineHeight: 1.2,
        shadow: null,

        shadowColor: "#000000",
        shadowBlur: 5,
        shadowOffsetX: 1,
        shadowOffsetY: 1,

        fillType: "color",
        gradientAngle: 0,
        gradientType: "linear",
        fillColor: "#000",
        gradientColors: [],
        textureFill: null,
        textureOffset: null,

        fillTypeOutlines: "color",
        gradientAngleOutlines: 0,
        gradientTypeOutlines: "linear",
        fillColorOutlines: "#000",
        gradientColorsOutlines: [],

        fillTypeOutlines3D: "color",
        gradientAngleOutlines3D: 0,
        gradientTypeOutlines3D: "linear",
        fillColorOutlines3D: "#000",
        gradientColorsOutlines3D: [],
      },
      template: {
        font: {},
      },
      fontsList: [],
      fontsSearch: "",
      globalCompositeOperationArray: [
        {
          id: 1111,
          name: "source-over",
        },
        {
          id: 1,
          name: "multiply",
        },
        {
          id: 2,
          name: "screen",
        },
        {
          id: 3,
          name: "overlay",
        },
        {
          id: 4,
          name: "darken",
        },
        {
          id: 5,
          name: "lighten",
        },
        {
          id: 6,
          name: "color-dodge",
        },
        {
          id: 7,
          name: "color-burn",
        },
        {
          id: 8,
          name: "hard-light",
        },
        {
          id: 9,
          name: "soft-light",
        },
        {
          id: 10,
          name: "difference",
        },
        {
          id: 11,
          name: "exclusion",
        },
      ],
      sizes: [
        {
          id: 1,
          name: "A6",
          sizeName: "105 x 148 mm",
          width: "1240",
          height: "1748",
          zoom: 0.4,
        },
        {
          id: 2,
          name: "A5",
          sizeName: "148 x 210 mm",
          width: "1748",
          height: "2480",
          zoom: 0.4,
        },
        {
          id: 3,
          name: "A4",
          sizeName: "210 x 297 mm",
          width: "2480",
          height: "3508",
          zoom: 0.4,
        },
        {
          id: 4,
          name: "Half Letter",
          sizeName: "5.5 x 8.5 in",
          width: "1240",
          height: "1748",
          zoom: 0.4,
        },
        {
          id: 5,
          name: "Letter (Flyer)",
          sizeName: "8.5 x 11 in",
          width: "1748",
          height: "2480",
          zoom: 0.4,
        },
        {
          id: 6,
          name: "Legal",
          sizeName: "8.5 x 14 in",
          width: "2480",
          height: "3508",
          zoom: 0.5,
        },
      ],

      toJsonArray: [
        "id",
        "indexScaleX",
        "indexScaleY",
        "scaleX",
        "scaleY",
        "isNoHistoryElement",
        "idParent",
        "elementType",
        "lockMovementX",
        "lockMovementY",
        "fontUrl",
        "padding",

        "index3DAngle",
        "length3D",
        "is3D",
        "opacity3D",
        "gradientAngle3D",
        "gradientType3D",
        "gradientColors3D",
        "fillType3D",
        "fillColor3D",
        "textureFill3D",
        "textureFillSource3D",
        "strokeWidth3D",
        "stroke3D",
        "shadow3D",

        "typeElement",
        "fillType",
        "gradientAngle",
        "gradientType",
        "fillColor",
        "gradientColors",
        "textureFill",
        "textureOffset",
        "chosenSize",

        "fillTypeOutlines",
        "gradientAngleOutlines",
        "gradientTypeOutlines",
        "fillColorOutlines",
        "gradientColorsOutlines",

        "fillTypeOutlines3D",
        "gradientAngleOutlines3D",
        "gradientTypeOutlines3D",
        "fillColorOutlines3D",
        "gradientColorsOutlines3D",

        "charSpacing",
        "isGuide",
        "isBorder",
      ],
    };
  },
  components: {
    VueSlider,
    "sketch-picker": Sketch,
  },
  watch: {
    background: {
      deep: true,
      immediate: true,
      handler() {
        if (this.canvas) {
          this.canvas.set({
            gradientAngle: this.background.gradientAngle,
            gradientType: this.background.gradientType,
            gradientColors: this.background.gradientColors,
          });
          if (this.background.fillType == "color") {
            this.canvas.set({
              fillColor: this.background.fillColor,
            });
            this.canvas.setBackgroundColor(
              this.background.fillColor,
              this.canvas.renderAll.bind(this.canvas),
            );
          }
          if (this.background.fillType == "gradient") {
            this.normalizeGradientBackground();
          }
        }
      },
    },
    chosenData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.isUpdating) return;
        this.isUpdating = true;
        if (this.chosenElement != null) {
          // image change
          const obj = this.canvas.getActiveObject();

          if (this.chosenData.lock) {
            obj.set({
              lockMovementX: true,
              lockMovementY: true,
            });
          } else {
            obj.set({
              lockMovementX: false,
              lockMovementY: false,
            });
          }

          if (this.chosenElementType == "image" || this.chosenElementType == "vector") {
            obj.set({
              opacity: this.chosenData.opacity,
            });
            this.canvas.renderAll();
          }
          if (this.chosenElementType == "vector") {
            obj.set({
              fill: this.chosenData.fill,
            });

            obj.getObjects().forEach((itemObj) => {
              itemObj.set("fill", this.chosenData.fill);
              itemObj.set("stroke", this.chosenData.fill);
            });
          }
          // text change
          if (this.chosenElementType == "text") {
            obj.set({
              opacity: this.chosenData.opacity,
              fill: this.chosenData.fill,
              text: this.chosenData.text,
              fontSize: this.chosenData.fontSize,
              fontWeight: this.chosenData.fontWeight,
              fontStyle: this.chosenData.fontStyle,
              underline: this.chosenData.underline,
              linethrough: this.chosenData.linethrough,
              textAlign: this.chosenData.textAlign,
              // angle: this.chosenData.angle,
              lineHeight: this.chosenData.lineHeight,
              charSpacing: this.chosenData.charSpacing,

              strokeWidth: this.chosenData.strokeWidth,
              stroke: this.chosenData.stroke,
            });

            if (this.chosenData.fillTypeOutlines == "color") {
              obj.set({
                fillColorOutlines: this.chosenData.stroke,
              });
            }

            if (this.chosenData.fillTypeOutlines == "gradient") {
              obj.set({
                gradientAngleOutlines: this.chosenData.gradientAngleOutlines,
                gradientTypeOutlines: this.chosenData.gradientTypeOutlines,
                gradientColorsOutlines: this.chosenData.gradientColorsOutlines,
              });
            }

            if (this.chosenData.fillType == "color") {
              obj.set({
                fillColor: this.chosenData.fill,
              });
            }

            if (this.chosenData.fillType == "gradient") {
              obj.set({
                gradientAngle: this.chosenData.gradientAngle,
                gradientType: this.chosenData.gradientType,
                gradientColors: this.chosenData.gradientColors,
              });
            }

            if (this.chosenData.fillType == "texture") {
              obj.set({
                textureFill: this.chosenData.fill,
                textureOffset: this.chosenData.textureOffset,
              });
              if (this.chosenData.textureOffset) {
                obj.fill.patternTransform = [
                  this.chosenData.textureOffset,
                  0,
                  0,
                  this.chosenData.textureOffset,
                  0,
                  0,
                ];
                obj.set("dirty", true);
              }
            }

            if (this.chosenData.is3D) {
              obj.set({
                length3D: this.chosenData.length3D,
                index3DAngle: this.chosenData.angle3D,
                opacity3D: this.chosenData.opacity3D,
                gradientAngle3D: this.chosenData.gradientAngle3D,
                gradientType3D: this.chosenData.gradientType3D,
                gradientColors3D: this.chosenData.gradientColors3D,
                fillType3D: this.chosenData.fillType3D,
                fillColor3D: this.chosenData.fillColor3D,
                textureFill3D: this.chosenData.textureFill3D,
                strokeWidth3D: this.chosenData.strokeWidth3D,
                stroke3D: this.chosenData.stroke3D,
                shadow3D: this.chosenData.shadow3D,
              });

              if (this.chosenData.fillTypeOutlines3D == "color") {
                obj.set({
                  fillColorOutlines3D: this.chosenData.stroke3D,
                });
              }

              if (this.chosenData.fillTypeOutlines3D == "gradient") {
                obj.set({
                  gradientAngleOutlines3D: this.chosenData.gradientAngleOutlines3D,
                  gradientTypeOutlines3D: this.chosenData.gradientTypeOutlines3D,
                  gradientColorsOutlines3D: this.chosenData.gradientColorsOutlines3D,
                });
              }
              this.debouncedNormalizeGradientOutlines3D(obj);
              this.debouncedAdd3DFor(this.chosenData.length3D, this.chosenData.angle3D);
            }

            if (obj.shadow) {
              obj.set({
                shadow: {
                  color: this.chosenData.shadowColor,
                  blur: this.chosenData.shadowBlur,
                  offsetX: this.chosenData.shadowOffsetX,
                  offsetY: this.chosenData.shadowOffsetY,
                },
              });
            }
          }
          this.canvas.renderAll();
          this.debouncedNormalizeGradientOutlines(obj);
          this.debouncedNormalizeGradient(obj);
          this.debouncedGetListElement();

          if (!this.pause_saving_history && !this.pause_saving) {
            this.debouncedHandleSaveHistory();
          }
        }
        this.$nextTick(() => {
          this.isUpdating = false;
        });
      },
    },
  },
  mounted() {
    this.getTemplateLayout();
    this.getFontList();

    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  beforeRouteLeave(to, from) {
    const answer = window.confirm("Do you really want to leave? you have unsaved changes!");
    if (!answer) return false;
  },
  methods: {
    handleBeforeUnload(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    cleanCanvas() {
      if (confirm("Are you sure you want to clear the canvas? This action cannot be undone.")) {
        const url = `/${this.$route.params.id}`;
        const data = {
          data: null,
          template_id: this.layout_template.templateId,
        };
        templatesApi
          .editTemplateLayout(url, data)
          .then((res) => {
            location.reload();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      } else {
        console.log("Canvas clear action was cancelled.");
      }
    },

    debouncedShowGuideLines: debounce(function (movingObject) {
      this.showGuideLines(movingObject);
    }, 100),
    showGuideLines(movingObject, snapThreshold = 20, snapLine = 40) {
      // Убираем старые направляющие
      this.canvas.forEachObject((obj) => {
        if (obj.isGuide) this.canvas.remove(obj);
      });

      const objects = this.canvas.getObjects();

      objects.forEach((obj) => {
        if (obj === movingObject || obj.isNoHistoryElement) return; // Пропускаем объект, который мы двигаем

        const objBounds = obj.getBoundingRect();
        const movingBounds = movingObject.getBoundingRect();

        // Центры перемещаемого объекта
        const movingCenterX = movingBounds.left + movingBounds.width / 2;
        const movingCenterY = movingBounds.top + movingBounds.height / 2;

        // Центры объекта для сравнения
        const objCenterX = objBounds.left + objBounds.width / 2;
        const objCenterY = objBounds.top + objBounds.height / 2;

        // Границы перемещаемого объекта
        const movingLeft = movingBounds.left;
        const movingRight = movingBounds.left + movingBounds.width;
        const movingTop = movingBounds.top;
        const movingBottom = movingBounds.top + movingBounds.height;

        // Границы другого объекта
        const objLeft = objBounds.left;
        const objRight = objBounds.left + objBounds.width;
        const objTop = objBounds.top;
        const objBottom = objBounds.top + objBounds.height;

        // --- Границы ---
        // Привязка по левому краю
        if (Math.abs(movingLeft - objLeft) < snapLine) {
          const newLeft = objLeft;

          if (Math.abs(movingLeft - objLeft) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              left: objLeft + 30,
              lockMovementX: true,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementX: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.isMovingLeft) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line([objLeft - 1, 0, objLeft - 1, this.canvas.height], {
            stroke: "#0F70BA",
            strokeWidth: 3,
            selectable: false,
            isGuide: true,
            isNoHistoryElement: true,
            isMovingLeft: true,
            strokeDashArray: [5, 5],
          });
          this.canvas.add(guideLine);
        }

        if (Math.abs(movingLeft - 0) < snapLine) {
          const newLeft = 0;

          if (Math.abs(movingLeft - 0) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              left: 0 + 30,
              lockMovementX: true,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementX: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.isMovingLeftLayout) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line([0, 0, 0, this.canvas.height], {
            stroke: "#0F70BA",
            strokeWidth: 3,
            selectable: false,
            isGuide: true,
            isNoHistoryElement: true,
            isMovingLeftLayout: true,
            strokeDashArray: [5, 5],
          });
          this.canvas.add(guideLine);
        }

        // Привязка по правому краю
        if (Math.abs(movingRight - objRight) < snapLine) {
          const newLeft = objRight - movingBounds.width;

          if (Math.abs(movingRight - objRight) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              left: newLeft + 30,
              lockMovementX: false,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementX: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.movingRight) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line([objRight - 1, 0, objRight - 1, this.canvas.height], {
            stroke: "#0F70BA",
            strokeWidth: 3,
            selectable: false,
            isGuide: true,
            isNoHistoryElement: true,
            movingRight: true,
            strokeDashArray: [5, 5],
          });
          this.canvas.add(guideLine);
        }

        if (Math.abs(movingRight - this.canvas.width) < snapLine) {
          const newLeft = this.canvas.width - movingBounds.width;

          if (
            Math.abs(movingRight - this.canvas.width) < snapThreshold &&
            this.keyName == "Shift"
          ) {
            movingObject.set({
              left: newLeft + 30,
              lockMovementX: false,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementX: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.movingRightLayout) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line(
            [this.canvas.width - 3, 0, this.canvas.width - 3, this.canvas.height],
            {
              stroke: "#0F70BA",
              strokeWidth: 3,
              selectable: false,
              isGuide: true,
              isNoHistoryElement: true,
              movingRightLayout: true,
              strokeDashArray: [5, 5],
            },
          );
          console.log(guideLine);
          this.canvas.add(guideLine);
        }

        // Привязка по верхнему краю
        if (Math.abs(movingTop - objTop) < snapLine) {
          const newTop = objTop;

          if (Math.abs(movingTop - objTop) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              top: newTop + 30,
              lockMovementY: true,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementY: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.movingTop) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line([0, objTop - 1, this.canvas.width, objTop - 1], {
            stroke: "#0F70BA",
            strokeWidth: 3,
            selectable: false,
            isGuide: true,
            isNoHistoryElement: true,
            movingTop: false,
            strokeDashArray: [5, 5],
          });
          this.canvas.add(guideLine);
        }

        if (Math.abs(movingTop - 0) < snapLine) {
          const newTop = 0;

          if (Math.abs(movingTop - 0) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              top: newTop + 30,
              lockMovementY: true,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementY: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.movingTopLayout) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line([0, 0, this.canvas.width - 1, 0], {
            stroke: "#0F70BA",
            strokeWidth: 3,
            selectable: false,
            isGuide: true,
            isNoHistoryElement: true,
            movingTopLayout: false,
            strokeDashArray: [5, 5],
          });
          this.canvas.add(guideLine);
        }

        // Привязка по нижнему краю
        if (Math.abs(movingBottom - objBottom) < snapLine) {
          const newTop = objBottom - movingBounds.height;

          if (Math.abs(movingBottom - objBottom) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              top: newTop + 30,
              lockMovementY: true,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementY: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.movingBottom) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line([0, objBottom - 1, this.canvas.width, objBottom - 1], {
            stroke: "#0F70BA",
            strokeWidth: 3,
            selectable: false,
            isGuide: true,
            isNoHistoryElement: true,
            movingBottom: true,
            strokeDashArray: [5, 5],
          });
          this.canvas.add(guideLine);
        }

        if (Math.abs(movingBottom - this.canvas.height) < snapLine) {
          const newTop = this.canvas.height - movingBounds.height;

          if (
            Math.abs(movingBottom - this.canvas.height) < snapThreshold &&
            this.keyName == "Shift"
          ) {
            movingObject.set({
              top: newTop + 30,
              lockMovementY: true,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementY: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.movingBottomLayout) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line(
            [0, this.canvas.height - 3, this.canvas.width, this.canvas.height - 3],
            {
              stroke: "#0F70BA",
              strokeWidth: 3,
              selectable: false,
              isGuide: true,
              isNoHistoryElement: true,
              movingBottomLayout: true,
              strokeDashArray: [5, 5],
            },
          );
          this.canvas.add(guideLine);
        }

        // --- Центры ---
        // Привязка по горизонтальному центру
        if (Math.abs(movingCenterX - objCenterX) < snapLine) {
          const newLeft = objCenterX - movingBounds.width / 2;

          if (Math.abs(movingCenterX - objCenterX) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              left: newLeft + 30,
              lockMovementX: true,
            });

            setTimeout(() => {
              movingObject.set({
                lockMovementX: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.isMovingCenterX) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line(
            [objCenterX - 1, 0, objCenterX - 1, this.canvas.height],
            {
              stroke: "#d75f54",
              strokeWidth: 3,
              selectable: false,
              isGuide: true,
              isNoHistoryElement: true,
              isMovingCenterX: true,
              strokeDashArray: [5, 5],
            },
          );

          this.canvas.add(guideLine);
        }

        // Привязка по вертикальному центру
        if (Math.abs(movingCenterY - objCenterY) < snapLine) {
          const newTop = objCenterY - movingBounds.height / 2;

          if (Math.abs(movingCenterY - objCenterY) < snapThreshold && this.keyName == "Shift") {
            movingObject.set({
              top: newTop + 30,
              lockMovementY: true,
            });
            setTimeout(() => {
              movingObject.set({
                lockMovementY: false,
              });
              this.canvas.renderAll();
            }, 50);
          }

          this.canvas.forEachObject((obj) => {
            if (obj.isMovingCenterY) this.canvas.remove(obj);
          });

          const guideLine = new fabric.Line(
            [0, objCenterY - 1, this.canvas.width, objCenterY - 1],
            {
              stroke: "#d75f54",
              strokeWidth: 3,
              selectable: false,
              isGuide: true,
              isNoHistoryElement: true,
              isMovingCenterY: true,
              strokeDashArray: [5, 5],
            },
          );
          this.canvas.add(guideLine);
        }
      });

      this.canvas.renderAll(); // Перерисовываем холст
    },

    saveCanvas() {
      this.loading = true;
      try {
        const url = `/${this.$route.params.id}`;
        const data = {
          data: this.canvas.toJSON(this.toJsonArray),
          template_id: this.layout_template.templateId,
        };
        templatesApi
          .editTemplateLayout(url, data)
          .then((res) => {
            this.loading = false;
          })
          .catch((error) => {
            alert("Save failed!");
            console.log(error);
            this.loading = false;
          })
          .finally(() => {});
      } catch (e) {
        alert("Save failed!");
        this.loading = false;
      }
    },
    getFontList() {
      const data = {};
      data.search = this.fontsSearch;
      referencesApi
        .filterLayoutFonts(`?page=1&perPage=50`, data)
        .then((res) => {
          const fonts = res.data.items.data;

          for (let i = 0; fonts.length > i; i++) {
            this.fontsList.push({
              id: fonts[i].id,
              name: fonts[i].name,
              url: fonts[i].fileName,
              image: fonts[i].imageName,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    getTemplateLayout() {
      this.loading = true;

      const url = `/${this.$route.params.id}`;
      try {
        templatesApi
          .getTemplateLayout(url)
          .then((res) => {
            this.loading = false;
            if (res.data) {
              this.editor_save = res.data.data;
              this.layout_template = res.data;
              if (this.editor_save && this.editor_save.chosenSize) {
                this.chosenSize = this.editor_save.chosenSize;
                this.changeCanvasAspect(this.chosenSize);
              } else {
                this.resizeModal = true;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          })
          .finally(() => {});
      } catch (e) {}
    },
    handleSaveHistory() {
      console.log("history save");
      if (this.undo_stack.length > 4) {
        this.undo_stack.shift();
        console.log("hello");
      }
      this.undo_stack.push(JSON.stringify(this.canvas.toJSON(this.toJsonArray)));

      console.log(this.undo_stack, "history");

      this.redo_stack = [];
      this.getListElement();
    },

    debouncedHandleSaveHistory: debounce(function () {
      this.handleSaveHistory();
    }, 100),

    handleChooseActiveElement(element, editable = false) {
      this.chosenElement = null;
      this.chosenElementType = null;
      this.pause_saving_history = true;

      console.log(element, "active element");

      this.canvas.forEachObject((obj) => {
        if (obj.isBorder) this.canvas.remove(obj);
      });

      element.set({
        //element control
        borderColor: "#D85F54",
        borderScaleFactor: 5,
        cornerSize: 30,
        cornerStrokeColor: "#D85F54",
        transparentCorners: false,
        padding: 30,
        selectionBackgroundColor: "rgba(0,0,0, 0.1)",
        cornerColor: "#D85F54",
      });

      // if (editable) {
      //   element.set({
      //     editable: true,
      //   });
      // } else {
      element.set({
        editable: false,
      });
      // }

      element.controls.mtr.offsetY = -parseFloat(100);

      this.chosenData.scale = element.scaleX;
      this.chosenData.flipX = element.flipX;
      this.chosenData.flipY = element.flipY;

      if (element.globalCompositeOperation) {
        this.chosenData.globalCompositeOperation = element.globalCompositeOperation;
      }

      if (element.lockMovementX && element.lockMovementY) {
        this.chosenData.lock = true;
      } else {
        this.chosenData.lock = false;
      }

      if (element.opacity) {
        this.chosenData.opacity = element.opacity;
      }

      if (element.typeElement == "vector") {
        this.chosenData.fill = element.fill;
      }

      if (element.typeElement == "text") {
        if (element.fontFamily) {
          this.chosenData.fontFamily = element.fontFamily;
        }
        this.chosenData.text = element.text.replaceAll("\u200A", "");
        this.chosenData.charSpacing = element.charSpacing;
        this.chosenData.fontSize = +element.fontSize;
        this.chosenData.fontWeight = element.fontWeight;
        this.chosenData.fontStyle = element.fontStyle;
        this.chosenData.underline = element.underline;
        this.chosenData.linethrough = element.linethrough;
        this.chosenData.textAlign = element.textAlign;
        this.chosenData.angle = element.angle;
        this.chosenData.lineHeight = element.lineHeight;
        this.chosenData.fillType = element.fillType;
        this.chosenData.fillTypeOutlines = element.fillTypeOutlines;
        this.chosenData.fillTypeOutlines3D = element.fillTypeOutlines3D;

        this.chosenData.strokeWidth = element.strokeWidth;
        this.chosenData.stroke = element.stroke;

        if (element.gradientType) {
          this.chosenData.gradientAngle = element.gradientAngle;
          this.chosenData.gradientType = element.gradientType;
          this.chosenData.gradientColors = element.gradientColors;
        }

        if (element.gradientTypeOutlines3D) {
          this.chosenData.gradientAngleOutlines3D = element.gradientAngleOutlines3D;
          this.chosenData.gradientTypeOutlines3D = element.gradientTypeOutlines3D;
          this.chosenData.gradientColorsOutlines3D = element.gradientColorsOutlines3D;
        }

        if (element.gradientTypeOutlines) {
          this.chosenData.gradientAngleOutlines = element.gradientAngleOutlines;
          this.chosenData.gradientTypeOutlines = element.gradientTypeOutlines;
          this.chosenData.gradientColorsOutlines = element.gradientColorsOutlines;
        }

        if (element.textureFill && element.textureFill.source) {
          if (element.textureFill.source.src) {
            this.chosenData.textureFill = element.textureFill;
            this.chosenData.textureOffset = element.textureOffset;
          } else {
            if (this.chosenData.fillType == "texture") {
              this.chosenData.textureFill = element.fill;
              this.chosenData.textureOffset = element.textureOffset;
            }
          }
        } else {
          this.chosenData.textureFill = null;
        }

        if (element.fill) {
          this.chosenData.fill = element.fill;
          this.fillingTab = element.fillType;
          this.chosenData.fillColor = element.fillColor;
        }

        if (element.stroke) {
          this.chosenData.stroke = element.stroke;
          this.fillingTabOutlines = element.fillTypeOutlines || "color";
          this.chosenData.fillColorOutlines = element.fillColorOutlines;
        }

        if (element.stroke3D) {
          this.chosenData.stroke3D = element.stroke3D;
          this.fillingTabOutlines3D = element.fillTypeOutlines3D || "color";
          this.chosenData.fillColorOutlines3D = element.fillColorOutlines3D;
        }

        this.chosenData.is3D = element.is3D;
        if (element.is3D) {
          this.chosenData.length3D = element.length3D;
          this.chosenData.angle3D = element.index3DAngle;
          this.chosenData.opacity3D = element.opacity3D;

          this.chosenData.gradientAngle3D = element.gradientAngle3D;
          this.chosenData.gradientType3D = element.gradientType3D;
          this.chosenData.gradientColors3D = element.gradientColors3D;
          this.fillingTab3D = element.fillType3D;
          this.chosenData.fillType3D = element.fillType3D;
          this.chosenData.fillColor3D = element.fillColor3D;
          this.chosenData.textureFill3D = element.textureFill3D;
          this.chosenData.strokeWidth3D = element.strokeWidth3D || 0;
          this.chosenData.stroke3D = element.stroke3D || "#fff";

          if (element.shadow3D) {
            this.chosenData.shadow3D = element.shadow3D;
          } else {
            this.chosenData.shadow3D = null;
          }
        }

        if (element.shadow) {
          this.chosenData.shadowColor = element.shadow.color;
          this.chosenData.shadowBlur = element.shadow.blur;
          this.chosenData.shadowOffsetX = element.shadow.offsetX;
          this.chosenData.shadowOffsetY = element.shadow.offsetY;
          this.chosenData.shadow = element.shadow;
        } else {
          this.chosenData.shadow = null;
        }
      }

      this.chosenElementType = element.typeElement;
      this.chosenElement = element;

      this.canvas.renderAll();

      setTimeout(() => {
        this.pause_saving_history = false;
      }, 0);
    },

    handleAddListener() {
      if (!this.pause_saving_history && !this.pause_saving) {
        setTimeout(() => {
          this.handleSaveHistory();
        }, 0);
      }
      // add history navigation
      this.canvas.on("object:added", (e) => {
        if (!this.pause_saving_history && !this.pause_saving && !e.target.isNoHistoryElement) {
          this.debouncedHandleSaveHistory();
        }
      });
      this.canvas.on("object:moving", (e) => {
        this.debouncedShowGuideLines(e.target);
      });
      this.canvas.on("selection:created", (e) => {
        const obj = this.canvas.getActiveObject();

        obj.set({
          //element control
          borderColor: "#D85F54",
          borderScaleFactor: 5,
          cornerSize: 30,
          cornerStrokeColor: "#D85F54",
          transparentCorners: false,
          padding: 20,
          selectionBackgroundColor: "rgba(0,0,0, 0.1)",
          cornerColor: "#D85F54",
        });

        obj.controls.mtr.offsetY = -parseFloat(100);

        this.canvas.renderAll();
      });
      this.canvas.on("object:modified", (event) => {
        if (!this.pause_saving && !event.target.isNoHistoryElement) {
          if (!this.pause_saving_history && !this.pause_saving) {
            this.debouncedHandleSaveHistory();
          }

          if (this.canvas.getActiveObject().text) {
            this.mimicryParent();
          } else {
            if (
              this.canvas.getActiveObject()._objects &&
              this.canvas.getActiveObject()._objects.length &&
              this.canvas.getActiveObject().typeElement != "vector"
            ) {
              for (let i = 0; this.canvas.getActiveObject()._objects.length > i; i++) {
                if (this.canvas.getActiveObject()._objects[i]) {
                  const selectedObjects = this.canvas.getActiveObjects(); // Получаем выбранные объекты

                  this.handleDiscardActiveObject();
                  this.normalize3dElement();

                  setTimeout(() => {
                    if (selectedObjects.length > 1) {
                      // Если выбрано больше одного объекта
                      const activeSelection = new fabric.ActiveSelection(selectedObjects, {
                        canvas: this.canvas,
                      });
                      this.canvas.setActiveObject(activeSelection); // Устанавливаем активную группу
                      this.canvas.requestRenderAll(); // Перерисовываем холст
                    }
                  }, 100);

                  break;
                }
              }
            }
          }
        }
      });
      this.canvas.on("object:removed", (e) => {
        if (!this.pause_saving_history && !this.pause_saving && !e.target.isNoHistoryElement) {
          this.debouncedHandleSaveHistory();
        }
      });

      this.canvas.on("touch:gesture", function (event) {
        this.canvas.renderAll();
      });

      this.canvas.on("mouse:down", (event) => {
        if (this.canvas.getActiveObject()) {
          const element = this.canvas.getActiveObject();
          this.handleChooseActiveElement(element);
          this.chosenElementID = element.id;
        } else {
          this.chosenElementID = null;
          this.chosenElement = null;
          this.chosenElementType = null;
        }
        this.getListElement();
      });

      this.canvas.on("mouse:over", (e) => {
        const obj = e.target;

        console.log(obj, "mouse over");

        if (
          obj != null &&
          !obj.isNoHistoryElement &&
          (this.chosenElement ? obj.id != this.chosenElement.id : true)
        ) {
          const border = new fabric.Rect({
            left: obj.left - 33, // Позиция рамки совпадает с текстом
            top: obj.top - 33,
            width: obj.width * obj.scaleX + 60, // Ширина с учетом масштаба
            height: obj.height * obj.scaleY + 60, // Высота с учетом масштаба
            fill: "transparent",
            stroke: "#d75f54",
            strokeWidth: 6,
            strokeDashArray: [5, 5],
            isNoHistoryElement: true,
            isBorder: true,
            evented: false,
            angle: obj.angle, // Устанавливаем тот же угол поворота, что и у текста
          });

          this.canvas.add(border);

          this.canvas.renderAll(); // Обновляем холст
        }
      });

      this.canvas.on("mouse:out", (event) => {
        this.canvas.forEachObject((obj) => {
          if (obj.isBorder) this.canvas.remove(obj);
        });

        this.canvas.renderAll(); // Обновляем холст
      });

      this.canvas.on("mouse:up", (event) => {
        // Восстанавливаем возможность выбора для всех объектов
        this.canvas.getObjects().forEach((obj) => {
          obj.evented = true;
        });

        this.canvas.forEachObject((obj) => {
          if (obj.isGuide) this.canvas.remove(obj);
        });

        this.canvas.renderAll(); // Обновляем холст
      });
    },
    handleHistory(item) {
      if (item == "undo") {
        this.pause_saving = true;
        if (this.redo_stack.length > 4) {
          this.redo_stack.shift();
        }
        this.redo_stack.push(this.undo_stack.pop());
        let previous_state = this.undo_stack[this.undo_stack.length - 1];
        if (previous_state == null) {
          previous_state = "{}";
        }
        this.canvas.loadFromJSON(previous_state, () => {
          this.canvas.renderAll();
        });
        setTimeout(() => {
          this.pause_saving = false;
        }, 0);
      } else if (item == "redo") {
        this.pause_saving = true;
        const state = this.redo_stack.pop();
        if (state != null) {
          if (this.undo_stack.length > 4) {
            this.undo_stack.shift();
          }
          this.undo_stack.push(state);
          this.canvas.loadFromJSON(state, () => {
            this.canvas.renderAll();
          });
          setTimeout(() => {
            this.pause_saving = false;
          }, 0);
        }
      }

      setTimeout(() => {
        this.canvas.forEachObject((obj) => {
          if (obj.isBorder) this.canvas.remove(obj);
        });
        this.canvas.forEachObject((obj) => {
          if (obj.isGuide) this.canvas.remove(obj);
        });
        setTimeout(() => {
          this.normalize3dElement();
        }, 0);
        this.getListElement();
        console.log(this.canvas, "handle history");
      }, 0);
    },
    handleAddBGImage() {
      const file = this.$refs.bgImg.files[0];
      const reader = new FileReader();

      reader.onload = (f) => {
        const data = f.target.result;
        fabric.Image.fromURL(data, (img) => {
          const canvasWidth = this.canvas.width;
          const canvasHeight = this.canvas.height;

          const imgWidth = img.width;
          const imgHeight = img.height;

          // Соотношения сторон
          const canvasRatio = canvasWidth / canvasHeight;
          const imgRatio = imgWidth / imgHeight;

          let scaleX, scaleY, offsetX, offsetY;

          if (canvasRatio > imgRatio) {
            // Масштабировать по ширине
            scaleX = scaleY = canvasWidth / imgWidth;
            offsetY = (canvasHeight - imgHeight * scaleY) / 2;
            offsetX = 0;
          } else {
            // Масштабировать по высоте
            scaleX = scaleY = canvasHeight / imgHeight;
            offsetX = (canvasWidth - imgWidth * scaleX) / 2;
            offsetY = 0;
          }

          // Устанавливаем фоновое изображение
          this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
            scaleX: scaleX,
            scaleY: scaleY,
            top: offsetY,
            left: offsetX,
            originX: "left",
            originY: "top",
          });
        });
      };

      reader.readAsDataURL(file);
    },
    handleDiscardActiveObject() {
      if (this.canvas) {
        if (this.canvas.getActiveObject()) {
          this.canvas.discardActiveObject();
          this.canvas.renderAll();
          this.chosenElement = null;
          this.chosenElementType = null;
          this.chosenElementID = null;
          this.listChosenElementId = null;
        }

        this.canvas.getObjects().forEach((obj) => {
          obj.evented = true;
        });

        this.canvas.forEachObject((obj) => {
          if (obj.isGuide) this.canvas.remove(obj);
        });
      }
    },
    handleRemoveGuide() {
      this.canvas.forEachObject((obj) => {
        if (obj.isGuide) this.canvas.remove(obj);
      });
    },
    handleAddImage() {
      this.handleDiscardActiveObject();

      const file = this.$refs.img.files[0];

      if (
        file.type != "image/svg+xml" &&
        file.type != "image/png" &&
        file.type != "image/jpeg" &&
        file.type != "image/jpg" &&
        file.type != "image/webp"
      ) {
        alert("Incorrect file type!");
        return;
      }

      const reader = new FileReader();
      reader.onload = (f) => {
        var data = f.target.result;
        fabric.Image.fromURL(data, (img) => {
          const maxWidth = this.canvas.getWidth();
          const scaleFactor = maxWidth / img.width;
          if (img.width > maxWidth) {
            img.scale(scaleFactor);
          }
          // add image
          const setImg = img.set({
            left: 100,
            top: 100,
            opacity: 1,
            id: this.generateRandomString(10),
            typeElement: "image",
            src: img._element.currentSrc,
            lockMovementX: false,
            lockMovementY: false,

            //element control
            borderColor: "black",
            padding: 10,
            selectionBackgroundColor: "rgba(0,0,0, 0.1)",
            cornerColor: "black",
          });
          this.canvas.add(setImg);
          setImg.center();
          setImg.bringToFront();
        });
      };
      reader.readAsDataURL(file);
    },
    addGlobalCompositeOperation(item) {
      const obj = this.canvas.getActiveObject();
      obj.globalCompositeOperation = item.name;
      this.chosenData.globalCompositeOperation = item.name;
      this.canvas.renderAll();
    },
    handleAddVector() {
      this.handleDiscardActiveObject();

      const file = this.$refs.vector.files[0];

      if (file.type != "image/svg+xml") {
        alert("Incorrect file type!");
        return;
      }

      const reader = new FileReader();
      reader.onload = (f) => {
        const svgText = f.target.result;

        // Загружаем SVG из текста
        fabric.loadSVGFromString(svgText, (objects, options) => {
          // Создаем группу объектов
          const group = new fabric.Group(objects, {
            left: 100,
            top: 100,
            id: this.generateRandomString(10),
            typeElement: "vector",
            fill: "#000000",
            lockMovementX: false,
            lockMovementY: false,

            //element control
            borderColor: "black",
            padding: 10,
            selectionBackgroundColor: "rgba(0,0,0, 0.1)",
            cornerColor: "black",
          });

          group.getObjects().forEach(function (obj) {
            obj.set("fill", "#000000");
          });

          // Масштабируем группу так, чтобы ширина была равна 300
          const scaleFactor = 300 / group.width;
          group.scaleToWidth(300);

          // Подгоняем группу по центру канваса
          group.center();

          // Добавляем группу на канвас
          this.canvas.add(group);

          // Выполняем перерисовку канваса
          this.canvas.renderAll();
        });
      };
      reader.readAsText(file);
    },

    generateRandomString(length) {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handelAddText(textField = "") {
      if (textField.length < 1) {
        return;
      }

      const currentWidthCanvas = this.canvas.getWidth();
      const currentTextWidth = textField.length * 35;
      let textWidth = "";

      if (currentWidthCanvas - 200 > currentTextWidth) {
        textWidth = currentTextWidth;
      } else {
        textWidth = currentWidthCanvas - 200;
      }

      this.handleDiscardActiveObject();

      const font = new FontFace("Oswald", `url(/fonts/Oswald-Regular.ttf)`);

      font
        .load()
        .then(() => {
          document.fonts.add(font);

          const text = new fabric.Textbox(textField, {
            fill: "#000",
            fillColor: "#000",
            fillColorOutlines: "#fff",
            fillColorOutlines3D: "#fff",
            fontSize: 80,
            fontFamily: "Oswald",
            fontUrl: "/fonts/Oswald-Regular.ttf",
            opacity: 1,
            id: this.generateRandomString(10),
            lockScalingFlip: true,
            typeElement: "text",
            splitByGrapheme: false,
            fontWeight: "normal",
            fontStyle: "normal",
            underline: false,
            linethrough: false,
            textAlign: "left",
            lineHeight: 1.2,
            angel: 0,
            fillType: "color",
            fillTypeOutlines: "color",
            fillTypeOutlines3D: "color",
            lockMovementX: false,
            lockMovementY: false,
            is3D: false,
            width: textWidth,
            paintFirst: "stroke",
            strokeLineJoin: "round",
            strokeWidth: 0,
            stroke: "#fff",
            charSpacing: 1,

            //element control
            borderColor: "#D85F54",
            borderScaleFactor: 5,
            cornerSize: 30,
            cornerStrokeColor: "#D85F54",
            transparentCorners: false,
            padding: 30,
            selectionBackgroundColor: "rgba(0,0,0, 0.1)",
            cornerColor: "#D85F54",
          });

          this.canvas.add(text);
          text.center();
          text.bringToFront();
          this.canvas.renderAll();
        })
        .catch((error) => {
          console.error("Ошибка загрузки шрифта:", error);
        });
    },
    handleDownload() {
      this.handleRemoveGuide();

      if (this.typeDownload == "standard") {
        const url = this.canvas.toDataURL({
          format: "png",
        });
        this.$refs.linkDownload.href = url;
        this.$refs.linkDownload.download = "canvas.png";
        this.$refs.linkDownload.click();
      }
    },
    handleCopyItem() {
      this.chosenElement.clone((clonedObj) => {
        // Копируем пользовательские поля
        const customFields = this.toJsonArray;
        customFields.forEach((field) => {
          clonedObj.set(field, this.chosenElement[field]);
        });

        // Устанавливаем стандартные поля
        clonedObj.set({
          left: this.chosenElement.left + 50, // небольшое смещение, чтобы копия не накладывалась на оригинал
          top: this.chosenElement.top + 50,
          id: this.generateRandomString(10),
          lockMovementX: false,
          lockMovementY: false,
        });

        this.canvas.add(clonedObj);
        if (clonedObj.is3D) {
          this.add3DFor(clonedObj.length3D, clonedObj.index3DAngle, clonedObj);
        }
        this.canvas.renderAll();
        this.handleDiscardActiveObject();
      });
    },
    handleDeleteItem() {
      this.delete3dItems(this.chosenElement.id);
      this.canvas.remove(this.chosenElement);
      this.chosenElement = null;
      this.chosenElementType = null;
      this.chosenElementID = null;
    },
    handleTopItem(obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }
      this.canvas.bringForward(obj);
      this.canvas.renderAll();
      this.getListElement();

      if (!this.pause_saving_history && !this.pause_saving) {
        this.debouncedHandleSaveHistory();
      }
    },
    handleBottomItem(obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }
      this.canvas.sendBackwards(obj);
      this.canvas.renderAll();
      this.getListElement();

      if (!this.pause_saving_history && !this.pause_saving) {
        this.debouncedHandleSaveHistory();
      }
    },
    changeFont(data) {
      this.template.font = data;

      // render fontFamily
      const obj = this.canvas.getActiveObject();
      const font = new FontFace(this.template.font.name, `url(${this.template.font.url})`);
      font
        .load()
        .then(() => {
          document.fonts.add(font);
          obj.set({
            fontFamily: this.template.font.name,
            fontUrl: this.template.font.url,
          });
          this.mimicryParent();
          this.canvas.renderAll();

          if (!this.pause_saving_history && !this.pause_saving) {
            this.debouncedHandleSaveHistory();
          }

          this.pause_saving_history = true;

          this.chosenData.fontFamily = data.name;

          setTimeout(() => {
            this.pause_saving_history = false;
            this.modalFont = false;
          }, 1000);
        })
        .catch((error) => {
          console.error("Ошибка загрузки шрифта:", error);
        });
    },
    debouncedNormalizeGradient: debounce(function (obj) {
      this.normalizeGradient(obj);
    }, 200),
    debouncedNormalizeGradientOutlines: debounce(function (obj) {
      this.normalizeGradientOutlines(obj);
    }, 200),
    debouncedNormalizeGradientOutlines3D: debounce(function (obj) {
      this.normalizeGradientOutlines3D(obj);
    }, 200),
    normalizeGradient(obj) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (!this.freezeNG) {
        (this.freezeNG = true),
          setTimeout(() => {
            this.freezeNG = false;
          }, 0);
        if (obj.fillType == "gradient") {
          this.addGradient(obj, obj.gradientAngle, obj.gradientType, obj.gradientColors);
        }
      }
    },
    normalizeGradientOutlines(obj) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (!this.freezeNGO) {
        (this.freezeNGO = true),
          setTimeout(() => {
            this.freezeNGO = false;
          }, 0);
        if (obj.fillTypeOutlines == "gradient") {
          this.addGradientOutlines(
            obj,
            obj.gradientAngleOutlines,
            obj.gradientTypeOutlines,
            obj.gradientColorsOutlines,
          );
        }
      }
    },
    normalizeGradientOutlines3D(obj) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (!this.freezeNG3D) {
        (this.freezeNG3D = true),
          setTimeout(() => {
            this.freezeNG3D = false;
          }, 0);
        if (obj.fillTypeOutlines3D == "gradient") {
          this.addGradientOutlines3D(
            obj,
            obj.gradientAngleOutlines3D,
            obj.gradientTypeOutlines3D,
            obj.gradientColorsOutlines3D,
          );
        }
      }
    },
    normalizeGradientBackground() {
      if (!this.freezeNG) {
        (this.freezeNG = true),
          setTimeout(() => {
            this.freezeNG = false;
          }, 0);
        if (this.canvas.fillType == "gradient") {
          this.addGradientBackground(
            this.canvas.gradientAngle,
            this.canvas.gradientType,
            this.canvas.gradientColors,
          );
        }
      }
    },
    getGradientFill3D(obj) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }
      let colors = [];
      if (obj.gradientColors3D) {
        colors = obj.gradientColors3D;
      }
      let angle = 0;

      if (obj.gradientAngle3D) {
        angle = obj.gradientAngle3D;
      }

      if (obj.gradientType3D == "linear") {
        // Преобразуем угол из градусов в радианы
        let radians = angle * (Math.PI / 180);

        // Длина и высота текста
        let textWidth = obj.width;
        let textHeight = obj.height;

        // Вычисляем конечные координаты градиента
        let x2 = textWidth * Math.cos(radians);
        let y2 = textHeight * Math.sin(radians);

        // Учитываем знак для корректного направления градиента
        if (angle === 90 || angle === 270) {
          x2 = 0;
          y2 = textHeight;
        } else if (angle === 180 || angle === 0 || angle === 360) {
          x2 = textWidth;
          y2 = 0;
        }

        return new fabric.Gradient({
          type: "linear",
          gradientUnits: "pixels",
          coords: { x1: 0, y1: 0, x2: x2, y2: y2 },
          colorStops: colors,
        });
      } else {
        return new fabric.Gradient({
          type: "radial",
          gradientUnits: "pixels", // Использование единиц пикселей
          coords: {
            x1: obj.width / 2, // Центр градиента по оси X
            y1: obj.height / 2, // Центр градиента по оси Y
            r1: 0, // Начальный радиус
            x2: obj.width / 2, // Конечный центр градиента по оси X
            y2: obj.height / 2, // Конечный центр градиента по оси Y
            r2: Math.max(obj.width, obj.height) / 2, // Конечный радиус
          },
          colorStops: colors,
        });
      }
    },
    generateGradient(colors, angle, gradientType) {
      const sortedColors = [...colors].sort((a, b) => a.offset - b.offset);
      // Преобразуем offset от 0-1 в проценты
      const gradientStops = sortedColors
        .map((colorStop) => {
          const percentage = colorStop.offset * 100;
          return `${colorStop.color} ${percentage}%`;
        })
        .join(", ");

      // Формируем итоговый CSS-градиент в зависимости от типа
      if (gradientType === "linear") {
        return `linear-gradient(${angle + 90}deg, ${gradientStops})`;
      } else if (gradientType === "radial") {
        return `radial-gradient(${gradientStops})`;
      } else {
        return "";
      }
    },
    addGradient(obj = null, angle = 0, type = "linear", colors = []) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (this.chosenElement == null) {
        return;
      }
      if (colors.length < 1) {
        colors = [
          { offset: 0, color: "#2980B9" },
          { offset: 1, color: "#F2C511" },
        ];
      }

      if (type == "linear") {
        // Преобразуем угол из градусов в радианы
        let radians = angle * (Math.PI / 180);

        // Длина и высота текста
        let textWidth = obj.width;
        let textHeight = obj.height;

        // Вычисляем конечные координаты градиента
        let x2 = textWidth * Math.cos(radians);
        let y2 = textHeight * Math.sin(radians);

        // Учитываем знак для корректного направления градиента
        if (angle === 90 || angle === 270) {
          x2 = 0;
          y2 = textHeight;
        } else if (angle === 180 || angle === 0 || angle === 360) {
          x2 = textWidth;
          y2 = 0;
        }

        obj.set({
          fill: new fabric.Gradient({
            type: "linear",
            gradientUnits: "pixels",
            coords: { x1: 0, y1: 0, x2: x2, y2: y2 },
            colorStops: colors,
          }),
          fillType: "gradient",
          gradientAngle: angle,
          gradientType: "linear",
          gradientColors: colors,
        });
      } else {
        obj.set({
          fill: new fabric.Gradient({
            type: "radial",
            gradientUnits: "pixels", // Использование единиц пикселей
            coords: {
              x1: obj.width / 2, // Центр градиента по оси X
              y1: obj.height / 2, // Центр градиента по оси Y
              r1: 0, // Начальный радиус
              x2: obj.width / 2, // Конечный центр градиента по оси X
              y2: obj.height / 2, // Конечный центр градиента по оси Y
              r2: Math.max(obj.width, obj.height) / 2, // Конечный радиус
            },
            colorStops: colors,
          }),
          fillType: "gradient",
          gradientAngle: angle,
          gradientType: "radial",
          gradientColors: colors,
        });
      }

      // Обновляем объект на холсте
      obj.canvas.renderAll();
    },
    addGradientOutlines(obj = null, angle = 0, type = "linear", colors = []) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (this.chosenElement == null) {
        return;
      }
      if (colors.length < 1) {
        colors = [
          { offset: 0, color: "#2980B9" },
          { offset: 1, color: "#F2C511" },
        ];
      }

      if (type == "linear") {
        // Преобразуем угол из градусов в радианы
        let radians = angle * (Math.PI / 180);

        // Длина и высота текста
        let textWidth = obj.width;
        let textHeight = obj.height;

        // Вычисляем конечные координаты градиента
        let x2 = textWidth * Math.cos(radians);
        let y2 = textHeight * Math.sin(radians);

        // Учитываем знак для корректного направления градиента
        if (angle === 90 || angle === 270) {
          x2 = 0;
          y2 = textHeight;
        } else if (angle === 180 || angle === 0 || angle === 360) {
          x2 = textWidth;
          y2 = 0;
        }

        obj.set({
          stroke: new fabric.Gradient({
            type: "linear",
            gradientUnits: "pixels",
            coords: { x1: 0, y1: 0, x2: x2, y2: y2 },
            colorStops: colors,
          }),
          fillTypeOutlines: "gradient",
          gradientAngleOutlines: angle,
          gradientTypeOutlines: "linear",
          gradientColorsOutlines: colors,
        });
      } else {
        obj.set({
          stroke: new fabric.Gradient({
            type: "radial",
            gradientUnits: "pixels", // Использование единиц пикселей
            coords: {
              x1: obj.width / 2, // Центр градиента по оси X
              y1: obj.height / 2, // Центр градиента по оси Y
              r1: 0, // Начальный радиус
              x2: obj.width / 2, // Конечный центр градиента по оси X
              y2: obj.height / 2, // Конечный центр градиента по оси Y
              r2: Math.max(obj.width, obj.height) / 2, // Конечный радиус
            },
            colorStops: colors,
          }),
          fillTypeOutlines: "gradient",
          gradientAngleOutlines: angle,
          gradientTypeOutlines: "radial",
          gradientColorsOutlines: colors,
        });
      }

      // Обновляем объект на холсте
      obj.canvas.renderAll();
    },
    addGradientOutlines3D(obj = null, angle = 0, type = "linear", colors = []) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (this.chosenElement == null) {
        return;
      }
      if (colors.length < 1) {
        colors = [
          { offset: 0, color: "#2980B9" },
          { offset: 1, color: "#F2C511" },
        ];
      }

      if (type == "linear") {
        // Преобразуем угол из градусов в радианы
        let radians = angle * (Math.PI / 180);

        // Длина и высота текста
        let textWidth = obj.width;
        let textHeight = obj.height;

        // Вычисляем конечные координаты градиента
        let x2 = textWidth * Math.cos(radians);
        let y2 = textHeight * Math.sin(radians);

        // Учитываем знак для корректного направления градиента
        if (angle === 90 || angle === 270) {
          x2 = 0;
          y2 = textHeight;
        } else if (angle === 180 || angle === 0 || angle === 360) {
          x2 = textWidth;
          y2 = 0;
        }

        obj.set({
          stroke3D: new fabric.Gradient({
            type: "linear",
            gradientUnits: "pixels",
            coords: { x1: 0, y1: 0, x2: x2, y2: y2 },
            colorStops: colors,
          }),
          fillTypeOutlines3D: "gradient",
          gradientAngleOutlines3D: angle,
          gradientTypeOutlines3D: "linear",
          gradientColorsOutlines3D: colors,
        });
      } else {
        obj.set({
          stroke3D: new fabric.Gradient({
            type: "radial",
            gradientUnits: "pixels", // Использование единиц пикселей
            coords: {
              x1: obj.width / 2, // Центр градиента по оси X
              y1: obj.height / 2, // Центр градиента по оси Y
              r1: 0, // Начальный радиус
              x2: obj.width / 2, // Конечный центр градиента по оси X
              y2: obj.height / 2, // Конечный центр градиента по оси Y
              r2: Math.max(obj.width, obj.height) / 2, // Конечный радиус
            },
            colorStops: colors,
          }),
          fillTypeOutlines3D: "gradient",
          gradientAngleOutlines3D: angle,
          gradientTypeOutlines3D: "radial",
          gradientColorsOutlines3D: colors,
        });
      }

      // Обновляем объект на холсте
      obj.canvas.renderAll();
    },
    addGradientBackground(angle = 0, type = "linear", colors = []) {
      if (colors.length < 1) {
        colors = [
          { offset: 0, color: "#2980B9" },
          { offset: 1, color: "#F2C511" },
        ];

        this.background.gradientColors = colors;
      }

      if (type == "linear") {
        // Преобразуем угол из градусов в радианы
        let radians = angle * (Math.PI / 180);

        // Длина и высота текста
        let textWidth = this.canvas.getWidth();
        let textHeight = this.canvas.getHeight();

        // Вычисляем конечные координаты градиента
        let x2 = textWidth * Math.cos(radians);
        let y2 = textHeight * Math.sin(radians);

        // Учитываем знак для корректного направления градиента
        if (angle === 90 || angle === 270) {
          x2 = 0;
          y2 = textHeight;
        } else if (angle === 180 || angle === 0 || angle === 360) {
          x2 = textWidth;
          y2 = 0;
        }

        const gradient = new fabric.Gradient({
          type: "linear",
          gradientUnits: "pixels",
          coords: { x1: 0, y1: 0, x2: x2, y2: y2 },
          colorStops: colors,
        });
        this.canvas.fillType = "gradient";
        this.canvas.gradientAngle = angle;
        this.canvas.gradientType = "linear";
        this.canvas.gradientColors = colors;

        this.canvas.setBackgroundColor(gradient, this.canvas.renderAll.bind(this.canvas));
      } else {
        const gradient = new fabric.Gradient({
          type: "radial",
          gradientUnits: "pixels", // Использование единиц пикселей
          coords: {
            x1: this.canvas.getWidth() / 2, // Центр градиента по оси X
            y1: this.canvas.getHeight() / 2, // Центр градиента по оси Y
            r1: 0, // Начальный радиус
            x2: this.canvas.getWidth() / 2, // Конечный центр градиента по оси X
            y2: this.canvas.getHeight() / 2, // Конечный центр градиента по оси Y
            r2: Math.max(this.canvas.getWidth(), this.canvas.getHeight()) / 2, // Конечный радиус
          },
          colorStops: colors,
        });
        this.canvas.fillType = "gradient";
        this.canvas.gradientAngle = angle;
        this.canvas.gradientType = "radial";
        this.canvas.gradientColors = colors;

        this.canvas.setBackgroundColor(gradient, this.canvas.renderAll.bind(this.canvas));
      }
    },
    switcherShadow() {
      if (this.chosenData.shadow) {
        this.deleteShadow();
      } else {
        this.addShadow();
      }
    },
    switcherShadow3D() {
      if (this.chosenData.shadow3D) {
        this.deleteShadow3D();
      } else {
        this.addShadow3D();
      }
    },
    switcher3D() {
      if (this.chosenData.is3D) {
        this.delete3DFromElement(null);
      } else {
        this.add3DFor(10, 45, null, true);
      }
    },
    addShadow() {
      const obj = this.canvas.getActiveObject();

      obj.set({
        shadow: {
          color: "#000000",
          blur: 5,
          offsetX: 1,
          offsetY: 1,
        },
      });

      this.canvas.renderAll();
      this.handleChooseActiveElement(obj);
    },
    addShadow3D() {
      const obj = this.canvas.getActiveObject();

      obj.set({
        shadow3D: {
          color: "#000000",
          blur: 5,
          offsetX: 1,
          offsetY: 1,
        },
      });

      this.canvas.renderAll();
      this.handleChooseActiveElement(obj);
    },
    deleteShadow() {
      const obj = this.canvas.getActiveObject();

      obj.set({
        shadow: null,
      });

      this.canvas.renderAll();
      this.handleChooseActiveElement(obj);
    },
    deleteShadow3D() {
      const obj = this.canvas.getActiveObject();

      obj.set({
        shadow3D: null,
      });

      this.canvas.renderAll();
      this.handleChooseActiveElement(obj);
    },
    addTexture() {
      const obj = this.canvas.getActiveObject();

      fabric.Image.fromURL("/img/texture.jpg", function (img) {
        const textureUrl = img._element.src;

        // Создаем новый объект с паттерном
        const pattern = new fabric.Pattern({
          source: textureUrl,
          repeat: "repeat", // Повторяем текстуру по всей площади текста
        });

        // Устанавливаем текстуру для существующего текстового объекта
        obj.set({
          fill: pattern,
        });
      });
      setTimeout(() => {
        this.canvas.renderAll();
      }, 500);
    },
    loadTexture() {
      const obj = this.canvas.getActiveObject();

      const file = this.$refs.img_texture.files[0];
      const reader = new FileReader();
      reader.onload = (f) => {
        const data = f.target.result;
        fabric.Image.fromURL(data, (img) => {
          // Создаем новый объект с паттерном
          const pattern = new fabric.Pattern({
            source: img.getElement(),
            repeat: "repeat", // Отключаем повторение, чтобы изображение растянулось
          });

          obj.set({
            fill: pattern,
            textureFill: pattern,
            textureOffset: 0.5,
          });

          this.handleChooseActiveElement(obj);

          obj.fill.patternTransform = [0.5, 0, 0, 0.5, 0, 0];
          obj.set("dirty", true);
          this.canvas.renderAll();
        });
      };
      reader.readAsDataURL(file);

      setTimeout(() => {
        this.canvas.renderAll();
      }, 1000);
    },
    deleteTexture() {
      const obj = this.canvas.getActiveObject();

      obj.set({
        fill: "#000",
        textureFill: null,
        textureOffset: null,
      });

      this.chosenData.textureFill = null;
      this.chosenData.fill = "#000";
      this.chosenData.textureOffset = null;

      this.canvas.renderAll();
    },
    loadTexture3D() {
      const obj = this.canvas.getActiveObject();

      const file = this.$refs.img_texture_3d.files[0];
      const reader = new FileReader();
      reader.onload = (f) => {
        const data = f.target.result;
        fabric.Image.fromURL(data, (img) => {
          // Создаем новый объект с паттерном
          const pattern = new fabric.Pattern({
            source: img.getElement(),
            patternTransform: [0.5, 0, 0, 0.5, 0, 0],
            repeat: "repeat",
          });
          obj.set({
            textureFill3D: pattern,
          });
          this.chosenData.textureFill3D = pattern;
          this.canvas.renderAll();
        });
      };
      reader.readAsDataURL(file);

      setTimeout(() => {
        this.canvas.renderAll();
      }, 1000);
    },
    delete3DFromElement(obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      this.delete3dItems(obj.id);

      obj.set({
        is3D: false,
      });
      this.chosenData.is3D = false;

      this.canvas.renderAll();
    },
    changeFlip(flip) {
      const obj = this.canvas.getActiveObject();
      obj[flip] = !obj[flip];
      this.chosenData[flip] = obj[flip];
      this.canvas.renderAll();
      this.mimicryParent();
    },
    debouncedAdd3DFor: debounce(function (iteration, angle = 45, obj = null, rerender = false) {
      this.add3DFor(iteration, angle, obj, rerender);
    }, 200),
    add3DFor(iteration, angle = 45, obj = null, rerender = false) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }
      obj.set({
        is3D: true,
        length3D: iteration,
        index3DAngle: angle,
        opacity3D: obj.opacity3D ?? 1,
        fillColor3D: obj.fillColor3D || "#000",
        fillType3D: obj.fillType3D || "color",
      });

      if (rerender) {
        this.handleChooseActiveElement(obj);
      }

      this.delete3dItems(obj.id);

      const array = [];

      for (let i = 0; iteration > i; i++) {
        array.push(i);
      }

      const arrayReversed = array;

      for (let i = 0; arrayReversed.length > i; i++) {
        this.add3D(arrayReversed[i], obj, i);
      }
    },
    add3D(i, obj = null, index) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      const text = new fabric.Textbox(obj.text, {
        fontSize: obj.fontSize,
        fontFamily: obj.fontFamily,
        fontWeight: obj.fontWeight,
        fontStyle: obj.fontStyle,
        underline: obj.underline,
        charSpacing: obj.charSpacing,
        linethrough: obj.linethrough,
        textAlign: obj.textAlign,
        lineHeight: obj.lineHeight,
        opacity: obj.opacity3D,
        id: obj.id + "_3D_" + i,
        idParent: obj.id,
        elementType: "3D",
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        angle: obj.angle,
        left: this.getPointX(obj.left, obj.index3DAngle, i * obj.scaleX, obj.angle),
        top: this.getPointY(obj.top, obj.index3DAngle, i * obj.scaleY, obj.angle),
        selectable: false,
        indexScaleX: i,
        indexScaleY: i,
        isNoHistoryElement: true,
        splitByGrapheme: false,
        width: obj.width,
        height: obj.height,
        flipY: obj.flipY,
        flipX: obj.flipX,

        padding: 10,
      });

      if (index == 0) {
        text.set({
          shadow: obj.shadow3D,
        });
      }

      if (index == 0 && obj.angle == 0) {
        text.set({
          paintFirst: "stroke",
          strokeLineJoin: "round",
          strokeWidth: obj.strokeWidth3D,
          stroke: obj.stroke3D,

          left:
            this.getPointX(obj.left, obj.index3DAngle, (obj.length3D - i) * obj.scaleX, obj.angle) -
            (obj.strokeWidth3D / 2) * obj.scaleX,
          top:
            this.getPointY(obj.top, obj.index3DAngle, (obj.length3D - i) * obj.scaleY, obj.angle) -
            (obj.strokeWidth3D / 2) * obj.scaleY,
        });
      }

      if (obj.fillType3D == "color") {
        text.set({
          fill: obj.fillColor3D,
        });
      } else if (obj.fillType3D == "gradient") {
        text.set({
          fill: this.getGradientFill3D(obj),
        });
      } else if (obj.fillType3D == "texture") {
        if (obj.textureFill3D && obj.textureFill3D.source && obj.textureFill3D.source.src) {
          text.set({
            fill: obj.textureFill3D,
          });
        } else {
          text.set({
            fill: obj.fillColor3D,
          });
        }
      }

      this.canvas.add(text);
      obj.bringToFront();
      this.canvas.renderAll();
    },
    switcherOutlines() {
      if (this.chosenData.strokeWidth > 0) {
        this.chosenData.strokeWidth = 0;
      } else {
        this.chosenData.strokeWidth = 1;
      }
    },
    switcherOutlines3D() {
      console.log("hello");
      if (this.chosenData.strokeWidth3D > 0) {
        this.chosenData.strokeWidth3D = 0;
      } else {
        this.chosenData.strokeWidth3D = 1;
      }
    },
    delete3dItems(id) {
      const obj_3D_array = this.getElementsByIdParent(id, "3D");

      if (obj_3D_array) {
        for (let i = 0; obj_3D_array.length > i; i++) {
          const obj_3D = this.getElementById(obj_3D_array[i]);
          this.canvas.remove(obj_3D);
        }
      }
    },

    changeColor(color, first, second, third = null, four = null) {
      const { r, g, b, a } = color.rgba;
      if (four) {
        this[first][second][third][four] = `rgba(${r}, ${g}, ${b}, ${a})`;
      } else if (third) {
        this[first][second][third] = `rgba(${r}, ${g}, ${b}, ${a})`;
      } else {
        this[first][second] = `rgba(${r}, ${g}, ${b}, ${a})`;
      }
    },

    mimicryParent(obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }
      const obj_3D_array = this.getElementsByIdParent(obj.id, "3D");

      if (obj_3D_array) {
        for (let i = 0; obj_3D_array.length > i; i++) {
          const obj_3D = this.getElementById(obj_3D_array[i]);
          if (obj_3D) {
            obj_3D.set({
              text: obj.text,
              scaleX: obj.scaleX,
              scaleY: obj.scaleY,
              angle: obj.angle,
              left: this.getPointX(obj.left, obj.index3DAngle, obj_3D.indexScaleX * obj.scaleX),
              top: this.getPointY(obj.top, obj.index3DAngle, obj_3D.indexScaleY * obj.scaleY),
              fontSize: obj.fontSize,
              fontFamily: obj.fontFamily,
              lineHeight: obj.lineHeight,
              width: obj.width,
              height: obj.height,
            });

            if (i == 0 && obj.angle == 0) {
              obj_3D.set({
                left:
                  this.getPointX(obj.left, obj.index3DAngle, (obj.length3D - i) * obj.scaleX) -
                  (obj.strokeWidth3D / 2) * obj.scaleX,
                top:
                  this.getPointY(obj.top, obj.index3DAngle, (obj.length3D - i) * obj.scaleY) -
                  (obj.strokeWidth3D / 2) * obj.scaleY,
              });
            } else if (i == 0 && obj.angle != 0) {
              this.canvas.remove(obj_3D);
            }
          }
        }
      }
    },
    debouncedGetListElement: debounce(function () {
      this.getListElement();
    }, 200),
    getListElement() {
      const objects = this.canvas.getObjects();
      this.elementList = [];

      for (let i = 0; objects.length > i; i++) {
        if (!objects[i].isNoHistoryElement && !objects[i].isGuide) {
          const element = {
            id: objects[i].id,
            type: objects[i].typeElement,
          };
          if (objects[i].lockMovementX && objects[i].lockMovementY) {
            element.lock = true;
          } else {
            element.lock = false;
          }
          if (objects[i].typeElement == "text") {
            element.text = objects[i].text;
          }
          if (objects[i].typeElement == "image") {
            element.image = objects[i].src;
          }
          this.elementList.unshift(element);
        }
      }
    },
    handleChooseElement(id) {
      this.canvas.getObjects().forEach((obj) => {
        if (obj.id !== id) {
          obj.evented = false; // Отключаем выбор для остальных
        }
      });

      const element = this.getElementById(id);
      this.canvas.setActiveObject(element);
      this.canvas.renderAll();
      this.chosenElementID = element.id;
      this.handleChooseActiveElement(element);
      this.listChosenElementId = id;
      this.canvas.getObjects().forEach((obj) => {
        if (obj.id !== id) {
          obj.evented = false; // Отключаем выбор для остальных
        }
      });
    },
    normalize3dElement() {
      const array3dElement = this.getElementsByType("3D");
      if (!array3dElement) {
        return;
      }

      for (let i = 0; array3dElement.length > i; i++) {
        const element = this.getElementById(array3dElement[i]);
        const elementParent = this.getElementById(element.idParent);
        element.set({
          text: elementParent.text,
          scaleX: elementParent.scaleX,
          scaleY: elementParent.scaleY,
          angle: elementParent.angle,
          left: this.getPointX(
            elementParent.left,
            elementParent.index3DAngle,
            element.indexScaleX * elementParent.scaleX,
          ),
          top: this.getPointY(
            elementParent.top,
            elementParent.index3DAngle,
            element.indexScaleY * elementParent.scaleY,
          ),
          fontSize: elementParent.fontSize,
          fontFamily: elementParent.fontFamily,
          lineHeight: elementParent.lineHeight,
          width: elementParent.width,
          height: elementParent.height,
        });

        if (i == 0 && elementParent.angle == 0) {
          element.set({
            left:
              this.getPointX(
                elementParent.left,
                elementParent.index3DAngle,
                (elementParent.length3D - i) * elementParent.scaleX,
              ) -
              (elementParent.strokeWidth3D / 2) * elementParent.scaleX,
            top:
              this.getPointY(
                elementParent.top,
                elementParent.index3DAngle,
                (elementParent.length3D - i) * elementParent.scaleY,
              ) -
              (elementParent.strokeWidth3D / 2) * elementParent.scaleY,
          });
        } else if (i == 0 && elementParent.angle != 0) {
          this.canvas.remove(element);
        }
      }

      this.canvas.renderAll();
    },
    getElementsByIdParent(id, type) {
      const objects = this.canvas.getObjects();
      const array = [];
      for (let i = 0; i < objects.length; i++) {
        if (objects[i].idParent === id && objects[i].elementType === type) {
          array.push(objects[i].id);
        }
      }
      if (array.length > 0) {
        return array;
      }
      return null;
    },
    getElementById(id) {
      const objects = this.canvas.getObjects();

      for (let i = 0; i < objects.length; i++) {
        if (objects[i].id === id) {
          return objects[i];
        }
      }
      return null;
    },
    getElementsByType(type) {
      const objects = this.canvas.getObjects();
      const array = [];

      for (let i = 0; i < objects.length; i++) {
        if (objects[i].elementType === type) {
          array.push(objects[i].id);
        }
      }
      if (array.length > 0) {
        return array;
      }
      return null;
    },
    getPointX(x1, angleDegrees, length) {
      var angleRadians = (angleDegrees * Math.PI) / 180;
      var x2 = x1 + length * Math.cos(angleRadians);
      return x2;
    },
    getPointY(y1, angleDegrees, length) {
      var angleRadians = (angleDegrees * Math.PI) / 180;
      var y2 = y1 + length * Math.sin(angleRadians);
      return y2;
    },
    changeFillingType(type, obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (type == "color" && obj.fillType != "color") {
        obj.set({
          fillType: "color",
          fill: obj.fillColor,
        });
        this.canvas.renderAll();
        this.fillingTab = "color";
        this.handleChooseActiveElement(obj);
      }

      if (type == "gradient" && obj.fillType != "gradient") {
        this.addGradient(
          obj,
          obj.gradientAngle || 90,
          obj.gradientType || "linear",
          obj.gradientColors || [],
        );
        this.canvas.renderAll();
        this.fillingTab = "gradient";
        this.handleChooseActiveElement(obj);
      }

      if (type == "texture" && obj.fillType != "texture") {
        obj.set({
          fillType: "texture",
        });

        if (obj.textureFill) {
          obj.set({
            fill: obj.textureFill,
          });
          if (obj.textureOffset) {
            obj.fill.patternTransform = [obj.textureOffset, 0, 0, obj.textureOffset, 0, 0];
          }
        } else {
          obj.set({
            fill: "#000",
          });
        }
        this.canvas.renderAll();
        this.fillingTab = "texture";
        this.handleChooseActiveElement(obj);
      }
    },
    changeFillingTypeOutlines(type, obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (type == "color" && obj.fillTypeOutlines != "color") {
        obj.set({
          fillTypeOutlines: "color",
          stroke: obj.fillColorOutlines,
        });
        this.canvas.renderAll();
        this.fillingTabOutlines = "color";
        this.handleChooseActiveElement(obj);
      }

      if (type == "gradient" && obj.fillTypeOutlines != "gradient") {
        this.addGradientOutlines(
          obj,
          obj.gradientAngleOutlines || 90,
          obj.gradientTypeOutlines || "linear",
          obj.gradientColorsOutlines || [],
        );
        this.canvas.renderAll();
        this.fillingTabOutlines = "gradient";
        this.handleChooseActiveElement(obj);
      }
    },

    changeFillingTypeOutlines3D(type, obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (type == "color" && obj.fillTypeOutlines3D != "color") {
        obj.set({
          fillTypeOutlines3D: "color",
          stroke: obj.fillColorOutlines3D,
        });
        this.canvas.renderAll();
        this.fillingTabOutlines3D = "color";
        this.handleChooseActiveElement(obj);
      }

      if (type == "gradient" && obj.fillTypeOutlines3D != "gradient") {
        this.addGradientOutlines3D(
          obj,
          obj.gradientAngleOutlines3D || 90,
          obj.gradientTypeOutlines3D || "linear",
          obj.gradientColorsOutlines3D || [],
        );
        this.canvas.renderAll();
        this.fillingTabOutlines3D = "gradient";
        this.handleChooseActiveElement(obj);
      }
    },
    changeFillingTypeBackground(type) {
      if (type == "color" && this.canvas.fillType != "color") {
        this.canvas.set({
          fillType: "color",
          fill: this.canvas.fillColor,
        });
        this.background.fillType = "color";
        this.fillingTabBackground = "color";
      }

      if (type == "gradient" && this.canvas.fillType != "gradient") {
        this.addGradientBackground(
          this.canvas.gradientAngle || 90,
          this.canvas.gradientType || "linear",
          this.canvas.gradientColors || [],
        );
        this.background.fillType = "gradient";
        this.fillingTabBackground = "gradient";
      }
    },
    changeFillingType3D(type, obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (type == "color" && obj.fillType3D != "color") {
        obj.set({
          fillType3D: "color",
        });
        this.canvas.renderAll();
        this.fillingTab3D = "color";
        this.handleChooseActiveElement(obj);
      }

      if (type == "gradient" && obj.fillType3D != "gradient") {
        obj.set({
          fillType3D: "gradient",
          gradientAngle3D: obj.gradientAngle3D || 90,
          gradientType3D: obj.gradientType3D || "linear",
          gradientColors3D: obj.gradientColors3D || [
            { offset: 0, color: "#2980B9" },
            { offset: 1, color: "#F2C511" },
          ],
        });
        this.canvas.renderAll();
        this.fillingTab3D = "gradient";
        this.handleChooseActiveElement(obj);
      }

      if (type == "texture" && obj.fillType3D != "texture") {
        obj.set({
          fillType3D: "texture",
        });

        this.canvas.renderAll();
        this.fillingTab3D = "texture";
        this.handleChooseActiveElement(obj);
      }
    },
    deleteGradientColorItem(index) {
      this.chosenData.gradientColors.splice(index, 1);
    },
    deleteGradientColorItemBackground(index) {
      this.background.gradientColors.splice(index, 1);
    },
    deleteGradientColorItem3D(index) {
      this.chosenData.gradientColors3D.splice(index, 1);
    },
    deleteGradientColorItemOutlines(index) {
      this.chosenData.gradientColorsOutlines.splice(index, 1);
    },
    deleteGradientColorItemOutlines3D(index) {
      this.chosenData.gradientColorsOutlines3D.splice(index, 1);
    },
    addGradientColorItem() {
      this.chosenData.gradientColors.push({
        offset: 0.5,
        color: "#000",
      });
    },
    addGradientColorItemBackgrouond() {
      this.background.gradientColors.push({
        offset: 0.5,
        color: "#000",
      });
    },
    addGradientColorItem3D() {
      this.chosenData.gradientColors3D.push({
        offset: 0.5,
        color: "#000",
      });
    },
    addGradientColorItemOutlines() {
      this.chosenData.gradientColorsOutlines.push({
        offset: 0.5,
        color: "#000",
      });
      movingBottom;
    },
    addGradientColorItemOutlines3D() {
      this.chosenData.gradientColorsOutlines3D.push({
        offset: 0.5,
        color: "#000",
      });
    },
    openPanel(data, openMobilePanel = false) {
      this.is_active_panel = data;

      if (openMobilePanel) {
        this.openMobileActivePanel();
      }
    },
    closePanel() {
      this.colorPanel = null;
      this.is_active_panel = null;
    },
    handleLockItem() {
      if (this.chosenData.lock) {
        this.chosenData.lock = false;
      } else {
        this.chosenData.lock = true;
      }
    },
    handleZoomScale(up) {
      if (up) {
        if (this.zoomScale < 2) {
          this.zoomScale += 0.1;
        }
      } else {
        if (this.zoomScale > 0.2) {
          this.zoomScale = this.zoomScale - 0.1;
        }
      }

      this.zoom = this.zoomIndex * this.zoomScale;

      // Получение lowerCanvas
      const lowerCanvas = this.canvas.lowerCanvasEl;

      // Получение upperCanvas
      const upperCanvas = this.canvas.upperCanvasEl;

      // Пример: изменение стиля lowerCanvas
      lowerCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
      lowerCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

      // Пример: изменение стиля upperCanvas
      upperCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
      upperCanvas.style.height = `${this.canvasHeight * this.zoom}px`;
    },
    resizeCanvas(size) {
      this.chosenSize = size;
      const newWidth = size.width;
      const newHeight = size.height;
      this.resizeModal = false;

      this.canvas.set({
        chosenSize: size,
      });

      this.canvasHeight = newHeight;
      this.canvasWidth = newWidth;
      // Получаем текущие размеры холста
      const currentWidth = this.canvas.getWidth();
      const currentHeight = this.canvas.getHeight();

      // Рассчитываем коэффициенты масштабирования
      const scaleX = newWidth / currentWidth;
      const scaleY = newHeight / currentHeight;

      const diffX = Math.abs(scaleX - 1);
      const diffY = Math.abs(scaleY - 1);

      const scaleMin = diffX < diffY ? scaleX : scaleY;

      // Масштабируем и центрируем каждый объект на холсте
      this.canvas.getObjects().forEach(function (obj) {
        obj.scaleX *= scaleMin;
        obj.scaleY *= scaleMin;
        obj.left *= scaleMin;
        obj.top *= scaleMin;

        obj.setCoords(); // Обновляем координаты объекта
      });

      // Устанавливаем новые размеры холста
      this.canvas.setWidth(newWidth);
      this.canvas.setHeight(newHeight);

      // Перерисовываем холст
      this.canvas.renderAll();
      this.canvas.calcOffset();

      this.canvas.getObjects().forEach((obj) => {
        setTimeout(() => {
          this.handleChooseElement(obj.id);
        }, 0);
        setTimeout(() => {
          this.handleDiscardActiveObject();
        }, 100);
      });

      setTimeout(() => {
        const windowHeigth = window.innerHeight;
        const windowWidth = window.innerWidth;
        const zoomHeight = (windowHeigth - 130) / this.canvasHeight;
        const zoomWidth = (windowWidth - 90) / this.canvasWidth;
        let zoomTotal = 1;

        if (zoomHeight < zoomWidth) {
          zoomTotal = zoomHeight;
        } else {
          zoomTotal = zoomWidth;
        }

        this.zoom = zoomTotal;
        this.zoomIndex = zoomTotal;
        this.zoomScale = 1;

        // Получение lowerCanvas
        const lowerCanvas = this.canvas.lowerCanvasEl;

        // Получение upperCanvas
        const upperCanvas = this.canvas.upperCanvasEl;

        // Пример: изменение стиля lowerCanvas
        lowerCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
        lowerCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

        // Пример: изменение стиля upperCanvas
        upperCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
        upperCanvas.style.height = `${this.canvasHeight * this.zoom}px`;
      }, 0);

      this.normalizeGradientBackground();
      setTimeout(() => {
        this.canvas.renderAll();
      }, 0);
    },
    handelBack() {
      this.$router.push({
        path: "/",
      });
    },
    centerCanvas() {
      const windowHeigth = window.innerHeight;
      const windowWidth = window.innerWidth;
      const zoomHeight = (windowHeigth - 130) / this.canvasHeight;
      const zoomWidth = (windowWidth - 90) / this.canvasWidth;
      let zoomTotal = 1;

      if (zoomHeight < zoomWidth) {
        zoomTotal = zoomHeight;
      } else {
        zoomTotal = zoomWidth;
      }

      this.zoom = zoomTotal;
      this.zoomIndex = zoomTotal;
      this.zoomScale = 1;

      // Получение lowerCanvas
      const lowerCanvas = this.canvas.lowerCanvasEl;

      // Получение upperCanvas
      const upperCanvas = this.canvas.upperCanvasEl;

      // Пример: изменение стиля lowerCanvas
      lowerCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
      lowerCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

      // Пример: изменение стиля upperCanvas
      upperCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
      upperCanvas.style.height = `${this.canvasHeight * this.zoom}px`;
    },
    changeCanvasAspect(size) {
      this.canvasWidth = size.width;
      this.canvasHeight = size.height;
      this.chosenSize = size;
      this.resizeModal = false;
      this.loading = true;

      this.canvas = true;
      this.$nextTick(() => {
        const ref = this.$refs.can;
        this.canvas = new fabric.Canvas(ref, {
          preserveObjectStacking: true,
          isDrawingMode: false,
          allowTouchScrolling: true,
          enableRetinaScaling: false,
        });

        if (this.editor_save) {
          this.canvas.loadFromJSON(JSON.stringify(this.editor_save), () => {
            this.canvas.renderAll();
            this.getListElement();
          });
        }

        this.handleAddListener();
        const fonts = [];
        const storageCanvas = this.editor_save;
        if (storageCanvas) {
          const objectsElement = storageCanvas.objects;

          for (let i = 0; objectsElement.length > i; i++) {
            if (!objectsElement[i].isNoHistoryElement) {
              if (objectsElement[i].typeElement == "text") {
                const element = {
                  name: objectsElement[i].fontFamily,
                  url: objectsElement[i].fontUrl,
                };

                fonts.push(element);
              }
            }
          }
        }

        Promise.all(
          fonts.map((font) => {
            const fontFace = new FontFace(font.name, `url(${font.url})`);
            return fontFace
              .load()
              .then(() => {
                document.fonts.add(fontFace);
              })
              .catch((error) => {
                console.warn(`Не удалось загрузить шрифт ${font.name}:`, error);
                // Возвращаем пустое значение или делаем resolve, чтобы Promise.all не остановился
                return null;
              });
          }),
        )
          .then(() => {
            this.canvas.getObjects().forEach((element) => {
              if (element.typeElement === "text" || element.elementType == "3D") {
                const originalFont = element.fontFamily;
                element.set({
                  fontFamily: "none",
                });

                this.canvas.renderAll();

                element.set({
                  fontFamily: originalFont,
                });
                this.canvas.renderAll();

                if (element.is3D) {
                  // this.add3DFor(element.length3D, element.index3DAngle, element);
                }
              }
            });

            this.loading = false;
          })
          .catch((error) => {
            console.error("Ошибка при загрузке шрифтов:", error);
            this.loading = false;
          });

        setTimeout(() => {
          const windowHeigth = window.innerHeight;
          const windowWidth = window.innerWidth;
          const zoomHeight = (windowHeigth - 130) / this.canvasHeight;
          const zoomWidth = (windowWidth - 90) / this.canvasWidth;
          let zoomTotal = 1;

          if (zoomHeight < zoomWidth) {
            zoomTotal = zoomHeight;
          } else {
            zoomTotal = zoomWidth;
          }

          this.zoom = zoomTotal;
          this.zoomIndex = zoomTotal;
          this.zoomScale = 1;

          // Получение lowerCanvas
          const lowerCanvas = this.canvas.lowerCanvasEl;

          // Получение upperCanvas
          const upperCanvas = this.canvas.upperCanvasEl;

          // Пример: изменение стиля lowerCanvas
          lowerCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
          lowerCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

          // Пример: изменение стиля upperCanvas
          upperCanvas.style.width = `${this.canvasWidth * this.zoom}px`;
          upperCanvas.style.height = `${this.canvasHeight * this.zoom}px`;

          this.canvas.set({
            chosenSize: size,
          });
        }, 0);
      });

      setTimeout(() => {
        const storageCanvas = this.editor_save;

        if (storageCanvas.fillType == "gradient") {
          this.background.gradientAngle = storageCanvas.gradientAngle;
          this.background.gradientType = storageCanvas.gradientType;
          this.background.gradientColors = storageCanvas.gradientColors;
          this.background.fillType = "gradient";
          this.fillingTabBackground = "gradient";
        } else if (storageCanvas.fillType == "color") {
          this.background.fillColor = storageCanvas.fillColor;
          this.background.fillType = "color";
          this.fillingTabBackground = "color";
        }
      }, 0);
    },

    handleMoveObject(to, obj = null) {
      if (!obj) {
        obj = this.canvas.getActiveObject();
      }

      if (to == "left" && !this.chosenData.lock) {
        obj.set({
          left: obj.left - 10,
        });
      } else if (to == "right" && !this.chosenData.lock) {
        obj.set({
          left: obj.left + 10,
        });
      } else if (to == "top" && !this.chosenData.lock) {
        obj.set({
          top: obj.top - 10,
        });
      } else if (to == "bottom" && !this.chosenData.lock) {
        obj.set({
          top: obj.top + 10,
        });
      }

      this.canvas.renderAll();
    },

    handleKeydown(event) {
      this.keyName = event.key;

      if (this.chosenElement) {
        this.showGuideLines(this.chosenElement, 40, 40);
      }

      if (!this.toCanvas) {
        return;
      }

      if (event.ctrlKey && event.key === "z") {
        if (this.canvas) {
          this.handleHistory("undo");
        }
      }

      if (event.ctrlKey && event.key === "v") {
        console.log("hello");
        if (this.canvas && this.chosenElement) {
          this.handleCopyItem();
        }
      }

      if (event.key === "Delete") {
        if (this.canvas && this.chosenElement) {
          this.handleDeleteItem();
        }
      }

      if (event.key === "ArrowUp" && !this.chosenData.lock) {
        if (this.canvas && this.chosenElement) {
          this.handleMoveObject("top");
        }
      }

      if (event.key === "ArrowDown" && !this.chosenData.lock) {
        if (this.canvas && this.chosenElement) {
          this.handleMoveObject("bottom");
        }
      }

      if (event.key === "ArrowRight" && !this.chosenData.lock) {
        if (this.canvas && this.chosenElement) {
          this.handleMoveObject("right");
        }
      }

      if (event.key === "ArrowLeft" && !this.chosenData.lock) {
        if (this.canvas && this.chosenElement) {
          this.handleMoveObject("left");
        }
      }

      if (event.key === "Escape") {
        if (this.canvas && this.chosenElement) {
          this.handleDiscardActiveObject();
        }
      }
    },

    handleKeyUp() {
      this.keyName = "";
    },

    handleOpenResizeModal() {
      this.resizeModal = true;
    },

    handleCloseResizeModal() {
      this.resizeModal = false;
    },

    handleOpenDownloadModal() {
      this.downloadModal = true;
    },

    handleCloseDownloadModal() {
      this.downloadModal = false;
    },

    chosenTypeDownload(data) {
      this.typeDownload = data;
    },

    handleOpenFontModal() {
      this.modalFont = true;
    },

    handleCloseFontModal() {
      this.modalFont = false;
    },
    openColorPanel(item) {
      this.colorPanel = item;
    },
    closeColorPanel() {
      this.colorPanel = null;
    },
    openMobileActivePanel() {
      this.mobileActivePanel = true;
    },
    closeMobileActivePanel() {
      this.mobileActivePanel = false;
    },
    openMobileListPanel() {
      this.mobileListPanel = true;
    },
    closeMobileListPanel() {
      this.mobileListPanel = false;
    },
    beforeEnter(el) {
      el.style.height = "0";
      el.style.opacity = "0";
    },
    enter(el, done) {
      el.style.transition = "height 0.5s ease-in-out, opacity 0.5s ease-in-out";
      el.style.height = `${el.scrollHeight}px`;
      el.style.opacity = "1";
      // Обязательно вызвать done после завершения анимации
      el.addEventListener("transitionend", done, { once: true });
    },
    leave(el, done) {
      el.style.transition = "height 0.5s ease-in-out, opacity 0.5s ease-in-out";
      el.style.height = "0";
      el.style.opacity = "0";
      // Обязательно вызвать done после завершения анимации
      el.addEventListener("transitionend", done, { once: true });
    },
  },
};
</script>

<style lang="scss" scoped>
#canvas-container {
  // width: 600px;
  // height: 850px;
  overflow: hidden;
  position: relative;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  // transform: scale(0.5);
  transform-origin: top left;
}

.hidden-block {
  overflow: hidden;
}

.fade-expand-enter-active,
.fade-expand-leave-active {
  transition: height 0.5s ease, opacity 0.5s ease;
}

.fade-expand-enter,
.fade-expand-leave-to {
  height: 0;
  opacity: 0;
}

.fade-expand-enter-to,
.fade-expand-leave {
  height: auto;
}

/deep/.vc-sketch {
  width: 100%;
  padding: 0;
  box-shadow: none;
}

/deep/.vc-sketch-hue-wrap {
  height: 20px;
}

/deep/.vc-sketch-alpha-wrap {
  height: 20px;
}

/deep/.vc-hue-picker {
  height: 18px;
  width: 7px;
}

/deep/.vc-alpha-picker {
  height: 18px;
  width: 7px;
}

/deep/.vc-sketch-color-wrap {
  width: 44px;
  height: 44px;
}

/deep/.vc-sketch-presets-color {
  width: 34px;
  height: 34px;
}

/deep/.vue-swatches {
  display: block;
}
/deep/.vue-swatches__trigger__wrapper {
  display: block;
  border: 1px solid #000;
  border-radius: 10px;
}
/deep/.vue-swatches__trigger {
  width: 100% !important;
}
/* Custom slider track */
/deep/.vue-slider.vue-slider-ltr {
  padding: 10px 0 !important;
  height: 28px !important;
  box-sizing: border-box !important;
  margin: 0 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(to right, #d85f54 20px, #565656 20px, #565656 100%);
    border-radius: 5px;
    top: 10px;
    left: -10px;
    right: -10px;
    height: 8px;
  }
}
/deep/.vue-slider-rail {
  background: transparent;
  border-radius: 5px;
}
/deep/.vue-slider-process {
  background: #d85f54;
}
/deep/.vue-slider-dot-tooltip-inner {
  display: none;
}
/deep/.vue-slider-dot-handle {
  background: #fff;
  box-shadow: none;
  border: 4px solid #565656;
  outline: 2px solid #fff;

  &-focus,
  &:hover {
    border: 4px solid #d85f54;
  }
}
/deep/.vue-slider-dot {
  width: 20px !important;
  height: 20px !important;
}
::-webkit-scrollbar {
  width: 6px; /* Ширина вертикального скроллбара */
  height: 6px; /* Высота горизонтального скроллбара */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px; /* Скругление углов бегунка */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
input[type="range"] {
  overflow: hidden;
  width: 100px;
  -webkit-appearance: none;
  background-color: rgba(99, 99, 99, 0.2);
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #fff;
  margin-top: 0px;
  border-radius: 20px;
}

input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: ew-resize;
  background: black;
  border-radius: 50px;
  box-shadow: -80px 0 0 77px rgba(99, 99, 99, 0.8);
}

.blur-container {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20px;
    left: -20px;
    background: #fff;
    opacity: 0.6;
    z-index: 10;
  }
}
.editor {
  min-height: 100vh;
  background: #d5d5d5;
  &__canvas-wrap {
    width: calc(100% - 550px);
    max-width: calc(100% - 550px);
    min-height: calc(100vh - 57px);
    max-height: calc(100vh - 57px);
    padding: 50px 10px 10px;
    overflow: hidden;
  }
  &__canvas-container {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 10px;
  }
  &__select {
    width: 100%;
    position: relative;

    &:hover {
      .editor__select-list {
        display: block;
      }
    }
  }
  &__side {
    background: #fff;
  }
  &__select-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px 12px 10px;
    border-radius: 5px;
    border: 2px solid #d9d9d9;
    background: #f0eded;
    color: #565656;
    cursor: pointer;
    font-size: 12px;

    svg {
      margin-left: 10px;
      width: 14px !important;
      height: 9px !important;
    }
  }
  &__select-list {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    max-width: 300px;
    overflow-y: auto;
    background: #f0eded;
    padding: 0 5px 10px;
    z-index: 10;
  }

  &__select-list-item {
    color: #565656;
    margin-top: 5px;
    font-size: 12px;
    cursor: pointer;
    padding: 5px 10px 5px;

    &:hover {
      background: #c2c2c2;
      border-radius: 10px;
    }
  }

  &__filter-color-button {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    border: 4px solid #565656;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      border: 4px solid #302f2f;
    }
  }

  &__list-fonts-item {
    margin: 5px 0;
    height: 40px;
    cursor: pointer;

    img {
      height: 100%;
    }

    &.active,
    &:hover {
      position: relative;
      background: #dbdbdb;
      border-radius: 5px;
    }
  }

  &__list-fonts &__panel-sizes-title {
    font-size: 18px;
    padding-bottom: 15px;
  }

  &__texture-input {
    visibility: hidden;
    position: absolute;
    height: 0;
    width: 0;
  }
  &__button-3d {
    display: block;
    width: fit-content;
    margin-top: 10px;
    font-size: 16px;
    padding: 10px 20px;
    background: #565656;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
  }
  &__texture-button {
    display: block;
    width: fit-content;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 14px;
    padding: 10px 20px;
    background: #ffffff;
    color: #565656;
    cursor: pointer;
    border-radius: 5px;
    border: #f0eded solid 1px;
  }
  &__texture-button-container {
    display: flex;
    margin-bottom: 20px;
  }
  &__loaded-texture {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  &__gradient-colors {
    display: flex;
    flex-direction: column;
  }
  &__gradient-color-tools {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__gradient-color-name {
    color: #565656;
    font-size: 14px;
    font-weight: 700;
  }
  &__gradient-tools-color-container {
    width: 67px;
    height: 38px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
  }

  &__gradient-preset {
    width: 100%;
    height: 70px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  &__gradient-tools-color-picker {
    display: none;
    position: fixed;
    top: 250px;
    right: auto;
    left: 420px;
    width: 300px;
    background: #fff;
    border-radius: 5px;
    z-index: 10;
    padding: 50px 20px 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &.active {
      display: block;
    }

    &-close {
      position: absolute;
      cursor: pointer;
      width: 35px;
      height: 35px;
      top: 10px;
      right: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;

      &:hover {
        background: #c0c0c0;
      }
    }
  }

  &__switcher {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #f0eded;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 25px;

    &.disabled {
      cursor: not-allowed;

      &:hover {
        border-color: #d85f54;
      }
    }

    &.active {
      background: #d9d9d9;

      &.white {
        background: #ffffff;
      }
    }
  }

  &__switcher-left {
    font-size: 14px;
    color: #565656;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    .active & {
      font-weight: 700;

      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &__switcher-right {
    width: 36px;
    height: 21px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
    padding: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::after {
      content: "";
      width: 13px;
      height: 13px;
      border-radius: 50px;
      background: #d9d9d9;
    }

    .active & {
      justify-content: flex-end;
      border: 1px solid #d85f54;
      background: #d85f54;

      &::after {
        background: #fff;
      }
    }
  }

  &__gradient-color-tools-right {
    display: flex;
    align-items: center;
  }

  &__gradient-tools-color {
    position: relative;
  }

  &__gradient-color-add {
    display: flex;
    align-items: center;
    order: 101;
    width: fit-content;
    font-size: 14px;
    color: #565656;
    padding: 10px;
    border: 1px solid #f0eded;
    border-radius: 10px;
    margin: 5px auto 0;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }
  }
  &__gradient-color {
    position: relative;
    margin-bottom: 15px;

    &-delete {
      margin-left: 10px;
      display: flex;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }
  &__filter-icon-buttons {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__filter-icon-button {
    cursor: pointer;
    background: #f0eded;
    border-radius: 5px;
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .four & {
      width: 24%;
    }

    &:hover {
      background: #d9d9d9;
    }

    &.active {
      background: #d9d9d9;
      position: relative;

      &::after {
        content: "";
        border-radius: 5px;
        background: #d85f54;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        height: 5px;
        border-radius: 5pxp;
        bottom: -10px;
      }
    }
  }
  &__filter-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 15px;

    &.border {
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 20px;
    }
  }
  &__filter-tab {
    margin: 0 0 10px 0;
    background: #f0eded;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #565656;
    font-size: 14px;
    font-weight: 400;
    width: 49%;

    .three & {
      width: 32%;
    }

    &:hover,
    &.active {
      background: #d75f54;
      color: #fff;
    }
  }
  &__filter-tabs-radio {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }

  &__filter-tab-radio {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #494949;
    padding: 0 15px;

    &-icon {
      width: 23px;
      height: 23px;
      border: 3px solid #565656;
      border-radius: 50px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      .editor__filter-tab-radio-icon {
        &::after {
          content: "";
          width: 11px;
          height: 11px;
          background: #d85f54;
          border-radius: 50px;
        }
      }
    }
  }
  &__filter-label {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #565656;

    &-side {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #f0eded;
      background: #fff;
      color: #565656;
      font-size: 14px;
    }
  }
  &__filter-text-input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    height: 80px;
    min-height: 80px;
  }
  &__filter-basic {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  &__filter-basic-item {
    background: #f0eded;
    border-radius: 5px;
    width: calc(25% - 6px);
    margin-bottom: 8px;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 6px;

    &:hover {
      background: #d9d9d9;
    }

    .four & {
      width: 24%;
    }
  }
  &__list-elements {
    margin-left: 30px;
    margin-top: 25px;
    padding: 0 10px;
    margin-right: 5px;
    min-width: 90px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 230px);
    min-height: calc(100vh - 230px);
  }

  &__list-element {
    position: relative;
    overflow: hidden;
    width: 70px;
    min-height: 90px;
    height: 90px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 3px;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    background: url("/img/alfa.png") lightgray 50% / cover repeat;

    &.active {
      border: 2px solid #565656;
    }
  }
  &__list-element-lock {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #000000;
    opacity: 0.7;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__list-element-item {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__list-element-item-text {
    text-align: left;
    font-size: 12px;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    min-height: 500px;
    overflow-x: auto;
    width: fit-content;
    min-width: 100%;
    width: 100%;
    min-height: calc(100vh - 57px);
  }

  &__save {
    margin-left: 20px;
  }

  &__save-image {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
    background: #f2f2f2;
  }

  &__clean {
    font-size: 14px;
    cursor: pointer;
    margin-left: 20px;
    color: #f0eded;
  }

  &__resize {
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
    display: flex;
    align-items: center;
    color: #fff;
    svg {
      margin-right: 10px;
    }
  }

  &__canvas {
    height: fit-content;
    direction: ltr;
    background: url("/img/alfa.png") 50% / auto repeat;
  }

  &__nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px 0;
    border-bottom: 1px solid gainsboro;
    background: #d75f54;
  }

  &__nav-left,
  &__nav-right {
    display: flex;
    align-items: center;
  }

  &__history {
    display: flex;
    margin-left: 20px;
  }

  &__history-item {
    padding-right: 10px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;

    svg {
      width: 15px;
      height: 15px;
    }

    &.no-active {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__add-container {
    padding: 20px 0;
    display: flex;
    position: relative;
    flex-direction: column;
    border-right: 1px solid gainsboro;
    height: 100%;

    .icon {
      width: 30px;
      height: 30px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    label input {
      visibility: hidden;
      position: absolute;
      top: 0;
    }
  }

  &__chosen-bar-add-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    position: relative;
  }

  &__chosen-bar-add-item-container {
    width: 100%;
  }

  &__chosen-bar-add-item-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(231, 231, 231);
    width: 100%;
    padding: 10px;
    border-radius: 5px;

    svg {
      margin-bottom: 5px;
    }

    &:hover {
      background: rgb(185, 185, 185);
    }
  }

  &__chose-bar-add-item {
    cursor: pointer;
    width: 32%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    label input {
      visibility: hidden;
      position: absolute;
      top: 0;
    }
  }

  &__chosen-bar-button {
    width: 100%;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #f2f2f2;
    padding: 15px;
    border-radius: 5px;
    background: #565656;
    cursor: pointer;

    &:hover {
      background: #2e2e2e;
    }
  }

  &__chosen-bar-button-input {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
  }

  &__add-item {
    font-size: 11px;
    margin: 0 5px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 0 8px;
      background: #f5f5f5;
      border-radius: 10px;
    }

    &:hover &-icon,
    &.active &-icon {
      background: #565656;

      svg {
        path {
          fill: #f5f5f5;
        }

        path.stroke {
          stroke: #f5f5f5;
          fill: none;
        }
      }
    }
  }

  &__panel-aspect {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__panel-aspect-item {
    width: 48%;

    input {
      padding: 5px 10px;
      font-size: 16px;
      width: 100%;
      outline: none;
      border: 1px solid #000;
      border-radius: 5px;
    }
  }

  &__panel-aspect-title {
    font-size: 18px;
    padding-bottom: 10px;
  }

  &__chosen-bar {
    height: 100%;
    max-height: calc(100vh - 57px);
    min-height: calc(100vh - 57px);
    overflow-y: auto;
    padding: 20px;
    background: #fff;
    box-shadow: 4px 4px 10.4px -3px #00000040;

    &-header {
      padding-bottom: 18px;
      margin-bottom: 20px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
    }
    &-header-close {
      cursor: pointer;
      background: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      width: 35px;
      height: 35px;

      &:hover {
        background: rgb(212, 212, 212);
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__chosen-bar-wrap {
    width: 340px;
    min-width: 340px;
    margin-right: 20px;
  }

  &__filter-opacity {
    display: flex;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  &__chosen-bar-item {
    padding: 10px 0 20px;
  }

  &__chosen-bar-container {
    display: flex;
    flex-direction: column;
  }

  &__chosen-bar-copy {
    cursor: pointer;
    padding-right: 20px;

    svg {
      width: 25px;
      height: 25px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__chosen-bar-top {
    cursor: pointer;
    padding-right: 20px;

    svg {
      width: 25px;
      height: 25px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__chosen-bar-delete {
    cursor: pointer;
    padding-right: 20px;

    svg {
      width: 25px;
      height: 25px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__filter-font {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    color: #565656;
  }

  &__zoom-container {
    position: fixed;
    bottom: 40px;
    right: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid rgb(196, 196, 196);
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }

  &__zoom-input {
    padding-right: 10px;
  }

  &__zoom-element {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: rgb(214, 214, 214);
    }
  }
  &__chosen-bar-burger,
  &__list-elements-burger {
    display: none;
  }
}

.e-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;

  &__download-button-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  &__download-button {
    padding: 10px 35px;
    background: #d75f54;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }

  &__container {
    padding: 30px 25px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    width: 340px;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 25px;
    cursor: pointer;
  }

  &__font-items {
    display: flex;
    flex-wrap: wrap;
  }

  &__font-item {
    width: calc(25% - 10px);
    padding: 0 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;

    img {
      height: 100%;
    }
  }

  &__resize-title {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(201, 201, 201);
  }

  &__chosen-size-item {
    font-size: 13px;
    margin-bottom: 10px;
    padding: 10px;
    width: fit-content;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: #f0eded;
    }

    &.active {
      background: #f0eded;
      font-weight: 700;
    }
  }
}

.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(#fff, 0.5);
  z-index: 1000;
}

$squarew: 15px;
$squareh: 15px;
$squaremargin: 5px;
$loaderw: $squarew * 3 + $squaremargin * 2;
$loaderh: $squareh * 3 + $squaremargin * 2;
$delayenter: 0.3s;
$topenter: -10px;

@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  transition: $value;
}

@mixin delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  animation-delay: $delay;
}
@-webkit-keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}
@keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}
@-moz-keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -$loaderw / 2;
  margin-top: -$loaderh / 2;
}
.square {
  background: #494949;
  width: $squarew;
  height: $squareh;
  float: left;
  top: $topenter;
  margin-right: $squaremargin;
  margin-top: $squaremargin;
  position: relative;
  opacity: 0;
  animation: enter 6s infinite;
}
.enter {
  top: 0px;
  opacity: 1;
}
.square:nth-child(1) {
  @include delay(6 * $delayenter);
}
.square:nth-child(2) {
  @include delay(7 * $delayenter);
}
.square:nth-child(3) {
  @include delay(8 * $delayenter);
  background: #d85f54;
}
.square:nth-child(4) {
  @include delay(3 * $delayenter);
}
.square:nth-child(5) {
  @include delay(4 * $delayenter);
}
.square:nth-child(6) {
  @include delay(5 * $delayenter);
}
.square:nth-child(7) {
}
.square:nth-child(8) {
  @include delay(1 * $delayenter);
}
.square:nth-child(9) {
  @include delay(2 * $delayenter);
}
.clear {
  clear: both;
}
.last {
  margin-right: 0;
}

@media (max-width: 1200px) {
  .editor {
    &__container {
      justify-content: flex-start;
    }
    &__canvas-wrap {
      margin: 0 0 0 auto;
      width: calc(100% - 50px);
      max-width: calc(100% - 50px);
    }

    &__chosen-bar-container {
      position: absolute;
      height: 100%;
      max-height: calc(100vh - 57px);
      min-height: calc(100vh - 57px);
      overflow-y: auto;
      padding: 20px;
      background: #fff;
      box-shadow: 4px 4px 10.4px -3px #00000040;
      width: 300px;
      min-width: 300px;

      z-index: 101;
    }

    &__chosen-bar-wrap {
      width: 0;
      min-width: 0;
      position: relative;
      margin: 0;
    }

    &__chosen-bar {
      display: block;
      padding: 0;

      &.hide {
        display: none;
      }
    }

    &__chosen-bar-burger {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 101;
      width: 40px;
      height: 40px;
      top: 5px;
      left: 5px;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 50px;
      cursor: pointer;

      &.close {
        left: 310px;
      }
    }

    &__list-elements {
      position: absolute;
      z-index: 100;
      top: 10px;
      right: 0;
      margin: 0;

      &.hide {
        display: none;
      }
    }

    &__add-item-text {
      display: none;
    }

    &__canvas-wrap {
      margin: 0;
    }

    &__add-item-icon {
      width: 30px;
      height: 30px;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    &__resize-text {
      display: none;
    }

    &__list-elements-burger {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 100;
      width: 40px;
      height: 40px;
      top: 5px;
      right: 5px;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 50px;
      cursor: pointer;

      &.close {
        right: 90px;
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &__gradient-tools-color-picker {
      left: 50px;
      bottom: 100px;
      top: auto;
      width: 280px;
    }
  }
}

@media (max-width: 991px) {
  .editor {
    &__zoom-container {
      display: none;
    }
  }
}
</style>
